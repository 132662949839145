import { lazy } from 'react'
import type { ComponentType } from 'react'

export type Props = {
  standalone?: boolean,
  d1Component?: boolean,
}

const Default = lazy<React$ComponentType<Props>>(() => import('d2/partials/SecuritySettings'))

const Standalone = lazy<React$ComponentType<Props>>(() => import('d2/partials/SecuritySettings/standalone'))

const SecuritySettings: ComponentType<Props> = ({ d1Component, standalone }) => (
  standalone ? <Standalone d1Component={d1Component} /> : <Default />
)

export default SecuritySettings
