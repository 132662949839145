// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import { each, isArray } from 'lodash-es'
import AudioFileModel from '../flux/models/AudioFileModel.js'
import AudioToVideoAction from '../flux/actions/AudioToVideoAction.js'
import Errors from '../../utils/Errors.js'
import FaIcon from '../FaIcon.jsx'
import ProgressBar from '../ProgressBar.jsx'
import PropTypes from 'prop-types'
import Texts from '../texts/texts.js'
import cx from 'classnames'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'BatchUploadItem'

  static propTypes = {
    file: PropTypes.instanceOf(AudioFileModel).isRequired,
    isSelected: PropTypes.bool.isRequired,
    selectable: PropTypes.bool.isRequired,
    sortable: PropTypes.bool.isRequired,
    orderId: PropTypes.number.isRequired,
  }

  handleSelect = () => {
    if (this.props.selectable && !this.props.isSelected) {
      AudioToVideoAction.selectFile(this.props.file)
    }
  }

  handleRemove = (event) => {
    AudioToVideoAction.removeFile(this.props.file)
    event.stopPropagation()
  }

  renderError = () => {
    const content = []
    const errors = this.props.file.getErrors()
    if (isArray(errors)) {
      each(errors, (error) => {
        content.push(error)
      })
    } else {
      each(errors, (fieldErrors, fieldName) => {
        each(fieldErrors, (fieldError) => {
          const textKey = `${fieldName}_${fieldError}`
          if (Texts.a2v.upload.error[textKey]) {
            content.push(Texts.a2v.upload.error[textKey])
          } else {
            content.push(Errors.getReadableMessage(fieldName, fieldError, { includeFieldName: true }))
          }
        })
      })
    }
    return (
      <span className='error'>
        { content.join(' ') }
      </span>
    )
  }

  render () {
    const file = this.props.file
    const classes = cx({
      'selected': this.props.selectable && this.props.isSelected,
      'batch-upload-item': true,
      'list-row': true,
    })

    let progress
    if (file.hasErrors()) {
      progress = this.renderError()
    } else {
      progress = (
        <ProgressBar
          active={!file.isComplete()}
          isFloat={false}
          progress={file.getProgress()}
          striped={!file.isComplete()}
        />
      )
    }

    return (
      <li
        className={classes}
        data-id={this.props.orderId}
        onClick={this.handleSelect}
      >
        <div className='batch-upload-filename'>
          { this.props.sortable && <FaIcon
            className='sortable-icon'
            icon='ellipsis-v'
          /> }
          { file.getValue('filename') }
          { this.props.selectable
            ? (<FaIcon
              className='selectable-slider'
              icon='sliders-h'
              onClick={this.handleSelect}
            />)
            : '' }
          <FaIcon
            icon='trash-alt'
            onClick={this.handleRemove}
          />
        </div>
        <div className='batch-upload-progress'>
          { progress }
        </div>
      </li>
    )
  }
}

// - -------------------------------------------------------------------- - //
