// popup in the center of the screen
export default function (url, title, width, height, center) {
  let a
  let i
  let g
  let f
  let h
  let left
  let top
  let options = 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, '

  if (center) {
    a = typeof window.screenX != 'undefined' ? window.screenX : window.screenLeft
    i = typeof window.screenY != 'undefined' ? window.screenY : window.screenTop
    g = typeof window.outerWidth != 'undefined' ? window.outerWidth : document.documentElement.clientWidth
    f = typeof window.outerHeight != 'undefined' ? window.outerHeight : document.documentElement.clientHeight - 22
    h = a < 0 ? window.screen.width + a : a
    left = parseInt(h + ((g - width) / 2), 10)
    top = parseInt(i + ((f - height) / 2.5), 10)
    options = `${options}, top=${top}, left=${left}, `
  }
  options = `${options}width=${width}, height=${height}`

  const poppedWindow = window.open(url, title, options)

  poppedWindow._poppedUp = true

  return poppedWindow
}
