import 'bootstrap/js/tooltip'
import $ from 'jquery'
import ready from '../utils/ready'

ready(() => {
  $('.tooltip-toggler').tooltip({
    container: 'body',
  })

  $(document).on('click', '.tooltip-toggler', (event) => {
    if (event.target.href && event.target.href !== '#') {
      $('.tooltip-toggler').tooltip('hide')
    }
  })

  $('.tooltip-with-html').tooltip({
    html: true,
    container: 'body',
    placement: 'bottom',
    delay: { hide: 3000 },
  })
})
