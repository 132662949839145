import { Component } from 'react'
import { find, isArray } from 'lodash-es'
import PropTypes from 'prop-types'
const s = function (v) {
  return `${v}`
}
const otherValue = 'other'
const otherConfig = [otherValue, 'Other'] // TODO: Allow passing something like this as a prop.

class SelectField extends Component {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    options:
      PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ])),
        PropTypes.string,
        PropTypes.number,
      ])),
    onChange: PropTypes.func,
    value:
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    defaultValue:
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    blank: PropTypes.string,
    otherPrompt: PropTypes.string,
  }

  state = {
    userCustomOptions: [],
    lastSelectedValue: this.props.value,
  }

  initialValueIsNotInOptions = () => {
    if (!this.props.value) {
      return false
    }
    const value = this.props.value
    return find(this.props.options, (option) => (option === value)
        || (isArray(option) && option[0] === value)) === undefined
  }

  addUserCustomOption = (value) => {
    this.setState({
      userCustomOptions: this.state.userCustomOptions.concat(value),
    })
  }

  componentWillMount () {
    if (this.initialValueIsNotInOptions()) {
      this.addUserCustomOption(this.props.value)
    }
  }

  handleChange = (event) => {
    let value = event.target.value

    if (this.props.otherPrompt && `${value}` === `${otherValue}`) {
      value = `${prompt(this.props.otherPrompt)}`
      if (value) {
        this.addUserCustomOption(value)
      }
    }

    if (value) {
      this.setState({ lastSelectedValue: value })
      this.props.onChange && this.props.onChange(event, value)
    } else {
      this.props.onChange && this.props.onChange(event, this.state.lastSelectedValue)
    }
  }

  render () {
    const blank = this.props.blank
      ? this.makeOption(['', s(this.props.blank)])
      : undefined

    return (
      <select
        className={this.props.className}
        defaultValue={this.props.defaultValue === undefined ? undefined : s(this.props.defaultValue)}
        id={this.props.id}
        name={this.props.name}
        onChange={this.handleChange}
        value={this.props.value === undefined ? undefined : s(this.props.value)}
      >
        { blank }
        { this.props.options.map(this.makeOption) }
        { this.state.userCustomOptions.map(this.makeOption) }
        {
          this.props.otherPrompt
            ? this.makeOption(otherConfig)
            : undefined
        }
      </select>
    )
  }

  makeOption = (config) => {
    let display
    let value

    if (isArray(config)) {
      value = config[0]
      display = config[1]
    } else {
      value = display = config
    }

    return (
      <option
        key={value + display}
        value={s(value)}
      >
        { display }
      </option>)
  }
}

export default SelectField
