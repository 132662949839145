// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import { prettyDuration, titleCase } from 'd2/utils/StringHelpers'
import { register } from 'd2/intermediate/registry'
import Container from '../Container.jsx'
import FaIcon from '../FaIcon.jsx'
import FileUploadModel from '../flux/models/FileUploadModel.js'
import MediaModel from '../flux/models/MediaModel.js'
import MediaThumbnail from './MediaThumbnail.jsx'
import MetadataModel from '../flux/models/MetadataModel.js'
import PropTypes from 'prop-types'
import Texts from '../texts/texts.js'

// - -------------------------------------------------------------------- - //

class MediaFilePreview extends Component {
  static displayName = 'MediaFilePreview'

  static propTypes = {
    mediaModel: PropTypes.instanceOf(MediaModel),
    mediaJson: PropTypes.object,
    mediaStatus: PropTypes.string,
    file_upload: PropTypes.object,
    mp4Url: PropTypes.string,
    metadata: PropTypes.object,
    isUploadAuthorized: PropTypes.bool.isRequired,
    releaseId: PropTypes.string,
  }

  render () {
    let file
    let mediaModel

    file = mediaModel = this.props.mediaModel

    if (this.props.mediaJson) {
      mediaModel = this.props.mediaJson
      mediaModel.status = this.props.mediaStatus

      mediaModel.file_upload = new FileUploadModel(
        FileUploadModel.pickProperties(this.props.file_upload)
      )

      if (this.props.metadata) {
        mediaModel.metadata = new MetadataModel(
          MetadataModel.pickProperties(this.props.metadata)
        )
      }

      file = new MediaModel(MediaModel.pickProperties(mediaModel))
    }

    const headingNav = []

    if (this.props.isUploadAuthorized) {
      headingNav.push(
        <a href={`/files/${file.getValue('id')}/attach`}>
          <FaIcon icon='cloud-upload' />
          { ' ' }
          { Texts.fileDetails.replace }
        </a>
      )
    }

    headingNav.push(
      <a
        data-turbolinks='false'
        href={`/files/${file.getValue('id')}/download`}
      >
        <FaIcon icon='cloud-download' />
        { ' ' }
        { Texts.fileDetails.download }
      </a>
    )

    const isMusicCategory = file.isMusicCategory()

    return (
      <Container
        className='video-file-info-container'
        heading={Texts.fileDetails.attachedFile}
        nav={headingNav}
      >

        <div className='row'>
          <div className='col-md-8 col-sm-6'>
            <MediaThumbnail
              mediaId={file.getValue('id')}
              mediaName={file.getSongName()}
              mediaStatus={file.getValue('status')}
              mp4Url={this.props.mp4Url}
              thumbnailUrl={file.getThumbnail()}
            />
          </div>
          <div className='col-md-4 col-sm-6'>
            <dl>
              <dt>
                { Texts.fileDetails.duration }
                :
              </dt>
              <dd>
                { file.getDuration() ? prettyDuration(file.getDuration() / 1000) : Texts.fileDetails.noDuration }
              </dd>
              <dt>
                { Texts.fileDetails.category }
                :
              </dt>
              <dd>
                { titleCase(file.getValue('category')) }
              </dd>
              {
                isMusicCategory
                  ? null
                  : [
                    <dt key='dt'>
                      { Texts.fileDetails.subCategory }
                      :
                    </dt>,
                    <dd key='dd'>
                      { titleCase(file.getValue('subcategory')) }
                    </dd>,
                  ]
              }
              {
                this.props.releaseId
                  ? [
                    <dt key='dt'>
                      { Texts.fileDetails.order }
                      :
                    </dt>,
                    <dd key='dd'>
                      #
                      { this.props.releaseId }
                    </dd>,
                  ]
                  : null
              }
            </dl>
          </div>
        </div>

      </Container>
    )
  }
}

export default register('MediaFilePreview', MediaFilePreview)

// - -------------------------------------------------------------------- - //
