import './Overlay.css'
import { includes, throttle } from 'lodash-es'
import $ from 'jquery'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import createReactClass from 'create-react-class'
import cx from 'classnames'
const $window = $(window)

// TODO: import from d2 instead

const verticallyCenter = function () {
  if (!this._isMounted) return

  const $content = $(ReactDOM.findDOMNode(this.refs.content))
  const $inner = $(ReactDOM.findDOMNode(this.refs.inner))
  const contentHeight = $content.innerHeight()
  const innerHeight = $inner.outerHeight(true)
  let top = (contentHeight / 2) - (innerHeight / 2)

  top = Math.max(top, 0)

  if (this.props.light) {
    $inner.css({ top, padding: '30px' })
  } else {
    $inner.css('top', top)
  }
}

const Overlay = createReactClass({
  displayName: 'Overlay',

  propTypes: {
    className: PropTypes.string,
    alpha: PropTypes.number,
    onClickAway: PropTypes.func,
    wrapContentWith: PropTypes.any,
    contentKey: PropTypes.any,
    noVertical: PropTypes.bool,
  },

  getDefaultProps () {
    return {
      alpha: 0.7,
    }
  },

  componentDidMount () {
    this._isMounted = true
    const self = this
    this.verticallyCenter()

    $window.on('resize', this._throttledVerticallyCenter)

    this.$mediaElements().each(function () {
      this.addEventListener('loadedmetadata', self._throttledVerticallyCenter)
    })

    this.$loadableElements().on('load', this._throttledVerticallyCenter)
  },

  componentDidUpdate () {
    this.verticallyCenter()
  },

  componentWillUnmount () {
    this._isMounted = false
    const self = this

    $window.off('resize', this._throttledVerticallyCenter)

    this.$mediaElements().each(function () {
      this.removeEventListener('loadedmetadata', self._throttledVerticallyCenter)
    })

    this.$loadableElements().off('load', this._throttledVerticallyCenter)
  },

  $mediaElements () {
    return $(ReactDOM.findDOMNode(this)).find('video, audio')
  },

  $loadableElements () {
    return $(ReactDOM.findDOMNode(this)).find('img')
  },

  verticallyCenter,
  _throttledVerticallyCenter: throttle(verticallyCenter, 400),

  handleClickAway (event) {
    if (!this.props.onClickAway) {
      return
    }

    const clickAwayNodes = [
      ReactDOM.findDOMNode(this.refs.content),
      ReactDOM.findDOMNode(this.refs.inner),
    ]

    if (!includes(clickAwayNodes, event.target)) {
      return
    }

    event.preventDefault()
    event.stopPropagation()
    this.props.onClickAway(event)
  },

  renderContent () {
    return (
      <div
        className='overlay-content'
        key={this.props.contentKey}
        ref='content'
      >
        <div
          className='overlay-inner'
          ref='inner'
        >
          { this.props.children }
        </div>
      </div>
    )
  },

  render () {
    const { props: { alpha, light, wrapContentWith, noVertical } } = this

    let style
    style = {
      background: `rgba(0, 0, 0, ${alpha})`,
    }
    if (light) {
      style = {
        background: `rgba(255, 255, 255, ${alpha})`,
      }
    }
    const classes = cx(this.props.className, {
      'overlay-no-vertical': noVertical,
      'overlay-container': !noVertical,
    })

    return (
      <div
        className={cx(classes)}
        onClick={this.handleClickAway}
        ref='container'
        style={style}
      >
        {
          wrapContentWith
            ? wrapContentWith(this.renderContent())
            : this.renderContent()
        }
      </div>
    )
  },
})

export default Overlay
