import $ from 'jquery'
import Errors from '../utils/Errors'
import FileUploader from '../utils/FileUploader'
import Form from '../components/Form'

const FileUploadForm = function ($form, options) {
  (options === undefined) && (options = {});
  (options.autoSubmit === undefined) && (options.autoSubmit = true)
  this.minBytes = options.minBytes
  this.maxBytes = options.maxBytes

  this.$form = $form
  this.$fileInput = this.$form.find('input:file')
  this.$submitButton = this.$form.find('input:submit')
  this.$progressBar = this.$form.find('.upload-progress')
  this.analytics = options.analytics

  this._bindEvents(options)
  this.serverless = options.serverless
}

FileUploadForm.prototype.track = function (eventName, properties) {
  if (!this.analytics) {
    return false
  }

  this.analytics.track(eventName, properties)
}

FileUploadForm.prototype.trackErrors = function (errors) {
  if (!this.analytics) {
    return false
  }
}

FileUploadForm.prototype.registerFile = function (file) {
  if (!this.analytics || !file) {
    return false
  }

  const fileSizeBytes = file.size
  const fileSizeKB = fileSizeBytes / 1000.0
  const fileSizeMB = fileSizeKB / 1000.0
  const fileSizeGB = fileSizeMB / 1000.0
  const fileSizeTB = fileSizeGB / 1000.0
  const properties = {
    'File Name': file.name,
    'File Extension': (file.name || '').split('.').pop()
      .toLowerCase(),
    'File Size (Bytes)': fileSizeBytes,
    'File Size (KB)': Math.round(fileSizeKB * 100) / 100,
    'File Size (MB)': Math.round(fileSizeMB * 100) / 100,
    'File Size (GB)': Math.round(fileSizeGB * 100) / 100,
    'File Size (TB)': Math.round(fileSizeTB * 100) / 100,
    'File Type': file.type,
    'File Last Modified': new Date(file.lastModified).toString(),
  }

  this.analytics.register(properties)
}

FileUploadForm.prototype._bindEvents = function (options) {
  const me = this

  me.$fileInput.on('change', function () {
    const file = $(this).get(0).files[0]

    me.registerFile(file)
    me.track('Select File')

    me.$form.trigger('fileChange', [file])

    if (options.autoSubmit) {
      me.uploadFile()
    }
  })

  me.$fileInput.one('focus', () => {
    me.track('Browse')
  })

  if (!options.autoSubmit) {
    // Here I believe we cancel the default submit of the HTTP form, and instead, upload the file to S3.
    // I think that means we can safely ignore the form action, method, and other "submitting"-related
    // attributes that are present on the underlying <form> HTML element.
    me.$form.submit((event) => {
      event.preventDefault()
      me.uploadFile()
    })
  }
}

FileUploadForm.prototype._bindProgressEvents = function (uploader) {
  const me = this
  const hideProgress = function () {
    me.$progressBar.hide()
  }
  let lastProgressQuarter = 0
  const beganAt = Date.now()

  uploader.on('progress', (event) => {
    const percentDecimal = event.originalEvent.loaded / event.originalEvent.total
    const percent = Math.floor(percentDecimal * 100)
    const progressQuarter = parseInt(percent / 25)
    const now = Date.now()
    const elapsedMS = (now - beganAt) || 1
    const totalMS = (1.0 / percentDecimal) * elapsedMS
    const remainingMS = totalMS - elapsedMS

    if (percent === 100) {
      // Upload complete, waiting for response.
      me.$progressBar.removeAttr('value')
    } else {
      me.$progressBar.attr('value', percent)
    }

    // Track upload progress in increments of 25%
    if (me.analytics && progressQuarter > lastProgressQuarter) {
      lastProgressQuarter = progressQuarter
      me.track('Progress', {
        'Progress Percent': percent,
        'Progress Percent (Rounded)': progressQuarter * 25,
      })
    }

    me.$form.trigger('uploadProgress', [percent, remainingMS])
  })

  uploader.on('start', () => {
    me.$progressBar.attr('value', '0').show()
  })

  uploader.on('finish', hideProgress)
  uploader.on('error', hideProgress)
}

FileUploadForm.prototype.uploadFile = function () {
  const me = this
  const uploadType = me.$form.find('.upload-type').val()
  const uploadKey = me.$form.find('.upload-key').val()
  const uploaderOptions = {
    minBytes: this.minBytes,
    maxBytes: this.maxBytes,
    serverless: this.serverless,
  }
  const uploader = new FileUploader(me.$fileInput.get(0), uploaderOptions)
  const authenticityToken = $('meta[name=csrf-token]').attr('content')

  me._bindProgressEvents(uploader)
  uploader.setRequestHeader('x-csrf-token', authenticityToken)
  uploader.setRequestHeader('vydia-upload-type', uploadType)
  uploader.setRequestHeader('vydia-upload-key', uploadKey)

  me.removeErrors()

  uploader.on('start', (event, file) => {
    me.removeErrors()
    me.disableSubmit()
    me.registerFile(file)
    me.track('Start')
    me.$form.trigger('uploadStart', [file])
  })

  uploader.on('finish', (event, file, response) => {
    me.enableSubmit()
    me.track('Finish')
    me.$form.trigger('uploadFinish', [file, response])
  })

  uploader.on('error', (event, errors, file) => {
    const readableErrors = Errors.getReadableMessages(errors, {
      includeFieldName: true,
    })

    Form.displayErrorOnField(me.$fileInput, readableErrors)

    me.enableSubmit()
    me.trackErrors(errors)
    me.$form.trigger('uploadError', [errors, file])
  })

  uploader.startUpload()
}

FileUploadForm.prototype.disableSubmit = function () {
  this.$submitButton
    .prop('disabled', true)
    .addClass('disabled')
}

FileUploadForm.prototype.enableSubmit = function () {
  this.$submitButton
    .removeAttr('disabled')
    .removeClass('disabled')
}

FileUploadForm.prototype.removeErrors = function () {
  const me = this

  me.$fileInput.click(() => {
    Form.removeErrorFromField(me.$fileInput)
  })
}

export default FileUploadForm
