/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */

import $ from 'jquery'
import '../vendor/dtpicker.js'
import ready from '../utils/ready.js'

ready(() => {
  const $datePickers = $('.date-picker-input')
  $datePickers.each(function () {
    const $this = $(this)
    const allowPastDates = $this.data('allow-past-dates')
    const dateOnly = $this.data('date-only')

    $this.appendDtpicker({
      'futureOnly': !allowPastDates,
      'dateOnly': !!dateOnly,
      'calendarMouseScroll': false,
      'closeOnSelected': true,
      'todayButton': false,
      'autodateOnStart': false,
    })
  })
})
