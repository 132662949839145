/*

Migrating to full React SPA will take some time.
During the transition we will be writing new features in the new stack
and injecting them into the old SSR HTML stack.

We will use this file to register loadables and make them available to
use with the ruby SSR HTML `react_component` helper, without increasing
the size of our main application JS bundle.

@example
  <%== react_component('D2StoragePlanPicker') %>

*/
import { register } from './registry'
import EditAdditionalArtistsFormWithProviders from 'd2/loadables/EditAdditionalArtistsFormWithProviders'
import Footer from 'd2/loadables/Footer'
import InstagramBusinessButton from 'd2/loadables/InstagramBusinessButton'
import MainNavBarD2 from 'd2/loadables/MainNavBarD2'
import OneTimePasswordGate from 'd2/loadables/OneTimePasswordGate'
import PhoneNumberInput from 'd2/loadables/PhoneNumberInput'
import RearrangeAlbumTrackOrderAdapterWithProviders from 'd2/loadables/RearrangeAlbumTrackOrderAdapterWithProviders'
import SecuritySettings from 'd2/loadables/SecuritySettings'
import StoragePlanPicker from 'd2/loadables/StoragePlanPicker'
import StorageSpaceBanner from 'd2/loadables/StorageSpaceBanner'
import StorageTierModalWrapper from 'd2/loadables/StorageTierModalWrapper'
import StorageTierSidebar from 'd2/loadables/StorageTierSidebar'
import TemporarilyLockedModal from 'd2/loadables/TemporarilyLockedModal'

register('D2Footer', () => <Footer standalone />)
register('D2MainNavBarD2', (props: ComponentProps<typeof MainNavBarD2>) => (<MainNavBarD2
  d1
  standalone
  {...props}
/>))
register('D2StoragePlanPicker', () => <StoragePlanPicker standalone />)
register('D2StorageSpaceBanner', () => (<StorageSpaceBanner
  d1
  standalone
/>))
register('D2StorageTierModalWrapper', () => <StorageTierModalWrapper standalone />)
register('D2InstagramBusinessButton', () => <InstagramBusinessButton standalone />)
register('D2StorageTierSidebar', () => <StorageTierSidebar standalone />)
register('D2TemporarilyLockedModal', (props: ComponentProps<typeof TemporarilyLockedModal>) => (<TemporarilyLockedModal
  standalone
  {...props}
/>))

register('RearrangeAlbumTrackOrderAdapter', (props: any) => <RearrangeAlbumTrackOrderAdapterWithProviders {...props} />)
register('EditAdditionalArtistsFormWithProviders', (props: any) => <EditAdditionalArtistsFormWithProviders {...props} />)
register('OneTimePasswordGate', (props: ComponentProps<typeof OneTimePasswordGate>) => <OneTimePasswordGate {...props} />)
register('SecuritySettings', (props: ComponentProps<typeof SecuritySettings>) => (<SecuritySettings
  standalone
  {...props}
/>))
register('PhoneNumberInput', (props: ComponentProps<typeof PhoneNumberInput>) => <PhoneNumberInput {...props} />)
