export default function cropResize (file, targetWidth, targetHeight, callback) {
  if (/\.png$|\.jpg$|\.jpeg$|\.gif$/i.test(file.name)) {
    const reader = new FileReader()
    reader.onload = function (e) {
      const img = new Image()
      img.onload = function () {
        let width = img.width
        let height = img.height
        if (width >= targetWidth && height >= targetHeight) {
          let ratio = width / height
          const targetRatio = targetWidth / targetHeight
          while (ratio !== targetRatio) {
            if (width > (height * targetRatio)) {
              width--
            } else {
              height--
            }
            ratio = width / height
          }
          const top = img.height === height ? 0 : (img.height - height) / 2
          const left = img.width === width ? 0 : (img.width - width) / 2
          const canvas = document.createElement('canvas')
          canvas.width = targetWidth
          canvas.height = targetHeight
          const context = canvas.getContext('2d')
          context.drawImage(img, left, top, width, height, 0, 0, targetWidth, targetHeight)
          const url = canvas.toDataURL('image/png')
          callback(null, url)
        } else {
          callback(new Error('dimensions'))
        }
      }
      img.src = e.target.result
    }
    reader.readAsDataURL(file)
  } else {
    callback(new Error('extension'))
  }
}
