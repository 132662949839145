import { readableMS } from 'd2/utils/StringHelpers'
import $ from 'jquery'
import Demplate from '../utils/demplate/Demplate'
import Errors from '../utils/Errors'
import FileUploadForm from '../components/FileUploadForm'
import Model from '../utils/demplate/Model'
import capper from '../utils/capper'
import pageTitle from '../shared/pageTitle'
import ready from '../utils/ready'
import template from '../utils/template'
let $uploadFormField
let fileUpload
let model
let demplate

// Popup stuff
const mainWindow = window.opener

// Functions
let init
let setupDemplate
let onModelChange
let uploadStart
let uploadProgress
let uploadFinish
let uploadError
let appendErrors
let removeErrors
let clearFileInput
let dragActive
let effectiveDropTimer
let mainWindowIsOpen
let triggerMainWindowEvent

ready(() => {
// TODO: Use React here.
  const $uploadForm = $('#files-upload')
  const $uploadFieldWrapper = $('.btn-file')
  const $uploadField = $('input[type=file]')

  if ($uploadForm.length <= 0) return

  init = function () {
    setupDemplate()

    $uploadFormField = $uploadField.closest('.form-field')

    const serverless = {
      key: window.upload_key,
      type: 'video',
    }

    fileUpload = new FileUploadForm($uploadForm, {
      serverless,
    })

    fileUpload.$form.on('uploadStart', uploadStart)

    fileUpload.$form.on('uploadProgress', uploadProgress)

    fileUpload.$form.on('uploadFinish', uploadFinish)

    fileUpload.$form.on('uploadError', uploadError)

    // drag-and-drop
    if ((new window.XMLHttpRequest()).upload) { // is XHR2 available?
    // file drop
      $uploadFieldWrapper.get(0).addEventListener('dragover', (event) => {
        event.stopPropagation()
        event.preventDefault()
        dragActive(true)
      }, false)

      $uploadFieldWrapper.get(0).addEventListener('dragleave', (event) => {
        event.stopPropagation()
        event.preventDefault()
        dragActive(false)
      }, false)

      $uploadFieldWrapper.get(0).addEventListener('drop', () => {
        dragActive(false)
        // check if drop did anything
        effectiveDropTimer = setTimeout(() => {
          const val = $uploadField.val()
          if (!val || val.toString() === '') {
            // Browser does not support drag-and-drop file input fields;
            // open the file browser instead
            $uploadField.trigger('click')
          }
        }, 5)
      }, false)
    }

    window.onbeforeunload = function (event) {
      if (!model.get('uploadStarted')) return

      event && event.preventDefault && event.preventDefault()
      return 'WARNING: You will lose all upload progress!'
    }
  }

  setupDemplate = function () {
    model = new Model({
      uploadStarted: false,
      uploadCompleted: false,
      uploadErrored: false,
      uploadETA: null,
      uploadPercent: null,
    })

    demplate = new Demplate($('#uploader-feedback'),
      'uploader-feedback-template',
      { model })

    demplate.bind('.close', 'click', (event) => {
      event.preventDefault()
      window.close()
    })

    model.on('change', onModelChange)

    demplate.render()
  }

  onModelChange = function () {
    $uploadForm.toggleClass('active', model.get('uploadStarted'))
    $uploadForm.toggleClass('complete', model.get('uploadCompleted'))
    $uploadField.prop('disabled', model.get('uploadStarted') || model.get('uploadCompleted'))
  }

  // Setup
  mainWindowIsOpen = function () {
    return mainWindow && !mainWindow.closed
  }

  // Trigger an event on the main window's delegator
  //   if the main window is open.
  triggerMainWindowEvent = function (eventName, data) {
    if (mainWindowIsOpen() && mainWindow.delegator) {
      mainWindow.delegator.trigger(eventName, data)
    }
  }

  uploadStart = function () {
    model.set({
      uploadStarted: true,
      uploadCompleted: false,
      uploadErrored: false,
      uploadETA: null,
      uploadPercent: null,
    })

    pageTitle.reset()

    dragActive(false)
    removeErrors()

    clearFileInput()

    triggerMainWindowEvent('uploadStart')
  }

  uploadProgress = capper((event, percent, remainingMS) => {
    model.set({
      uploadETA: readableMS(remainingMS),
      uploadPercent: percent,
    })

    if (parseInt(percent) === 100) {
      pageTitle.set('Verifying Upload')
    } else {
      pageTitle.set(`${percent}% Complete`)
    }
  }, 1500)

  uploadFinish = function () {
    model.set({
      uploadStarted: false,
      uploadCompleted: true,
      uploadErrored: false,
      uploadETA: null,
      uploadPercent: null,
    })

    uploadProgress.cancel()

    pageTitle.flicker('UPLOAD SUCCESSFUL')

    clearFileInput()

    triggerMainWindowEvent('uploadFinish')
  }

  uploadError = function (event, errors) {
    model.set({
      uploadStarted: false,
      uploadCompleted: false,
      uploadErrored: true,
      uploadETA: null,
      uploadPercent: null,
    })

    uploadProgress.cancel()

    pageTitle.flicker('UPLOAD ERROR')

    clearFileInput()

    dragActive(false)
    removeErrors()

    appendErrors(errors)

    triggerMainWindowEvent('uploadError')
  }

  appendErrors = function (errors) {
  // Don't alert if errors.empty?
  // Because FileUploadForm would have already alerted.
    if (errors && Object.keys(errors).length > 0) {
      const readableErrors = Errors.getReadableMessages(errors, {
        includeFieldName: true,
      })
      const errorsMarkup = template('form-field-errors', {
        errors: readableErrors,
      })

      $uploadFormField.append(errorsMarkup)

      alert('Oops! The upload couldn\'t be completed. Please verify the file and try again.')
    }
  }

  removeErrors = function () {
    $uploadFormField.find('.input-errors').remove()
  }

  clearFileInput = function () {
    $uploadField.wrap('<form>').closest('form')
      .get(0)
      .reset()
    $uploadField.unwrap()
    clearTimeout(effectiveDropTimer)
  }

  dragActive = function (isActive) {
    $uploadFieldWrapper.toggleClass('active', !!isActive)
  }

  init()
})
