const w = window
const d = w.document
const initialTitle = d.title

const PageTitle = function (options) {
  options || (options = {})

  this.flickerFrequency = 1000
  this.reset()

  this.titleSuffix = options.titleSuffix || ''
}

// Start flickering the page title between the initial title and another value.
//
// @param valueOrFunction [String, Function] - the value to toggle.
//   If a Function is provided, the return value will be used.
//   If a String is provided the String will be used.
PageTitle.prototype.flicker = function (valueOrFunction) {
  const self = this
  self.reset()

  self.flickerValue = valueOrFunction

  self._flickerTimer = setInterval(() => {
    self.toggleFlicker()
  }, self.flickerFrequency)

  self.toggleFlicker(true)
}

// Toggle the flicker state.
//
// @param flickerOn [Boolean] - Specify on or off
PageTitle.prototype.toggleFlicker = function (flickerOn) {
  flickerOn === undefined && (flickerOn = !this._flickeredOn)
  this._flickeredOn = flickerOn

  if (flickerOn) {
    this.set(this.getFlickerValue())
  } else {
    this.revert()
  }
}

// Set the title with the suffix appended.
PageTitle.prototype.set = function (title) {
  return this.setWithoutSuffix(title + this.titleSuffix)
}

// Set the title without the suffix appended.
PageTitle.prototype.setWithoutSuffix = function (title) {
  this.currentTitle = title
  d.title = this.currentTitle
}

// Revert the title to the initial title.
// NOTE: does not stop the flickering.
PageTitle.prototype.revert = function () {
  return this.setWithoutSuffix(initialTitle)
}

// Stop flickering and revert to the initial title.
PageTitle.prototype.reset = function () {
  this.toggleFlicker(false)
  this._flickerTimer && clearInterval(this._flickerTimer)

  this.flickerValue = null
}

// Call this.flickerValue if it's a function, otherwise return it.
PageTitle.prototype.getFlickerValue = function () {
  if (typeof this.flickerValue === 'function') {
    return this.flickerValue()
  }

  return this.flickerValue
}

// Global pageTitle object
const pageTitle = new PageTitle({
  titleSuffix: ` - ${window.app_brand_name}`,
})

export default pageTitle
