import $ from 'jquery'
import ready from '../utils/ready'
let canSubmitSSyncForm

function hideOrShowRights ($toggle) {
  const checked = $toggle.prop('checked')
  if (checked) {
    $('#sync-rights-container').removeClass('hide')
    hideOrShowPolicies()
  } else {
    $('#sync-rights-container').addClass('hide')
    $('#sync-policies-container').addClass('hide')
  }
}

function hideOrShowPolicies () {
  const hasRights = $('input:radio[name=\'has_full_rights\']:checked').val()
  if (hasRights === 'true') {
    $('#sync-policies-container').removeClass('hide')
  } else {
    $('#sync-policies-container').addClass('hide')
  }
}

function bindListenerToToggle ($toggle, bind) {
  if (bind) {
    $toggle.on('change', () => {
      hideOrShowRights($toggle)
    })
  } else {
    $toggle.off('change')
  }
}

function bindListenerToRights (bind) {
  if (bind) {
    $('input:radio[name=\'has_full_rights\']').on('click', () => {
      hideOrShowPolicies()
    })
  } else {
    $('input:radio[name=\'has_full_rights\']').off('click')
  }
}

function initializeInputListeners (toggleExists, $toggle) {
  if (toggleExists) {
    hideOrShowRights($toggle)
    hideOrShowPolicies()
    bindListenerToToggle($toggle, true)
    bindListenerToRights(true)
  } else {
    bindListenerToToggle($toggle, false)
    bindListenerToRights(false)
  }
}

function validateSelect ($select) {
  const selectValue = $select.val()
  if (selectValue == null) {
    if ($select.next('.error').hasClass('hide')) {
      $select.next('.error').removeClass('hide')
    }
    canSubmitSSyncForm = false
  } else {
    if (!$select.next('.error').hasClass('hide')) {
      $select.next('.error').addClass('hide')
    }
    canSubmitSSyncForm = true
  }
}

function initializeFormSubmissionListener (formExists, $form) {
  let syncType
  if (typeof window.syncSettingsFormData != 'undefined') {
    syncType = window.syncSettingsFormData.social_sync_type
  }
  if (formExists) {
    $form.on('submit', (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (syncType === 'facebook_sync') {
        validateSelect($('#category_select'))
      } else {
        canSubmitSSyncForm = true
      }
      if ($('#sync-policies-container').is(':visible')) {
        validateSelect($('#facebook_sync_policy'))
        validateSelect($('#youtube_sync_policy'))
      }
      if (canSubmitSSyncForm) {
        $form[0].submit()
      }
    })
  } else {
    $form.off('submit')
  }
}

function selectExistingValue (windowVariable, $select) {
  if (windowVariable && windowVariable !== 'undefined' && $select.val() !== null) {
    $select.val(windowVariable)
  }
}

ready(() => {
  const $toggle = $('#social_is_syncing')
  const $form = $('#sync-settings-form')
  const formExists = $form.length
  const toggleExists = $toggle.length
  initializeInputListeners(toggleExists, $toggle)
  initializeFormSubmissionListener(formExists, $form)
  if (typeof window.syncSettingsFormData != 'undefined') {
    const syncType = window.syncSettingsFormData.social_sync_type
    if (syncType === 'facebook_sync') {
      selectExistingValue(window.syncSettingsFormData.default_subcategory, $('#category_select'))
    }
    selectExistingValue(window.syncSettingsFormData.youtube_sync_policy, $('#youtube_sync_policy'))
    selectExistingValue(window.syncSettingsFormData.facebook_sync_policy, $('#facebook_sync_policy'))
  }
})
