import Navigation from 'd2/utils/Navigation'

const ClipboardJsMounter = {

  mount (copiable, element) {
    const mount = function () {
      Navigation.onPageNavigation(unmount)
    }

    const unmount = function () {
      if (element) {
        copiable.unbindListeners()
      }

      Navigation.offPageNavigation(unmount)
    }
    return mount()
  },

}

export default ClipboardJsMounter
