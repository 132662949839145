// - -------------------------------------------------------------------- - //

'use strict'

import $ from 'jquery'
import ArtistActionMixin from './shared/ArtistActionMixin.js'
import FileUploader from '../../../utils/FileUploader.js'
import Flux from '../flux.js'
import cropResize from '../../../utils/cropResize.js'
const Dispatcher = Flux.dispatcher

// - -------------------------------------------------------------------- - //

const AudioToVideoAction = {

  saveAndFinish (artist, files, globalSettings) {
    const filesAttrs = files.map((model, index, collection) => {
      const attrs = {
        title: model.getValue('song_name'),
        genre: model.getValue('genre'),
        label: model.getValue('label'),
        composer: model.getValue('composer'),
        video_isrc: model.getValue('video_isrc'),
        audio_isrc: model.getValue('audio_isrc'),
        track_number: index + 1,
        font: model.getValue('font'),
        show_text: model.getValue('show_text'),
        font_color: model.getValue('font_color'),
        font_shadow: model.getValue('font_shadow'),
      }
      if (model.getValue('file_upload_id')) {
        attrs['audio_file_upload_id'] = model.getValue('file_upload_id')
      }
      if (globalSettings.getValue('artwork')) {
        if (globalSettings.getValue('artwork').getValue('file_upload_id')) {
          attrs['artwork_file_upload_id'] = globalSettings.getValue('artwork').getValue('file_upload_id')
        }
      } else if (model.getValue('artwork') && model.getValue('artwork').getValue('file_upload_id')) {
        attrs['artwork_file_upload_id'] = model.getValue('artwork').getValue('file_upload_id')
      }
      if (globalSettings.getValue('backgroundType') === 'upload') {
        if (globalSettings.getValue('backgroundImage')
          ?.getValue('file')
          ?.getValue('file_upload_id')
        ) {
          attrs['background_file_upload_id'] = globalSettings
            .getValue('backgroundImage')
            .getValue('file')
            .getValue('file_upload_id')
        }
      } else if (globalSettings.getValue('backgroundType') === 'choose') {
        attrs['background_url'] = globalSettings.getValue('backgroundTemplate')
      }

      if (globalSettings.getValue('backgroundColor')) {
        attrs['background_color'] = globalSettings.getValue('backgroundColor')
      }
      return attrs
    })

    $.ajax({
      url: '/api/a2v/finish',
      type: 'POST',
      data: {
        files: filesAttrs,
        artist_id: artist.getValue('id'),
      },
    })
      .fail((data) => {
        const errors = data && data.responseJSON && data.responseJSON.errors ? data.responseJSON.errors : {}
        Dispatcher.dispatch({
          actionType: 'BUILD_ERROR',
          errors,
        })
      })
      .then((data) => {
        Dispatcher.dispatch({
          actionType: 'BUILD_SUCCESS',
          medias: data.medias || [],
        })
      })

    return {
      actionType: 'SAVE_AND_FINISH',
    }
  },

  saveAndContinue (currentStep) {
    return {
      actionType: 'SAVE_AND_CONTINUE',
      currentStep,
    }
  },

  showAudioWarning () {
    return {
      actionType: 'SHOW_AUDIO_WARNING',
    }
  },

  cancelAudioWarning () {
    return {
      actionType: 'CANCEL_AUDIO_WARNING',
    }
  },

  reorder (order) {
    return {
      actionType: 'CHANGE_FILE_ORDER',
      order,
    }
  },

  confirmAudioWarning () {
    return {
      actionType: 'CONFIRM_AUDIO_WARNING',
    }
  },

  showBackgroundWarning () {
    return {
      actionType: 'SHOW_BACKGROUND_WARNING',
    }
  },

  cancelBackgroundWarning () {
    return {
      actionType: 'CANCEL_BACKGROUND_WARNING',
    }
  },

  confirmBackgroundWarning () {
    return {
      actionType: 'CONFIRM_BACKGROUND_WARNING',
    }
  },

  globalSettings () {
    return {
      actionType: 'GLOBAL_SETTINGS',
    }
  },

  changeStep (step) {
    return {
      actionType: 'CHANGE_STEP',
      step,
    }
  },

  uploadFile (file) {
    const fileUploader = new FileUploader(file, {
      ext: ['wav'],
      serverless: {
        key: '', // Key is only used to associate with a media that shares the same upload_key value.
        type: 'a2vAudio',
      },
    })

    Dispatcher.dispatch({
      actionType: 'BATCH_UPLOAD_ADD_FILE',
      filename: file.name,
    })

    const authenticityToken = $('meta[name=csrf-token]').attr('content')
    fileUploader.setRequestHeader('x-csrf-token', authenticityToken)

    fileUploader.on('error', (event, errors, file) => {
      Dispatcher.dispatch({
        actionType: 'BATCH_UPLOAD_ERROR',
        filename: file.name,
        errors,
      })
    })
    fileUploader.on('start', (event, file) => {
      Dispatcher.dispatch({
        actionType: 'BATCH_UPLOAD_START',
        filename: file.name,
      })
    })

    fileUploader.on('progress', (event) => {
      // `event` is a jQuery event object
      event = event.originalEvent
      Dispatcher.dispatch({
        actionType: 'BATCH_UPLOAD_PROGRESS',
        filename: file.name,
        length: event.total,
        uploaded: event.loaded,
      })
    })

    fileUploader.on('finish', (event, file, fileUploadId) => {
      Dispatcher.dispatch({
        actionType: 'BATCH_UPLOAD_COMPLETE',
        filename: file.name,
        file_upload_id: fileUploadId,
      })
    })

    fileUploader.startUpload()

    return {
      actionType: '__UNHANDLED',
    }
  },

  selectFile (file) {
    return {
      actionType: 'BATCH_UPLOAD_SELECT_FILE',
      filename: file ? file.getValue('filename') : null,
    }
  },

  selectFirstFile () {
    return {
      actionType: 'BATCH_UPLOAD_SELECT_FIRST_FILE',
    }
  },

  removeFile (file) {
    return {
      actionType: 'BATCH_UPLOAD_REMOVE_FILE',
      filename: file ? file.getValue('filename') : null,
    }
  },

  previousSong () {
    return {
      actionType: 'BATCH_UPLOAD_PREVIOUS_SONG',
    }
  },

  nextSong () {
    return {
      actionType: 'BATCH_UPLOAD_NEXT_SONG',
    }
  },

  toggleEyeDropper (enabled) {
    return {
      actionType: 'TOGGLE_EYE_DROPPER',
      enabled,
    }
  },

  updateVideoSettings (field) {
    return {
      actionType: 'VIDEO_SETTINGS_UPDATE',
      field,
    }
  },

  removeArtwork () {
    return {
      actionType: 'VIDEO_SETTINGS_ARTWORK_REMOVE',
    }
  },

  uploadArtwork (file) {
    cropResize(file, 900, 900, (error, url) => {
      Dispatcher.dispatch({
        actionType: 'VIDEO_SETTINGS_ARTWORK_IMAGE',
        filename: file.name,
        url,
      })

      if (error) {
        Dispatcher.dispatch({
          actionType: 'VIDEO_SETTINGS_ARTWORK_UPLOAD_ERROR',
          filename: file.name,
          errors: [error.message],
        })
      } else {
        const fileUploader = new FileUploader(file, {
          ext: ['png', 'jpg', 'jpeg', 'tiff'],
          serverless: {
            key: '', // Key is only used to associate with a media that shares the same upload_key value.
            type: 'a2vArtwork',
          },
        })

        const authenticityToken = $('meta[name=csrf-token]').attr('content')
        fileUploader.setRequestHeader('x-csrf-token', authenticityToken)

        fileUploader.on('error', (event, errors, file) => {
          Dispatcher.dispatch({
            actionType: 'VIDEO_SETTINGS_ARTWORK_UPLOAD_ERROR',
            filename: file.name,
            errors,
          })
        })

        fileUploader.on('start', (event, file) => {
          Dispatcher.dispatch({
            actionType: 'VIDEO_SETTINGS_ARTWORK_UPLOAD_START',
            filename: file.name,
            uploaded: 0,
          })
        })

        fileUploader.on('progress', (event) => {
          event = event.originalEvent
          Dispatcher.dispatch({
            actionType: 'VIDEO_SETTINGS_ARTWORK_UPLOAD_PROGRESS',
            filename: file.name,
            length: event.total,
            uploaded: event.loaded,
          })
        })

        fileUploader.on('finish', (event, file, fileUploadId) => {
          Dispatcher.dispatch({
            actionType: 'VIDEO_SETTINGS_ARTWORK_UPLOAD_COMPLETE',
            filename: file.name,
            file_upload_id: fileUploadId,
          })
        })

        fileUploader.startUpload()
      }
    })

    return {
      actionType: '__UNHANDLED',
    }
  },

  updateGlobalSettings () {
    return {
      actionType: 'GLOBAL_SETTINGS_UPDATE',
    }
  },

  setArtwork (artwork) {
    return {
      actionType: 'GLOBAL_SETTINGS_ARTWORK',
      artwork,
    }
  },

  setBackgroundType (backgroundType) {
    return {
      actionType: 'GLOBAL_SETTINGS_BG_TYPE',
      backgroundType,
    }
  },

  setBackgroundColor (color) {
    return {
      actionType: 'GLOBAL_SETTINGS_BG_COLOR',
      backgroundColor: color,
    }
  },

  setBackgroundTemplate (template) {
    return {
      actionType: 'GLOBAL_SETTINGS_BG_TEMPLATE',
      backgroundTemplate: template,
    }
  },

  removeBackgroundImage () {
    return {
      actionType: 'GLOBAL_SETTINGS_BG_REMOVE',
    }
  },

  uploadBackgroundImage (file) {
    cropResize(file, 1920, 1080, (error, url) => {
      Dispatcher.dispatch({
        actionType: 'GLOBAL_SETTINGS_BG_IMAGE',
        filename: file.name,
        url,
      })

      if (error) {
        Dispatcher.dispatch({
          actionType: 'GLOBAL_SETTINGS_BG_UPLOAD_ERROR',
          filename: file.name,
          errors: [error.message],
        })
      } else {
        const fileUploader = new FileUploader(file, {
          ext: ['png', 'jpg', 'jpeg', 'gif'],
          serverless: {
            key: '', // Key is only used to associate with a media that shares the same upload_key value.
            type: 'a2vBackgroundImage',
          },
        })

        const authenticityToken = $('meta[name=csrf-token]').attr('content')
        fileUploader.setRequestHeader('x-csrf-token', authenticityToken)

        fileUploader.on('error', (errors) => {
          Dispatcher.dispatch({
            actionType: 'GLOBAL_SETTINGS_BG_UPLOAD_ERROR',
            filename: file.name,
            errors,
          })
        })

        fileUploader.on('start', (event, file) => {
          Dispatcher.dispatch({
            actionType: 'GLOBAL_SETTINGS_BG_UPLOAD_START',
            filename: file.name,
            uploaded: 0,
          })
        })

        fileUploader.on('progress', (event) => {
          event = event.originalEvent
          Dispatcher.dispatch({
            actionType: 'GLOBAL_SETTINGS_BG_UPLOAD_PROGRESS',
            filename: file.name,
            length: event.total,
            uploaded: event.loaded,
          })
        })

        fileUploader.on('finish', (event, file, fileUploadId) => {
          Dispatcher.dispatch({
            actionType: 'GLOBAL_SETTINGS_BG_UPLOAD_COMPLETE',
            filename: file.name,
            file_upload_id: fileUploadId,
          })
        })

        fileUploader.startUpload()
      }
    })

    return {
      actionType: '__UNHANDLED',
    }
  },

}

// - -------------------------------------------------------------------- - //

export default Flux.createCompositeActions(
  ArtistActionMixin({ actionPrefix: 'AUDIO_TO_VIDEO' }),
  AudioToVideoAction
)
// - -------------------------------------------------------------------- - //
