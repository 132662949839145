// - -------------------------------------------------------------------- - //

import { Component } from 'react'
import { register } from 'd2/intermediate/registry'
import PropTypes from 'prop-types'

// - -------------------------------------------------------------------- - //

const styles = {

  container: {
    display: 'block',
    position: 'relative',
    height: 0,
    padding: 0,
    overflow: 'hidden',
    paddingBottom: `${9 / 16 * 100}%`,
  },

  iframe: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },

}

// - -------------------------------------------------------------------- - //

class YouTubeEmbed extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
  }

  render () {
    return (
      <div style={styles.container}>
        <iframe
          allowFullScreen
          frameBorder='0'
          scrolling={false}
          src={`https://www.youtube.com/embed/${this.props.token}`}
          style={styles.iframe}
          title={`youtube-embed-${this.props.token}`}
        />
      </div>
    )
  }
}

export default register('YouTubeEmbed', YouTubeEmbed)
