// Function may only be executed once every throttleMs.
// Similar to throttler except:
//   The function is run as soon as it is called
//     rather than waiting on every call until the timer expires.
//   Subsequent calls are put on hold until after the timer expires.
//
// When the timer expires, the last given arguments are used
//   when calling the function.
//
// @param fn [Function]
//   The behavior to cap.
// @param throttleMs [Integer]
//   Number of milliseconds until the function runs again.
export default function (fn, throttleMs) {
  let timer
  let onHold = false
  let lastArgs

  throttleMs = throttleMs || 500

  const capperFn = function () {
    lastArgs = arguments

    if (timer) {
      onHold = true
      return
    }

    fn.apply(null, lastArgs)

    timer = setTimeout(() => {
      onHold = false
      timer = null

      if (onHold) {
        fn.apply(null, lastArgs)
      }
    }, throttleMs)
  }

  // Cancel the timer and clear onHold.
  capperFn.cancel = function () {
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
    onHold = false
  }

  return capperFn
}
