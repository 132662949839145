// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import Container from './Container.jsx'
import Overlay from './Overlay.jsx'
import cx from 'classnames'

// - -------------------------------------------------------------------- - //

const buttonClasses = cx({
  'btn': true,
  'btn-primary': true,
})

const style = {
  h1: {
    'fontSize': '2em',
    'textAlign': 'center',
    'margin': '30px auto 10px auto',
  },
  p: {
    'padding': '25px',
    'marginBottom': '20px',
  },
  inputWrapper: {
    'paddingBottom': '25px',
    'paddingLeft': '25px',
    'paddingRight': '25px',
    'marginBottom': '20px',
  },
  required: {
    'position': 'relative',
    'left': '-3px',
    'color': '#FF2F65',
    'fontSize': '12px',
    'fontFamily': 'source_sans_proregular',
  },
}

class FacebookSyncMessageOverlay extends Component {
  state = {
    selectedCategory: '',
    showError: false,
    selectValidation: 'Please choose a category for your synced videos.',
  }

  onAccept = (e) => {
    e.preventDefault()
    const category = this.state.selectedCategory
    if (category) {
      this.props.onAccept(this.state.selectedCategory)
    } else {
      this.setState({
        'showError': true,
      })
    }
  }

  onDecline = (e) => {
    e.preventDefault()
    this.props.onDecline()
  }

  updateSelectedCategory = (e) => {
    this.setState({
      'selectedCategory': e.target.value,
      'showError': false,
    })
  }

  renderCategoryOptions = () => this.props.subCategories.map((category, i) => (<option
    key={i} // eslint-disable-line react/no-array-index-key
    value={category.value}
  >
    { category.label }
  </option>))

  render () {
    return (
      <Overlay>
        <Container className='container-xs'>
          <h1 style={style.h1}>
            Enable Social Sync for Facebook
          </h1>
          <p style={style.p}>
            Would you like to enable Social Sync for Facebook? Once enabled,
            Vydia can store all of your video content on the platform,
            making it easy to protect your videos across the web.
          </p>
          <div style={style.inputWrapper}>
            <label
              className='form-label'
              htmlFor='FacebookSyncMessageOverlay-select'
            >
              <span style={style.required}>
                *
              </span>
              Category
            </label>
            <select
              className='form-input'
              defaultValue=''
              id='FacebookSyncMessageOverlay-select'
              onChange={this.updateSelectedCategory}
            >
              <option
                disabled
                value=''
              >
                { ' ' }
                -- Choose Category --
                { ' ' }
              </option>
              { this.renderCategoryOptions() }
            </select>
            <p className='form-note'>
              If multiple categories are appropriate, choose the best fit.
            </p>
            <p className='error'>
              { this.state.showError ? this.state.selectValidation : '' }
            </p>
          </div>
          <nav className='secondary-nav'>
            <ul>
              <li>
                <a
                  className={buttonClasses}
                  onClick={this.onAccept}
                >
                  YES & CONTINUE
                </a>
              </li>
              <li>
                <a onClick={this.onDecline}>
                  No & Continue
                </a>
              </li>
            </ul>
            <div className='clear' />
          </nav>
        </Container>
      </Overlay>
    )
  }
}

export default FacebookSyncMessageOverlay

// - -------------------------------------------------------------------- - //
