import $ from 'jquery'
import Navigation from 'd2/utils/Navigation'
import ready from '../utils/ready'

const FacebookSyncListener = {

  messageConstructor (type, message) {
    $('ul.success-notices').remove()
    $('ul.error-notices').remove()
    const list = $('<ul>')
    let alert
    const listItem = $('<li>')
    listItem.html(message)
    if (type === 'success') {
      alert = list.addClass('notices success-notices').append(listItem)
    } else {
      alert = list.addClass('notices error-notices').append(listItem)
    }
    $('#main-header').after(alert)
  },

  ajaxRequest (el, enabledSync) {
    let toggle
    let message
    const self = this
    const artistId = $(el).data('artist-id')
    const pageId = $(el).data('page-id')
    if (enabledSync) {
      toggle = 'enable_facebook_sync'
      message = 'Success! Facebook Page Now Syncing'
    } else {
      toggle = 'disable_facebook_sync'
      message = 'Success! Facebook Page Sync Now Disconnected.'
    }
    const url = `/artists/${artistId}/${toggle}/${pageId}`
    $.ajax({
      type: 'POST',
      url,
      data: {
        authenticity_token: $('meta[name=csrf-token]').attr('content'),
      },
      success () {
        self.messageConstructor('success', message)
      },
      error () {
        self.messageConstructor('error', 'Error! Something Went Wrong.')
      },
    })
  },

  enableSync (el) {
    this.ajaxRequest(el, true)
  },

  disableSync (el) {
    this.ajaxRequest(el, false)
  },

  assertChange (el) {
    const enabledSync = $(el).is(':checked')
    if (enabledSync) {
      this.enableSync(el)
    } else {
      this.disableSync(el)
    }
  },

  init (el) {
    const self = this
    $(el).change(() => {
      self.assertChange(el)
    })
  },
}

const YoutubeSyncListener = {

  messageConstructor (type, message) {
    $('ul.success-notices').remove()
    $('ul.error-notices').remove()
    const list = $('<ul>')
    let alert
    const listItem = $('<li>')
    listItem.html(message)
    if (type === 'success') {
      alert = list.addClass('notices success-notices').append(listItem)
    } else {
      alert = list.addClass('notices error-notices').append(listItem)
    }
    $('#main-header').after(alert)
  },

  ajaxRequest (el, enabledSync) {
    let toggle
    let message
    const self = this
    const artistId = $(el).data('artist-id')
    const channelId = $(el).data('channel-id')
    if (enabledSync) {
      toggle = 'enable_youtube_sync'
      message = 'Success! YouTube Channel Now Syncing'
    } else {
      toggle = 'disable_youtube_sync'
      message = 'Success! YouTube Channel Sync Now Disconnected.'
    }
    const url = `/artists/${artistId}/${toggle}/${channelId}`
    $.ajax({
      type: 'POST',
      url,
      data: {
        authenticity_token: $('meta[name=csrf-token]').attr('content'),
      },
      success () {
        self.messageConstructor('success', message)
      },
      error () {
        self.messageConstructor('error', 'Error! Something Went Wrong.')
      },
    })
  },

  enableSync (el) {
    this.ajaxRequest(el, true)
  },

  disableSync (el) {
    this.ajaxRequest(el, false)
  },

  assertChange (el) {
    const enabledSync = $(el).is(':checked')
    if (enabledSync) {
      this.enableSync(el)
    } else {
      this.disableSync(el)
    }
  },

  init (el) {
    const self = this
    $(el).change(() => {
      self.assertChange(el)
    })
  },
}

ready(() => {
  if ($('#youtube-sync').length) {
    YoutubeSyncListener.init('#youtube-sync')
  }

  if ($('#facebook-sync').length) {
    FacebookSyncListener.init('#facebook-sync')
  }
})

Navigation.onPageNavigation(() => {
  $('#facebook-sync').off()
  $('#youtube-sync').off()
})
