// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import { extend, omit } from 'lodash-es'
import PropTypes from 'prop-types'
import RcTooltip from 'rc-tooltip'

const styles = {
  display: 'block',
  maxWidth: '250px',
}

// - -------------------------------------------------------------------- - //

// This class is a wrapper for rc-tooltip
// https://github.com/react-component/tooltip

// - -------------------------------------------------------------------- - //

class Tooltip extends Component {
  static propTypes = {
    // One of (overlay|title) is required.
    overlay: PropTypes.element, // The content of tooltip.
    title: PropTypes.string, // The content of tooltip.
    placement: PropTypes.string,
  }

  static defaultProps = {
    placement: 'top',
  }

  render () {
    const tipOverlay
      = this.props.overlay
        ? this.props.overlay
        : (<div style={styles}>
          { this.props.title }
        </div>)

    const props = extend(omit(this.props, 'title'), {
      destroyTooltipOnHide: true,
      overlay: tipOverlay,
    })

    return (
      <RcTooltip {...props}>
        { this.props.children }
      </RcTooltip>
    )
  }
}

// - -------------------------------------------------------------------- - //

export default Tooltip

// - -------------------------------------------------------------------- - //
