// - -------------------------------------------------------------------- - //

'use strict'

import { assetUri } from 'd2/utils/Routes'
import Container from '../Container.jsx'
import FaIcon from '../FaIcon.jsx'
import Overlay from '../Overlay.jsx'
import PropTypes from 'prop-types'
import Texts from '../texts/texts.js'
import createReactClass from 'create-react-class'

// - -------------------------------------------------------------------- - //

export default createReactClass({
  displayName: 'MediaPlayerOverlay',

  propTypes: {
    userIsOwner: PropTypes.bool,
    status: PropTypes.string.isRequired,
    mediaId: PropTypes.number.isRequired,
    mp4Url: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string.isRequired,
    mediaName: PropTypes.string.isRequired,
    mediaStatus: PropTypes.string.isRequired,
    onClickAway: PropTypes.func.isRequired,
    bottomNav: PropTypes.element,
  },

  getDefaultProps () {
    return {
      userIsOwner: true,
    }
  },

  renderBottomNav () {
    if (this.props.bottomNav) {
      return this.props.bottomNav
    }

    if (this.props.userIsOwner) {
      return (
        <a href={`/service?media_id=${this.props.mediaId}`}>
          <FaIcon icon='mail-forward' />
          { ' ' }
          { Texts.fileManager.player.release }
        </a>
      )
    }

    return undefined
  },

  render () {
    let preview
    const hasVideo = !!this.props.mp4Url

    if (hasVideo) {
      const videoStyle = {
        width: '100%',
      }

      preview = (
        <video
          autoPlay
          controls
          style={videoStyle}
        >
          <source
            data-test={this.props.mediaId}
            src={this.props.mp4Url}
            type='video/mp4'
          />
        </video>
      )
    } else {
      const thumbStyle = this.props.thumbnailUrl
        ? { backgroundImage: `url('${this.props.thumbnailUrl}')` }
        : this.props.status === 'processing'
          ? { backgroundImage: `url('${assetUri('/images/files/default_thumbnail.jpg')}')` }
          : { backgroundColor: 'black' }

      preview = (
        <div
          className='preview-thumb'
          style={thumbStyle}
        />
      )
    }

    const nav = (
      <a onClick={this.props.onClickAway}>
        <FaIcon icon='times' />
      </a>
    )

    return (
      <Overlay
        className='media-preview-overlay'
        onClickAway={this.props.onClickAway}
      >
        <Container
          className='container-xs'
          heading={Texts.fileManager.player.title}
          nav={nav}
        >

          <div className={`preview ${this.props.mediaStatus}`}>
            <h3>
              { this.props.mediaName }
            </h3>
            { preview }
          </div>

          <div className={`status ${this.props.mediaStatus}`}>
            { this.renderBottomNav() }
          </div>
        </Container>
      </Overlay>
    )
  },

})

// - -------------------------------------------------------------------- - //
