import $ from 'jquery'
import PopupUploader from './PopupUploader'
import ready from '../utils/ready'
let $browseButton
let popupUploader

ready(() => {
  const $form = $('#attach-media-file-form')
  if ($form.length > 0) {
    $browseButton = $('#browse-hard-drive')

    popupUploader = new PopupUploader($form, {
      modalID: 'uploader-opened-modal',
      modalTemplateID: 'uploader-opened-modal-template',
    })

    $browseButton.on('click', (event) => {
      event.preventDefault()
      const url = $browseButton.data('url')
      popupUploader.open(url)
    })
  }
})
