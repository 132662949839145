// - -------------------------------------------------------------------- - //

'use strict'

import { inherits } from 'util'

// - -------------------------------------------------------------------- - //

function Collection () {
  this.length = 0
  this.init()
}

Collection.prototype = {

  init () {
  },

  add (model) {
    this[this.length] = model
    this.length++
  },

  get (key) {
    return this[key]
  },

  del (model) {
    const index = this.indexOf(model)
    if (index > -1) {
      this.splice(index, 1)
    }
  },

  empty () {
    this.splice(0, this.length)
  },

  isEmpty () {
    return !this.hasAny()
  },

  toArray () {
    return this.slice(0, this.length)
  },

  hasAny () {
    return this.length > 0
  },

  reorder (order) {
    const copy = this.slice()
    this.empty()
    order.forEach((i) => {
      this.add(copy[i])
    })
  },

  map: Array.prototype.map,
  filter: Array.prototype.filter,
  forEach: Array.prototype.forEach,
  indexOf: Array.prototype.indexOf,
  splice: Array.prototype.splice,
  slice: Array.prototype.slice,
  sort: Array.prototype.sort,

}

// - -------------------------------------------------------------------- - //

Collection.createCollection = function (proto) {
  proto = proto || {}
  const NewCollection = function () {
    Collection.apply(this, arguments)
  }
  inherits(NewCollection, Collection)
  Object.keys(proto).forEach((name) => {
    NewCollection.prototype[name] = proto[name]
  })
  return NewCollection
}

Collection.isCollection = function (arg) {
  return arg instanceof Collection
}

export default Collection

// - -------------------------------------------------------------------- - //
