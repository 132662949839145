// - -------------------------------------------------------------------- - //

'use strict'

import Collection from './Collection.js'

// - -------------------------------------------------------------------- - //

export default Collection.createCollection({

  getArtistById (id) {
    const filtered = this.filter((artist) => parseInt(artist.getValue('id')) === parseInt(id))
    return filtered[0]
  },

})

// - -------------------------------------------------------------------- - //
