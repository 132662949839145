// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import { assetUri } from 'd2/utils/Routes'
import { register } from 'd2/intermediate/registry'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import FaIcon from '../FaIcon.jsx'
import MediaPlayerOverlay from './MediaPlayerOverlay.jsx'
import PropTypes from 'prop-types'
import Texts from '../texts/texts.js'
import TransitionDurations from 'd2/utils/transition_durations'

const overlayTransitionDurations = TransitionDurations('overlay')

// - -------------------------------------------------------------------- - //

class MediaThumbnail extends Component {
  static propTypes = {
    userIsOwner: PropTypes.bool,
    href: PropTypes.string,
    mp4Url: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    mediaId: PropTypes.number.isRequired,
    mediaName: PropTypes.string.isRequired,
    mediaStatus: PropTypes.string.isRequired,
    overlayBottomNav: PropTypes.element,
  }

  static defaultProps = {
    userIsOwner: true,
  }

  state = {
    showOverlay: false,
  }

  toggleOverlay = (e) => {
    e.preventDefault()
    this.setState({
      showOverlay: !this.state.showOverlay,
    })
  }

  render () {
    const mediaStatus = this.props.mediaStatus

    let status
    let overlay
    let thumbnail
    let style
    const anchorProps = { href: this.props.href }
    let anchorContent

    if (mediaStatus === 'encoded' || mediaStatus === 'uploaded') {
      style = this.props.thumbnailUrl
        ? { backgroundImage: `url('${this.props.thumbnailUrl}')` }
        : { backgroundColor: 'black' }
      status = 'streaming'

      if (!this.props.href) {
        anchorProps.onClick = this.toggleOverlay
        anchorContent = <FaIcon icon='play-circle-o' />
      }

      thumbnail = (
        <a
          className='fm-thumb-link'
          style={style}
          {...anchorProps}
        >
          { anchorContent }
        </a>
      )
    } else if (mediaStatus === 'encoding' || mediaStatus === 'uploading') {
      style = this.props.thumbnailUrl
        ? { backgroundImage: `url('${this.props.thumbnailUrl}')` }
        : { backgroundImage: `url('${assetUri('/images/files/default_thumbnail.jpg')}')` }
      status = 'processing'

      if (!this.props.href) {
        anchorProps.onClick = this.toggleOverlay
        anchorContent = <FaIcon icon='repeat' />
      }

      thumbnail = (
        <a
          className='fm-thumb-link'
          style={style}
          {...anchorProps}
        >
          { anchorContent }
        </a>
      )
    } else if (mediaStatus === 'importing') {
      status = 'importing'
      thumbnail = (
        <span>
          { Texts.fileManager.importing }
        </span>
      )
    } else if (mediaStatus === 'pending_file') {
      status = 'pending'
      if (!this.props.href) {
        anchorProps.href = `/files/${this.props.mediaId}/attach`
        anchorContent = [
          <span key='1'>
            { Texts.fileManager.pending }
          </span>,
          <span key='2'>
            <FaIcon icon='cloud-upload' />
            { ` ${Texts.fileManager.upload}` }
          </span>,
        ]
      }

      thumbnail = (
        <a
          className='fm-thumb-link'
          {...anchorProps}
        >
          { anchorContent }
        </a>
      )
    }

    if (this.state.showOverlay) {
      overlay = (
        <MediaPlayerOverlay
          bottomNav={this.props.overlayBottomNav}
          mediaId={this.props.mediaId}
          mediaName={this.props.mediaName}
          mediaStatus={this.props.mediaStatus}
          mediaThumbnail={this.props.mediaThumbnail}
          mp4Url={this.props.mp4Url}
          onClickAway={this.toggleOverlay}
          status={status}
          thumbnailUrl={this.props.thumbnailUrl}
          userIsOwner={this.props.userIsOwner}
        />
      )
    }

    return (
      <div className='fm-thumb-wrapper'>
        <div className={`fm-thumb ${status}`}>
          { thumbnail }
        </div>

        <CSSTransitionGroup
          transitionName='overlay'
          {...overlayTransitionDurations}
        >
          { overlay }
        </CSSTransitionGroup>
      </div>
    )
  }
}

export default register('MediaThumbnail', MediaThumbnail)
