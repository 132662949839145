// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import AudioToVideoAction from '../flux/actions/AudioToVideoAction.js'
import DropFileArea from './DropFileArea.jsx'
import Texts from '../texts/texts.js'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'BatchUploadArea'

  render () {
    return (
      <section className='batch-upload-area'>
        <DropFileArea
          multiple
          name='batch-upload-file-field'
          onDropFile={AudioToVideoAction.uploadFile}
          placeholder={Texts.a2v.upload.placeholder}
          placeholderSubtext={Texts.a2v.upload.placeholderSubtext}
        />
      </section>
    )
  }
}

// - -------------------------------------------------------------------- - //
