import $ from 'jquery'
import Radioer from '../components/Radioer'
import SelectField from '../components/SelectField'
import ready from '../utils/ready'

const handleGeoRestrictions = function (geoRestrictionFields) {
  if (geoRestrictionFields.length > 0) {
    const countryPicker = geoRestrictionFields.find('.country-picker')

    const geoRestrictionTypeRadioer = new Radioer({
      inputName: geoRestrictionFields.find('input:radio:first').attr('name'),
    })

    const toggleGeoRestrictions = function (duration) {
      const nullValue = ''

      const val = geoRestrictionTypeRadioer.val()

      if (!val && val.toString() === nullValue) {
        countryPicker.stop(true, false).slideUp(duration)
      } else {
        countryPicker.stop(true, false).slideDown(duration)
      }
    }

    geoRestrictionTypeRadioer.on('change', () => {
      toggleGeoRestrictions()
    })
    toggleGeoRestrictions(0)
  }
}

ready(() => {
  const $geoRestrictionsFields = $('.geo-restrictions-fields')
  const $musicVideoGeoRestrictionsFields = $('.music-video-rights-geo-restrictions-fields')
  const $soundRecordingGeoRestrictionsFields = $('.sound-recording-rights-geo-restrictions-fields')
  const $compositionGeoRestrictionsFields = $('.composition-rights-geo-restrictions-fields')
  const $webGeoRestrictionsFields = $('.web-rights-geo-restrictions-fields')

  /* eslint-disable no-new */
  new SelectField($('#media_publisher'), {
    other_prompt: 'Enter the Publisher name:',
  })

  new SelectField($('#media_rights_society'), {
    other_prompt: 'Enter the Rights Society name:',
  })
  /* eslint-enable no-new */

  handleGeoRestrictions($geoRestrictionsFields)
  handleGeoRestrictions($musicVideoGeoRestrictionsFields)
  handleGeoRestrictions($soundRecordingGeoRestrictionsFields)
  handleGeoRestrictions($compositionGeoRestrictionsFields)
  handleGeoRestrictions($webGeoRestrictionsFields)

  $('#sound_recording_rights_same_as_above').change((event) => {
    if (event.target.checked) {
      $('#sound_recording_rights-geo-modifiers').hide(500)
    } else {
      $('#sound_recording_rights-geo-modifiers').css('padding-left', '20px')
      $('#sound_recording_rights-geo-modifiers').show(500)
    }
  })

  $('#composition_rights_same_as_above').change((event) => {
    if (event.target.checked) {
      $('#composition_rights-geo-modifiers').hide(500)
    } else {
      $('#composition_rights-geo-modifiers').css('padding-left', '20px')
      $('#composition_rights-geo-modifiers').show(500)
    }
  })
})
