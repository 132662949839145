// - -------------------------------------------------------------------- - //

import { Component } from 'react'
import PropTypes from 'prop-types'

// - -------------------------------------------------------------------- - //

const LANDSCAPE_VIDEO_HEIGHT = 320
const LANDSCAPE_VIDEO_WIDTH = 600
const PORTRAIT_VIDEO_HEIGHT = 640
const PORTRAIT_VIDEO_WIDTH = 360

// - -------------------------------------------------------------------- - //

const styles = {

  container: {
    display: 'block',
    position: 'relative',
    height: 0,
    padding: 0,
    overflow: 'hidden',
    paddingBottom: `${9 / 16 * 100}%`,
  },

  iframe: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },

}

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'FacebookEmbed'

  static propTypes = {
    pageId: PropTypes.number.isRequired,
    videoId: PropTypes.number.isRequired,
    videoWidth: PropTypes.number.isRequired,
    videoHeight: PropTypes.number.isRequired,
  }

  videoIsLandscape = () => this.props.videoHeight < this.props.videoWidth

  fixHeight = () => {
    if (this.videoIsLandscape()) {
      return LANDSCAPE_VIDEO_HEIGHT
    }
    return PORTRAIT_VIDEO_HEIGHT
  }

  fixWidth = () => {
    if (this.videoIsLandscape()) {
      return LANDSCAPE_VIDEO_WIDTH
    }
    return PORTRAIT_VIDEO_WIDTH
  }

  render () {
    return (
      <div style={this.videoIsLandscape() ? styles.container : {}}>
        <iframe
          allowFullScreen
          frameBorder='0'
          height={this.fixHeight()}
          scrolling={false}
          src={`https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F${this.props.pageId}%2Fvideos%2F${this.props.videoId}%2F&show_text=0&width=auto`}
          style={this.videoIsLandscape() ? styles.iframe : {}}
          title={`facebook-embed-${this.props.pageId}-${this.props.videoId}`}
          width={this.fixWidth()}
        />
      </div>
    )
  }
}
