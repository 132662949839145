import $ from 'jquery'
import helpers from '../ui/helpers'
import mediaPreview from '../components/mediaPreview.js'
import ready from '../utils/ready'

ready(() => {
  $('.not-encoded').click((e) => {
    e.preventDefault()
    alert('This file has not been encoded yet.')
  })

  mediaPreview($('.video-preview'), {
    formats: ['mp4', 'mov'],
    templateSelector: 'video-preview-template',
    modalSelector: '#video-preview-modal',
    modalOptions: {
      'center': false,
    },
  })

  mediaPreview($('.audio-preview'), {
    formats: ['mp3'],
    templateSelector: 'audio-preview-template',
    modalSelector: '#audio-preview-modal',
  })

  $('video')
    .on('play', function () {
      const $this = $(this)
      // $this.addClass("started");
      $this.addClass('active')
      $this.removeClass('paused')
      if ($this.data('scroll-on-play')) {
        helpers.scroll_to($this, 800)
      }
    })
    .on('pause', function () {
      const $this = $(this)
      $this.removeClass('active')
      $this.addClass('paused')
    })
})
