import $ from 'jquery'
import Delegator from '../utils/Delegator'
import Demplate from '../utils/demplate/Demplate'
import throttler from '../utils/throttler'
let $modalWrapper
let currentModal = null
const $window = $(window)

// options: {
//   center: boolean
//   closeable: boolean
//   renderIntoContainer: Boolean
//   templateSelector: DOM selector
//   templateData: Object - TODO
//   demplateModel: Model
// }
const Modal = function (selector, options) {
  const self = this

  self.options = options || {};
  (typeof self.options.center === 'undefined') && (self.options.center = true);
  (typeof self.options.closeable === 'undefined') && (self.options.closeable = true)

  self.$el = $(selector)
  self.$container = self.$el.find('.container:first')
  self.$renderInto = self.options.renderIntoContainer ? self.$container : self.$el

  self.$el.on('click', '.close', (event) => {
    event.preventDefault()
    if (self.options.closeable) {
      self.close()
    }
  })

  self.setupDemplate()

  self.center = throttler(() => {
    self._center()
  }, 400)

  $modalWrapper = $('#modal-wrapper')
  $modalWrapper.on('click', (event) => {
    if (currentModal && $(event.target).is('#modal-wrapper')) {
      event.preventDefault()
      currentModal.close()
    }
  })
}

Delegator.extendEventDelegation(Modal.prototype, '$el')

Modal.prototype.open = function () {
  const self = this
  currentModal = self
  self.bindListeners()

  self.renderContent()
  self.$el.show()

  $modalWrapper.fadeIn(200, () => {
    self.$el.trigger('modalOpen', [self.$el])
  })

  self.options.center && setTimeout(() => {
    currentModal._center()
  }, 5)
}

Modal.prototype.close = function (force) {
  const self = this
  if (!self.options.closeable && !force) {
    return
  }

  self.unbindListeners()
  currentModal = null

  $modalWrapper.fadeOut(200, () => {
    self.$el.hide()
    self.$el.trigger('modalClose', [self.$el])
  })

  self.clearContent()
}

Modal.prototype.setupDemplate = function () {
  if (!this.options.templateSelector) {
    return
  }

  this.demplate = new Demplate(
    this.$renderInto,
    this.options.templateSelector,
    { model: this.options.demplateModel }
  )
}

Modal.prototype.renderContent = function () {
  if (!this.demplate) {
    return
  }

  this.demplate.render()
}

Modal.prototype.clearContent = function () {
  if (!this.demplate) {
    return
  }

  this.demplate.clear()
}

Modal.prototype.bindListeners = function () {
  const self = this
  $window.on('resize.modal', () => {
    self.options.center && self.center()
  })
}

Modal.prototype.unbindListeners = function () {
  $window.off('resize.modal')
}

// throttled
Modal.prototype._center = function () {
  this.$el.css({
    top: ($modalWrapper.height() - this.$el.outerHeight()) / 2,
  })
  this.$el.trigger('modalCenter', [this.$el])
}

export default Modal
