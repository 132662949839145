// TODO: This should not be under /files assets directory.

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import AudioToVideo from '../components/a2v/AudioToVideo.jsx'
import Notices from '../components/notices/Notices.jsx'
import ReactMounter from '../utils/ReactMounter'
import ready from '../utils/ready'

ready(() => {
  // We have to create a new history every page navigation because of turbolinks.
  // You see, turbolinks makes calls to the window.history directly, and react-router
  // history has no way of knowing about it. If you don't create a new history object
  // every time, the history object will contain cached data that makes react-router
  // believe that it is still on the previous page.
  const container = document.getElementById('react-router')

  if (container) {
    // TODO: Do for dashboard_2.js
    ReactMounter.mount((
      <Router>
        <Routes>
          <Route
            element={<AudioToVideo />}
            name='A2V'
            path='/files/a2v'
          />
        </Routes>
      </Router>
    ), container)
  }

  const noticesContainer = document.getElementById('react-notices')

  if (noticesContainer) {
    ReactMounter.mount(<Notices />, noticesContainer)
  }
})
