import { createRoot } from 'react-dom/client'
import Navigation from 'd2/utils/Navigation'

const ReactMounter = {

  mount (component, element) {
    const root = createRoot(element)

    const mount = function () {
      Navigation.onPageNavigation(unmount)
      root.render(component)
    }

    const unmount = function () {
      if (element) {
        root.unmount()
      }

      Navigation.offPageNavigation(unmount)
    }

    return mount()
  },

}

export default ReactMounter
