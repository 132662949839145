// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import { register } from 'd2/intermediate/registry'
import { visit } from 'd2/utils/Navigation'
import $ from 'jquery'
import Container from './Container.jsx'
import Overlay from './Overlay.jsx'
import cx from 'classnames'

// - -------------------------------------------------------------------- - //

const buttonClasses = cx({
  'btn': true,
  'btn-primary': true,
})

const style = {
  h1: {
    'fontSize': '2em',
    'textAlign': 'center',
    'margin': '30px auto 10px auto',
  },
  p: {
    'padding': '25px',
    'marginBottom': '20px',
  },
}

class YouTubeSyncMessageOverlay extends Component {
  onAccept = (e) => {
    const path = `/artists/${this.props.artistId}/youtube_social_sync`
    $.form(path, {
      authenticity_token: $('meta[name=csrf-token]').attr('content'),
      artist_id: this.props.artistId,
      youtube_channel_id: this.props.youtubeChannelId,
      success_path: this.props.successPath,
      error_path: this.props.errorPath,
    }, 'POST').submit()
  }

  onDecline = (e) => {
    const url = this.props.successPath
    visit(url)
  }

  render () {
    return (
      <Overlay>
        <Container className='container-xs'>
          <h1 style={style.h1}>
            Enable Social Sync for YouTube
          </h1>
          <p style={style.p}>
            Would you like to enable Social Sync for YouTube? With
            Social Sync for YouTube enabled, an artist can update their YouTube
            channel with video uploads while simultaneously adding the new content to
            their Vydia Video Manager.
          </p>
          <nav className='secondary-nav'>
            <ul>
              <li>
                <a
                  className={buttonClasses}
                  onClick={this.onAccept}
                >
                  YES & CONTINUE
                </a>
              </li>
              <li>
                <a onClick={this.onDecline}>
                  No & Continue
                </a>
              </li>
            </ul>
            <div className='clear' />
          </nav>
        </Container>
      </Overlay>
    )
  }
}

export default register('YouTubeSyncMessageOverlay', YouTubeSyncMessageOverlay)
// - -------------------------------------------------------------------- - //
