import $ from 'jquery'

//
// Use internal $.serializeArray to get list of form elements which is
// consistent with $.serialize
//
// From version 2.0.0, $.serializeObject will stop converting [name] values
// to camelCase format. This is *consistent* with other serialize methods:
//
//   - $.serialize
//   - $.serializeArray
//
// If you require camel casing, you can either download version 1.0.4 or map
// them yourself.
//

$.fn.serializeObject = function () {
  'use strict'

  const result = {}
  const extend = function (i, element) {
    const node = result[element.name]

    // If node with same name exists already, need to convert it to an array as it
    // is a multi-value field (i.e., checkboxes)

    if (typeof node !== 'undefined' && node !== null) {
      if ($.isArray(node)) {
        node.push(element.value)
      } else {
        result[element.name] = [node, element.value]
      }
    } else {
      result[element.name] = element.value
    }
  }

  $.each(this.serializeArray(), extend)
  return result
}
