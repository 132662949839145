// - -------------------------------------------------------------------- - //

'use strict'

import Flux from '../flux.js'

// - -------------------------------------------------------------------- - //

let _Notices = []
let _NoticesId = 0

// - -------------------------------------------------------------------- - //

// payload.actionType -> callback
const actionHandlers = {

  NOTICES_ADD_NOTICE (payload) {
    _Notices.push({
      id: ++_NoticesId,
      type: payload.type,
      text: payload.text,
    })
  },

  NOTICES_REMOVE_NOTICE (payload) {
    _Notices = _Notices.filter((notice) => notice.id !== payload.id)
  },

  NOTICES_CLEAR_NOTICES () {
    _Notices = []
  },

}

// - -------------------------------------------------------------------- - //

// exports store state
const storeStateMethods = {

  getNotices () {
    return _Notices
  },

}

// - -------------------------------------------------------------------- - //

export default Flux.createStore(storeStateMethods, actionHandlers)

// - -------------------------------------------------------------------- - //
