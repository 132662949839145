// - -------------------------------------------------------------------- - //

'use strict'

import { extend, isArray } from 'lodash-es'
import $ from 'jquery'
import ArtistCollection from '../../collections/ArtistCollection.js'
import ArtistModel from '../../models/ArtistModel.js'

// mixins should be exported as a function returning the actual mixins
export default function (options) {
// - -------------------------------------------------------------------- - //

  let _ArtistCollection
  let _SelectedArtist
  let _NewArtistError
  let _isLoading
  let _isSaving

  const resetCallback = function (mount) {
    _ArtistCollection = new ArtistCollection()
    _SelectedArtist = undefined
    _NewArtistError = undefined
    _isLoading = false
    _isSaving = false
    if (mount) {
      loadArtists(this)
    }
  }

  function loadArtists (store) {
    _isLoading = true
    $.ajax({
      url: '/api/a2v/artists',
      type: 'GET',
    })
      .fail((data) => {
        _NewArtistError = data && data.responseJSON && data.responseJSON.errors ? data.responseJSON.errors : {}
      })
      .then((data) => {
        if (isArray(data.artists)) {
          data.artists.forEach((artist) => {
            const properties = ArtistModel.pickProperties(artist)
            const model = new ArtistModel(properties)
            _ArtistCollection.add(model)
          })
        }
      })
      .always(() => {
        _isLoading = false
        store.emitChange()
      })
  }

  // - -------------------------------------------------------------------- - //

  const stateMethods = {

    getNewArtistError () {
      return _NewArtistError
    },

    getArtists () {
      return _ArtistCollection
    },

    getSelectedArtist () {
      return _SelectedArtist
    },

    isLoadingArtists () {
      return _isLoading
    },

    isSavingArtist () {
      return _isSaving
    },

  }

  // - -------------------------------------------------------------------- - //

  const actionHandler = {

    ARTIST_CREATE () {
      _NewArtistError = undefined
      _SelectedArtist = undefined
    },

    ARTIST_SELECT (payload) {
      _NewArtistError = undefined
      _SelectedArtist = _ArtistCollection.getArtistById(payload.id)
    },

    ARTIST_ADD () {
      _isSaving = true
    },

    ARTIST_ADD_SUCCESS (payload) {
      const properties = ArtistModel.pickProperties(payload.artist)
      const artist = new ArtistModel(properties)
      _SelectedArtist = artist
      _ArtistCollection.add(artist)
      _isSaving = false
    },

    ARTIST_ADD_ERROR (payload) {
      _NewArtistError = payload.errors
      _isSaving = false
    },

  }

  // - -------------------------------------------------------------------- - //

  return extend({
    stateMethods,
    actionHandler,
    resetCallback,
  }, options)
}
// - -------------------------------------------------------------------- - //
