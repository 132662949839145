// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import AudioToVideoAction from '../flux/actions/AudioToVideoAction.js'
import BackgroundChoose from './BackgroundChoose.jsx'
import BackgroundColor from './BackgroundColor.jsx'
import BackgroundImageModel from '../flux/models/BackgroundImageModel.js'
import BackgroundUpload from './BackgroundUpload.jsx'
import PropTypes from 'prop-types'
import Texts from '../texts/texts.js'
import classSet from 'classnames'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'BackgroundSettings'

  static propTypes = {
    onAccept: PropTypes.func,
    backgroundType: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
    backgroundImage: PropTypes.instanceOf(BackgroundImageModel).isRequired,
    backgroundTemplate: PropTypes.string,
  }

  selectColor = () => {
    AudioToVideoAction.setBackgroundType('color')
  }

  selectUpload = () => {
    AudioToVideoAction.setBackgroundType('upload')
  }

  selectChoose = () => {
    AudioToVideoAction.setBackgroundType('choose')
  }

  render () {
    const type = this.props.backgroundType
    const color = this.props.backgroundColor
    const image = this.props.backgroundImage
    const template = this.props.backgroundTemplate
    let content

    if (type === 'color') {
      content = (
        <BackgroundColor
          backgroundColor={color}
          onAccept={this.props.onAccept}
          onCancel={this.selectChoose}
        />
      )
    } else if (type === 'upload') {
      content = <BackgroundUpload backgroundImage={image} />
    } else if (type === 'choose') {
      content = <BackgroundChoose backgroundTemplate={template} />
    }

    return (
      <div className='background-settings'>
        <nav>
          <ul className='widget-tabs widget-tabs-3 widget-tabs-primary'>
            <li className={classSet({ active: type === 'choose' })}>
              <a onClick={this.selectChoose}>
                { Texts.a2v.background.choose }
              </a>
            </li>
            <li className={classSet({ active: type === 'upload' })}>
              <a onClick={this.selectUpload}>
                { Texts.a2v.background.upload }
              </a>
            </li>
            <li className={classSet({ active: type === 'color' })}>
              <a onClick={this.selectColor}>
                { Texts.a2v.background.color }
              </a>
            </li>
          </ul>
        </nav>
        { content }
      </div>
    )
  }
}

// - -------------------------------------------------------------------- - //
