import { Component, createElement } from 'react'
import PropTypes from 'prop-types'
import Tooltip from './shared/Tooltip.jsx'
import cx from 'classnames'

class Icon extends Component {
  static propTypes = {
    tagName: PropTypes.string, // Specify the type of HTML tag to use.
    icon: PropTypes.string.isRequired,
    spin: PropTypes.bool,
    pulse: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    tip: PropTypes.string,
    tipPlace: PropTypes.string,
    library: PropTypes.string.isRequired,
  }

  static defaultProps = {
    tagName: 'span',
  }

  render () {
    let classes
    if (this.props.library.startsWith('fa')) {
      classes = {
        [this.props.library]: true,
        'fa-spin': this.props.spin,
        'fa-pulse': this.props.pulse,
      }
    } else {
      classes = {
        'va': true,
        'va-spin': this.props.spin,
        'va-pulse': this.props.pulse,
      }
    }

    if (this.props.className) {
      classes[cx(this.props.className)] = true
    }

    const prefix = this.props.library.startsWith('fa') ? 'fa' : this.props.library

    classes[`${prefix}-${this.props.icon}`] = true

    const props = {
      className: cx(classes),
      onClick: this.props.onClick,
    }

    const font = createElement(this.props.tagName, props)

    if (this.props.tip) {
      return (
        <Tooltip
          placement={this.props.tipPlace || 'top'}
          title={this.props.tip}
        >
          { font }
        </Tooltip>
      )
    }

    return font
  }
}

export default Icon
