/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */

import './jquery.serializeObject.js'
import './pageTitle.js'
import './delegation.js'
import './forms.js'
import './tooltips.js'
import './tables.js'
import './modals.js'
import './merge_tags.js'
import './advanced_rights.js'

window.jQuery = window.$ = require('jquery')
