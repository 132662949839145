// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import { s3Url } from 'd2/utils/Routes'
import BackgroundImageModel from '../flux/models/BackgroundImageModel.js'
import ImagePreview from './ImagePreview.jsx'
import PropTypes from 'prop-types'
import classSet from 'classnames'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'BackgroundPreview'

  static propTypes = {
    backgroundColor: PropTypes.string,
    backgroundImage: PropTypes.instanceOf(BackgroundImageModel).isRequired,
    backgroundTemplate: PropTypes.string,
  }

  render () {
    const backgroundColor = this.props.backgroundColor
    const backgroundImage = this.props.backgroundImage
    const backgroundTemplate = this.props.backgroundTemplate
    const hasColor = /^#[A-F0-9]{3,6}$/i.test(backgroundColor)

    const classes = classSet({
      'background-preview': true,
      'no-color': !hasColor,
      'no-image': !backgroundImage,
    })

    let image
    if (backgroundImage && backgroundImage.hasValue('file')) {
      image = <ImagePreview file={backgroundImage.getValue('file')} />
    } else if (backgroundTemplate) {
      image = <ImagePreview url={s3Url(backgroundTemplate)} />
    }

    const color = {}
    if (hasColor) {
      color.backgroundColor = backgroundColor
    }

    return (
      <div
        className={classes}
        style={color}
      >
        { image }
      </div>
    )
  }
}

// - -------------------------------------------------------------------- - //
