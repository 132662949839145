// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import ArtistCollection from '../flux/collections/ArtistCollection.js'
import ArtistModel from '../flux/models/ArtistModel.js'
import AudioToVideoAction from '../flux/actions/AudioToVideoAction.js'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import Texts from '../texts/texts.js'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'ArtistPicker'

  static propTypes = {
    artists: PropTypes.instanceOf(ArtistCollection),
    selectedArtist: PropTypes.instanceOf(ArtistModel),
  }

  constructor (props) {
    super(props)
    const artist = props.selectedArtist

    this.state = {
      value: artist ? artist.getValue('id') : undefined,
    }
  }

  componentWillReceiveProps (newProps) {
    this.setState({
      value: newProps.selectedArtist ? newProps.selectedArtist.getValue('id') : undefined,
    })
  }

  handleChange = () => {
    const value = ReactDOM.findDOMNode(this.refs.select).value
    if (value === 'CREATE') {
      AudioToVideoAction.newArtist()
    } else if (value === 'CHOOSE') {
      AudioToVideoAction.selectArtist(undefined)
    } else {
      AudioToVideoAction.selectArtist(value)
    }
    this.setState({ value })
  }

  renderOption = (artist, index) => (
    <option
      key={index}
      value={artist.getValue('id')}
    >
      { artist.getValue('name') }
    </option>
  )

  render () {
    return (
      <div className='artist-picker'>
        <label htmlFor='artist-picker-select'>
          { Texts.a2v.artist.label }
        </label>
        <select
          id='artist-picker-select'
          onChange={this.handleChange}
          ref='select'
          value={this.state.value}
        >
          <option value='CHOOSE'>
            { Texts.a2v.artist.existing }
          </option>
          <option value='CREATE'>
            { Texts.a2v.artist.create }
          </option>
          { this.props.artists ? this.props.artists.map(this.renderOption) : null }
        </select>
      </div>
    )
  }
}

// - -------------------------------------------------------------------- - //
