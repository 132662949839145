import $ from 'jquery'
import Delegator from '../Delegator'
import Model from './Model'
import template from '../template'

// TODO: make templateID an optional option rather than an argument.
//
// Demplate: Data-Dependent Template
// @param el [Selector] - the element to render the template into.
// @param templateID [String] - the ID of the script element with the template data.
// @param options [Object] - key/value object of options.
//   @option data [Object] - the data to pass to the model.
//   @option model [Model] - the demplate Model.
//   @option renderer [Function] - how the template is rendered.
const Demplate = function (el, templateID, options) {
  options = options || {}

  this.$el = $(el)
  this.templateID = templateID

  this.options = options

  this.toRender(options.renderer || this.defaultRenderer)

  this.model = options.model || new Model(options.data || {})

  this.currentContent = undefined

  this.bindings = []

  this.bindListeners()
}

Demplate.Model = Model

// Listen for key events.
// Events:
//   render   - triggered after new content is rendered.
//   teardown - triggered before render if any rendered content already existed.
Delegator.delegate(Demplate.prototype, 'on', '$el')

// Set the value of a key from the model and trigger a render.
Delegator.delegate(Demplate.prototype, 'set', 'model')

// Get the value of a key from the model.
Delegator.delegate(Demplate.prototype, 'get', 'model')

// Register an event which will be bound to matching elements after content is rendered.
// Events will be automatically unbound on teardown to avoid rogue listeners.
//
// @param selector [String]
// @param eventName [String]
// @param fn [callback]
Demplate.prototype.bind = function (selector, eventName, fn) {
  this.bindings.push([selector, eventName, fn])
}

Demplate.prototype.render = function () {
  const content = this.renderer(this.model)
  this.setContent(content)
}

// How to render the content?
Demplate.prototype.toRender = function (fn) {
  this.renderer = fn
  return fn
}

Demplate.prototype.defaultRenderer = function (model) {
  return template(this.templateID, model.data)
}

Demplate.prototype.clear = function () {
  this.setContent('')
}

Demplate.prototype.setContent = function (content) {
  if (this.currentContent) {
    this._onTeardown()
    this.$el.trigger('teardown', [this.$el])
  }

  this.currentContent = content

  this.$el.html(content)
  this._onRender()
  this.$el.trigger('render', [this.$el])
}

// For each binding, setup a listener.
Demplate.prototype._onRender = function () {
  const self = this
  let $el
  let eventName
  let fn
  $.each(self.bindings, (_, data) => {
    $el = self.$el.find(data[0])
    eventName = data[1]
    fn = data[2]
    $el.on(eventName, fn)
  })
}

// Remove each binding listener.
Demplate.prototype._onTeardown = function () {
  const self = this
  let $el
  let eventName
  let fn
  $.each(self.bindings, (_, data) => {
    $el = self.$el.find(data[0])
    eventName = data[1]
    fn = data[2]
    $el.off(eventName, fn)
  })
}

Demplate.prototype.bindListeners = function () {
  const self = this
  self.model.on('change', () => {
    self.render()
  })
}

export default Demplate
