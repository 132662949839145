// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import AudioToVideoAction from '../flux/actions/AudioToVideoAction.js'
import BackgroundImageModel from '../flux/models/BackgroundImageModel.js'
import FaIcon from '../FaIcon.jsx'
import ProgressBar from '../ProgressBar.jsx'
import PropTypes from 'prop-types'
import Texts from '../texts/texts.js'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'BackgroundUploadFile'

  static propTypes = {
    backgroundImage: PropTypes.instanceOf(BackgroundImageModel).isRequired,
  }

  handleRemove = () => {
    AudioToVideoAction.removeBackgroundImage(this.props.backgroundImage)
  }

  render () {
    const file = this.props.backgroundImage.getValue('file')
    const errors = file.getErrors()

    let progress
    if (errors && errors[0] === 'dimensions') {
      progress = (
        <span className='error'>
          { Texts.a2v.global.dimensions }
        </span>
      )
    } else if (errors && errors[0] === 'extension') {
      progress = (
        <span className='error'>
          { Texts.a2v.global.extension }
        </span>
      )
    } else if (errors && !errors[0]) {
      progress = (
        <span className='error'>
          { Texts.a2v.global.error }
        </span>
      )
    } else {
      progress = (
        <ProgressBar
          active={!file.isComplete()}
          isFloat={false}
          progress={file.getProgress()}
          striped={!file.isComplete()}
        />
      )
    }

    return (
      <div className='background-upload-file'>
        <div className='background-upload-filename'>
          { file.getValue('filename') }
          <FaIcon
            icon='trash-alt'
            onClick={this.handleRemove}
          />
        </div>
        <div className='background-upload-progress'>
          { progress }
        </div>
      </div>
    )
  }
}

// - -------------------------------------------------------------------- - //
