// - -------------------------------------------------------------------- - //

'use strict'

import { find } from 'lodash-es'
import Collection from './Collection.js'

// - -------------------------------------------------------------------- - //

export default Collection.createCollection({

  getFileByName (name) {
    return find(this, (file) => file.getValue('filename') === name)
  },

  getFileByUploadId (id) {
    return find(this, (file) => {
      const upload = file.getValue('file_upload')
      return upload && `${upload.id}` === `${id}`
    })
  },

})

// - -------------------------------------------------------------------- - //
