import { Component } from 'react'
import Icon from './Icon.jsx'

class FaIcon extends Component {
  static propTypes = Icon.propTypes

  static defaultProps = {
    library: 'fa',
  }

  render () {
    return (
      <Icon {...this.props} />
    )
  }
}

export default FaIcon
