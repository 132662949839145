import { Component } from 'react'
import { DateTime } from 'luxon'
import { register } from 'd2/intermediate/registry'
import PropTypes from 'prop-types'

class UserLocalTime extends Component {
  static propTypes = {
    datetime: PropTypes.instanceOf(Date).isRequired,
    dateOnly: PropTypes.bool,
    showTime: PropTypes.bool,
    noSeconds: PropTypes.bool,
  }

  sameDay = () => DateTime.local().toJSDate()
    .toLocaleDateString() === this.props.datetime.toLocaleDateString()

  sameYear = () => DateTime.local().toJSDate()
    .toLocaleDateString('en-US', { year: 'numeric' }) === this.props.datetime.toLocaleDateString('en-US', { year: 'numeric' })

  // MM/DD/YYYY
  properDate = () => this.props.datetime.toLocaleDateString()

  // Month DD
  informalDate = () => this.props.datetime.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })

  // HH:MM:SS AM/PM
  time = () => {
    let timeString = this.props.datetime.toLocaleTimeString()

    if (this.props.noSeconds) {
      timeString = timeString.replace(/:\d{2}\s/, ' ')
    }

    return timeString
  }

  render () {
    let readable

    if (this.props.dateOnly) {
      // pretty_time_as_date
      if (this.sameDay()) {
        readable = 'Today'
      } else {
        readable = this.properDate()
      }
    } else {
      // pretty_time
      if (this.sameDay()) {
        readable = this.time()
      } else if (this.sameYear()) {
        readable = this.informalDate()

        if (this.props.showTime) {
          readable += ` at ${this.time()}`
        }
      } else {
        readable = this.properDate()

        if (this.props.showTime) {
          readable += ` at ${this.time()}`
        }
      }
    }
    return (<time dateTime={this.props.datetime.toISOString()}>
      { readable }
    </time>)
  }
}

export default register('UserLocalTime', UserLocalTime)
