import { Component } from 'react'
import { defer } from 'lodash-es'
import $ from 'jquery'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

const setFontSize = function (el, fontSize) {
  el.style.fontSize = `${fontSize}px`
}

// TODO: make npm package for this
class AutoFitText extends Component {
  static propTypes = {
    fontFamily: PropTypes.string, // Enforce font-family for proper sizing.
    minFontSize: PropTypes.number,
    maxFontSize: PropTypes.number,
    verticalCenter: PropTypes.bool,
  }

  static defaultProps = {
    minFontSize: 1,
    maxFontSize: 300,
    verticalCenter: false,
  }

  componentDidMount () {
    $(window).on('resize', this._calculateSize)
    defer(this._enforceStyles)
    defer(this._calculateSize)
  }

  componentWillUnmount () {
    $(window).off('resize', this._calculateSize)
  }

  componentDidUpdate (prevProps, prevState) {
    defer(this._enforceStyles)
    defer(this._calculateSize)
  }

  _enforceStyles = () => {
    const el = ReactDOM.findDOMNode(this)

    if (this.props.fontFamily) {
      el.style.fontFamily = this.props.fontFamily
    }

    el.style.display = 'inline'
    el.style.width = 'auto'
    el.style.whiteSpace = 'nowrap'
  }

  _centerVertically = (fontSize) => {
    const el = ReactDOM.findDOMNode(this)

    el.style.position = 'absolute'
    el.style.top = '50%'
    el.style.marginTop = `-${fontSize / 2}px`
  }

  // TODO: Maybe throttle this?
  _calculateSize = () => {
    const el = ReactDOM.findDOMNode(this)
    const $el = $(el)
    const parentWidth = $el.parent().innerWidth()

    setFontSize(el, this.props.minFontSize)
    const widthAtMin = $el.outerWidth()

    setFontSize(el, this.props.maxFontSize)
    const widthAtMax = $el.outerWidth()

    const computedSize
      = (parentWidth - widthAtMin) * (this.props.maxFontSize - this.props.minFontSize)
      / (widthAtMax - widthAtMin) + this.props.minFontSize

    setFontSize(el, computedSize)

    if (this.props.verticalCenter) {
      this._centerVertically(computedSize)
    }
  }

  render () {
    return this.props.children
  }
}

export default AutoFitText
