import { readableMS } from 'd2/utils/StringHelpers'
import $ from 'jquery'
import Demplate from '../utils/demplate/Demplate'
import Errors from '../utils/Errors'
import FileUploadForm from '../components/FileUploadForm'
import Model from '../utils/demplate/Model'
import capper from '../utils/capper'
import ready from '../utils/ready'
import template from '../utils/template'
let model
let feedback
let fileUpload
let messageUploads
const kib = 1024
const mib = kib * kib
const defaultUploadSizeLimit = 50 * mib
let init
let setupDemplate
let onModelChange
let uploadStart
let uploadProgress
let uploadFinish
let uploadError
let appendErrors
let removeErrors
let clearFileInput
let setIsDragging

ready(() => {
  const $formContainer = $('#release-message-form-container')
  const $messageContent = $formContainer.find('#message_content')
  const $uploadFormField = $messageContent.closest('.form-field')
  const $uploadForm = $formContainer.find('#release-message-form')
  const $uploadField = $formContainer.find('input[type=file]')
  const $uploadLink = $formContainer.find('a[href="#upload"]')
  const $uploadInput = $formContainer.find('#message-attachments')
  const $uploadList = $formContainer.find('#message-uploads')
  const $formNote = $formContainer.find('p.form-note')
  const uploadSizeLimit = $uploadInput.data('sizeLimit') || defaultUploadSizeLimit

  if ($uploadForm.length <= 0) return

  setIsDragging = function (isDragging, event) {
    event.preventDefault()

    $uploadFormField.toggleClass('drag-over', isDragging)

    return false
  }

  $uploadLink.on('click', (e) => {
    e.preventDefault()
    $uploadInput.click()
  })

  $uploadList.on('click', 'a.delete', function (e) {
    e.preventDefault()

    if (!window.confirm('Are you sure you want to remove this file?')) {
      return
    }

    const id = $(this).data('id')
    const uploads = model.get('uploads')
    delete uploads[id]
    model.set('uploads', uploads)

    // Nothing to do on response, whether success or fail
    $.post(e.target.href, {
      _method: 'DELETE',
      authenticity_token: $('meta[name=csrf-token]').attr('content'),
    })
  })

  $messageContent
    .on('dragover dragenter', setIsDragging.bind(null, true))
    .on('dragleave dragend drop', setIsDragging.bind(null, false))
    .on('drop', (e) => {
      $uploadInput.prop('files', e.originalEvent.dataTransfer.files)
    })

  init = function () {
    setupDemplate()

    fileUpload = new FileUploadForm($uploadForm, {
      maxBytes: uploadSizeLimit,
      serverless: {
        key: '', // Key is only used to associate with a media that shares the same upload_key value.
        type: 'messageAttachment',
      },
    })

    fileUpload.$form.on('uploadStart', uploadStart)
    fileUpload.$form.on('uploadProgress', uploadProgress)
    fileUpload.$form.on('uploadFinish', uploadFinish)
    fileUpload.$form.on('uploadError', uploadError)

    window.onbeforeunload = function (event) {
      if (!model.get('uploadStarted')) return

      event && event.preventDefault && event.preventDefault()
      return 'WARNING: You will lose all upload progress!'
    }
  }

  setupDemplate = function () {
    model = new Model({
      uploadStarted: false,
      uploadCompleted: false,
      uploadErrored: false,
      uploadETA: null,
      uploadPercent: null,
      uploads: {},
    })

    model.on('change', onModelChange)

    feedback = new Demplate($('#uploader-feedback'),
      'uploader-feedback-template',
      { model })
    messageUploads = new Demplate($('#message-uploads'),
      'message-uploads-template',
      { model })
    feedback.render()
    messageUploads.render()
  }

  onModelChange = function () {
    $formNote.toggle(model.get('uploadCompleted') || model.get('uploadErrored'))
    $uploadField.prop('enabled', model.get('uploadCompleted'))
    $uploadField.prop('disabled', model.get('uploadStarted'))
  }

  uploadStart = function () {
    model.set({
      uploadStarted: true,
      uploadCompleted: false,
      uploadErrored: false,
      uploadETA: null,
      uploadPercent: null,
    })

    removeErrors()
    clearFileInput()
  }

  uploadProgress = capper((event, percent, remainingMS) => {
    model.set({
      uploadETA: readableMS(remainingMS),
      uploadPercent: percent,
    })
  }, 1500)

  uploadFinish = function (event, file, fileUploadId) {
    const uploads = model.get('uploads')
    uploads[fileUploadId] = file
    model.set({
      uploadStarted: false,
      uploadCompleted: true,
      uploadErrored: false,
      uploadETA: null,
      uploadPercent: null,
      uploads,
    })

    uploadProgress.cancel()
  }

  uploadError = function (event, errors) {
    model.set({
      uploadStarted: false,
      uploadCompleted: false,
      uploadErrored: true,
      uploadETA: null,
      uploadPercent: null,
    })

    clearFileInput()
    uploadProgress.cancel()

    removeErrors()
    appendErrors(errors)
  }

  appendErrors = function (errors) {
    if (errors && Object.keys(errors).length > 0) {
      const readableErrors = Errors.getReadableMessages(errors, {
        includeFieldName: true,
      })
      const errorsMarkup = template('form-field-errors', {
        errors: readableErrors,
      })
      $uploadFormField.append(errorsMarkup)
      alert('Oops! The upload couldn\'t be completed. Please verify the file and try again.')
    }
  }

  removeErrors = function () {
    $uploadFormField.find('.input-errors').remove()
  }

  clearFileInput = function () {
    $uploadField.wrap('<form>').closest('form')
      .get(0)
      .reset()
    $uploadField.unwrap()
  }

  init()
})
