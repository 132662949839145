import { createElement } from 'react'
import { isArrayLikeObject, isPlainObject, reduce } from 'lodash-es'
import { lookup } from 'd2/intermediate/registry'
import $ from 'jquery'
import ReactMounter from '../../utils/ReactMounter'
import ready from '../../utils/ready'
const iso8601 = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})([+-](\d{2}):?(\d{2})?|Z)$/

const coerceValue = function (value) {
  if (isPlainObject(value)) {
    return coerceObject(value)
  } else if (isArrayLikeObject(value)) {
    return value.map(coerceValue)
  } else if (iso8601.test(value)) {
    return new Date(value)
  }

  return value
}

const coerceObject = function (props) {
  return reduce(props, (props, value, key) => {
    props[key] = coerceValue(value)

    return props
  }, {})
}

ready(() => {
  $('.js-react-component').each(function () {
    const $wrapper = $(this)
    const componentName = $wrapper.data('component-name')
    let props = $wrapper.data('props')

    props = coerceObject(props)

    const componentClass = lookup(componentName)
    const component = createElement(componentClass, props)

    ReactMounter.mount(component, this)
  })
})
