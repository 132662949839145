// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import { s3Url } from 'd2/utils/Routes'
import { sample } from 'lodash-es'
import $ from 'jquery'
import AudioToVideoAction from '../flux/actions/AudioToVideoAction.js'
import FaIcon from '../FaIcon.jsx'
import PropTypes from 'prop-types'
import ScrollView from '../ScrollView.jsx'
import classSet from 'classnames'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'BackgroundChoose'

  static propTypes = {
    backgroundTemplate: PropTypes.string,
    templates: PropTypes.array,
  }

  static defaultProps = (function () {
    const s3Path = 'assets/backgrounds/'
    const templateHost = s3Url(s3Path)
    const templatesListNames = [
      'aqua-grunge', 'blood-grunge', 'buildings',
      'coffee', 'congruent-snow', 'dark-grunge',
      'desert', 'dirt-grunge', 'ferris',
      'fog', 'moon-surface', 'mountains',
      'ocean', 'organic-oranges', 'pink-room',
      'speckled', 'stars', 'tropical',
      'tunnel', 'urban',
    ]
    const templatesListEntries = $.map(templatesListNames, (item) => ({
      thumb: `${templateHost + item}-thumb.jpg`,
      full: `${s3Path + item}.jpg`,
    }))

    return {
      templates: templatesListEntries,
    }
  }())

  handleClick = (template) => {
    AudioToVideoAction.setBackgroundTemplate(template.full)
  }

  renderTemplate = (template, index) => {
    const selected = template.full === this.props.backgroundTemplate
    const classes = classSet({
      'background-template': true,
      selected,
    })
    const styles = {
      backgroundImage: `url("${template.thumb}")`,
    }

    return (
      <div
        className={classes}
        key={index}
        onClick={() => this.handleClick(template)}
      >
        <div
          className='background-template-image'
          style={styles}
        >
          { selected ? <FaIcon icon='check' /> : null }
        </div>
      </div>
    )
  }

  componentWillMount () {
    AudioToVideoAction.setBackgroundTemplate(sample(this.props.templates).full)
  }

  render () {
    return (
      <ScrollView className='background-settings-choose'>
        <div className='background-templates'>
          { this.props.templates.map(this.renderTemplate) }
        </div>
        <div className='clear' />
      </ScrollView>
    )
  }
}

// - -------------------------------------------------------------------- - //
