// - -------------------------------------------------------------------- - //

'use strict'

import Model from './Model.js'

// - -------------------------------------------------------------------- - //

export default Model.createModel({

  properties: [
    'id',
    'user_id',
    'lyrics',
    'video_isrc',
    'audio_isrc',
    'genre',
    'updated_at',
    'song_name',
    'album',
    'director',
    'video_producer',
    'label',
    'production_company',
    'copyright_name',
    'copyright_year',
    'composer',
    'composers',
    'featured_artist',
    'has_asserted_full_rights',
    'has_asserted_partial_rights',
    'has_asserted_rights',
    'youtube_policy_type',
    'facebook_policy_type',
    'music_video_rights_details',
    'sound_recording_rights_details',
    'composition_rights_summary',
    'web_rights_details',
    'default_youtube_policy',
    'default_facebook_policy',
  ],

  hasAssertedRights () {
    return this.getValue('has_asserted_full_rights') || this.getValue('has_asserted_partial_rights')
  },

})

// - -------------------------------------------------------------------- - //
