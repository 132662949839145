import $ from 'jquery'

const Filterable = function (options) {
  options = options || {}

  typeof options.moveToTop === 'undefined'
      && (options.moveToTop = false)

  options.showMethod = options.showMethod || 'slide'
  options.filterProp = options.filterProp || 'type'

  this.$filterables = options.$filterables
  this.$tabsContainer = options.$tabsContainer

  this.$tabs = this.$tabsContainer.find('li a')

  this.options = options

  this.setup()
}

Filterable.prototype.show = function ($el) {
  $el.stop(true, false)

  if (this.options.showMethod === 'slide') {
    $el.slideDown()
  } else {
    $el.show()
  }

  $el.addClass('active')

  if (this.options.moveToTop) {
    $el.insertAfter(this.$tabsContainer)
  }
}

Filterable.prototype.hide = function ($el) {
  $el.stop(true, false)

  if (this.options.showMethod === 'slide') {
    $el.slideUp()
  } else {
    $el.hide()
  }

  $el.removeClass('active')
}

Filterable.prototype.clickTab = function ($tab) {
  const $tabContainer = $tab.closest('li')

  if ($tabContainer.hasClass('active')) {
    return false
  }

  this.$tabs.closest('li.active').removeClass('active')
  $tabContainer.addClass('active')

  this.showList($tab.data(this.options.filterProp))
}

Filterable.prototype.showList = function (filterString) {
  const self = this

  this.$filterables.each(function () {
    const $list = $(this)

    if (!filterString || $list.data(self.options.filterProp) === filterString) {
      self.show($list)
    } else {
      self.hide($list)
    }
  })
}

Filterable.prototype.bindListeners = function () {
  const self = this
  self.$tabs.on('click', function (event) {
    event.preventDefault()
    self.clickTab($(this))
    return false
  })
}

Filterable.prototype.setup = function () {
  this.bindListeners()

  this.clickTab(this.$tabs.first())
}

export default Filterable
