// - -------------------------------------------------------------------- - //

'use strict'

import $ from 'jquery'
import Flux from '../../flux.js'

// - -------------------------------------------------------------------- - //

export default function (options) {
  const prefix = Flux.createPrefixer(options)

  return {

    newArtist () {
      return {
        actionType: prefix('ARTIST_CREATE'),
      }
    },

    selectArtist (id) {
      return {
        actionType: prefix('ARTIST_SELECT'),
        id: id || undefined,
      }
    },

    addArtist (artist) {
      $.ajax({
        url: '/api/a2v/artists',
        type: 'POST',
        data: { artist },
      }).failDispatch({
        actionType: prefix('ARTIST_ADD_ERROR'),
      })
        .thenDispatch((data) => ({
          actionType: prefix('ARTIST_ADD_SUCCESS'),
          artist: data,
        }))
      return {
        actionType: prefix('ARTIST_ADD'),
      }
    },

    artistUpdated () {
      return {
        actionType: prefix('ARTIST_UPDATED'),
      }
    },

  }
}

// - -------------------------------------------------------------------- - //
