import $ from 'jquery'
import ready from '../utils/ready'
let clickColumn
let clickOrSubmit

ready(() => {
  const $comparisons = $('.comparison')
  clickColumn = function ($column) {
    let $target = $column.find($column.data('target'))

    if ($target.length === 0) {
      $target = $column.find('form')
    }
    clickOrSubmit($target)
  }

  clickOrSubmit = function ($el) {
    if ($el.is('form')) {
      $el[0].submit()
    } else {
      $el.trigger('click', [true])
    }
  }

  $comparisons.each(function () {
    const $this = $(this)
    const $choices = $this.find('> li')
    const isFullClick = $this.hasClass('full-click-comparison')

    if (isFullClick) {
      $choices.hover(
        function () {
          $(this).toggleClass('hover', true)
        },
        function () {
          $(this).toggleClass('hover', false)
        }
      )

      $choices.find('select').on('click', (event) => {
        event.preventDefault()
        event.stopPropagation()
        return false
      })

      $choices.on('click', function (event, triggered) {
        event.preventDefault()
        if (!triggered) {
          clickColumn($(this))
        }
      })
    }
  })
})
