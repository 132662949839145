import { lazy, memo } from 'react'

export type Props = {
  standalone?: boolean
}

const Default = lazy<React$ComponentType>(
  () => import('d2/partials/StorageTierModalWrapper'),
)

const Standalone = lazy<React$ComponentType>(
  () => import('d2/partials/StorageTierModalWrapper/standalone'),
)

const StorageTierModalWrapper = memo<Props>(({ standalone }) => (
  standalone ? <Standalone /> : <Default />
))

export default StorageTierModalWrapper
