import $ from 'jquery'
import helpers from '../ui/helpers.js'
import ready from '../utils/ready'

ready(() => {
  const showCreateArtistForm = function () {
    const $form = $('#create-artist-form')

    $('#create-artist-link').slideUp()
    $form.slideDown(() => {
      $form.find('.artist-name-field input').focus()
        .select()
    })

    helpers.scroll_to($form)
  }

  $('#create-artist-link').on('click', (event) => {
    event.preventDefault()
    showCreateArtistForm()
  })

  if (location.hash === '#from-picker') {
    showCreateArtistForm()
  }
})
