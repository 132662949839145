// - -------------------------------------------------------------------- - //

'use strict'

import { prettyPrice } from 'd2/utils/StringHelpers'
import { some } from 'lodash-es'
import Model from './Model.js'

// - -------------------------------------------------------------------- - //

export default Model.createModel({

  properties: [
    'id',
    'artist',
    'artist_id',
    'created_at',
    'encoding_since',
    'external_url',
    'category',
    'subcategory',
    'features',
    'file_upload',
    'file_upload_id',
    'first_submitted_at',
    'generator',
    'geo_include',
    'geo_restrictions',
    'has_content_id',
    'has_distribute_release',
    'has_monetize_release',
    'importing_since',
    'is_explicit',
    'song_name_locked',
    'video_isrc_locked',
    'audio_isrc_locked',
    'master_rights',
    'media_artist_name',
    'media_claims',
    'media_owner_user_id',
    'metadata',
    'metadata_complete',
    'metadata_id',
    'no_streaming_reason',
    'revenue',
    'revenue_updated_at',
    'has_positive_client_revenue',
    'royalty_synced_at',
    'royalty_artist_id',
    'status',
    'uid_comp',
    'uid_mv',
    'uid_sr',
    'uid_web',
    'updated_at',
    'upload_key',
    'user_archived',
    'user_id',
    'vevo_channel',
    'youtube_url',
    'thumbnail_path',
    'setup_metadata_editable',
    'setup_rights_editable',
    'setup_policy_editable',
    'ugc',
    'conflicts_total',
    'show_facebook_policy',
    'show_youtube_policy',
    'facebook_policy_options',
    'youtube_policy_options',
    'social_sync_type',
    'sync_released_at',
    'sync_canceled_at',
  ],

  isMetadataEditable () {
    return !!this.getValue('setup_metadata_editable')
  },

  isRightsEditable () {
    return !!this.getValue('setup_rights_editable')
  },

  isPolicyEditable () {
    return !!this.getValue('setup_policy_editable')
  },

  isMusicCategory () {
    return this.getValue('category') === 'music'
  },

  showFacebookPolicy () {
    return !!this.getValue('show_facebook_policy')
  },

  showYoutubePolicy () {
    return !!this.getValue('show_youtube_policy')
  },

  getTitleWithArtistName () {
    return `${this.getSongName()} by ${this.getArtistName()}`
  },

  getSongName () {
    if (this.hasValue('metadata')) {
      const metadata = this.getValue('metadata')
      if (metadata) {
        return metadata.getValue('song_name')
      }
    }
  },

  getArtistName () {
    if (this.hasValue('artist')) {
      const artist = this.getValue('artist')
      if (artist) {
        return artist.getValue('name')
      }
    }
  },

  getRoyaltyArtistId () {
    return this.getValue('royalty_artist_id')
  },

  getFileType () {
    const upload = this.getValue('file_upload')
    if (upload) {
      return upload.getValue('type')
    }
  },

  getMp4 () {
    const upload = this.getValue('file_upload')
    if (upload) {
      return upload.getValue('mp4_path')
    }
  },

  getThumbnail () {
    const upload = this.getValue('file_upload')
    if (upload) {
      return upload.getValue('thumbnail_path')
    }
  },

  getDuration () {
    const upload = this.getValue('file_upload')
    if (upload) {
      return upload.getValue('duration_in_ms')
    }
  },

  getRevenue () {
    return prettyPrice(this.getValue('revenue'))
  },

  getMediaUserId () {
    return this.getValue('media_owner_user_id')
  },

  getMediaArtistName () {
    return this.getValue('media_artist_name')
  },

  getMediaClaims () {
    return this.getValue('media_claims')
  },

  getId () {
    return this.getValue('id')
  },

  getGenerator () {
    return this.getValue('generator') || 'Upload'
  },

  getPositiveClientRevenue () {
    return this.getValue('has_positive_client_revenue')
  },

  getHasMonetizeRelease () {
    return this.getValue('has_monetize_release')
  },

  isLocked (field) {
    return !!this.getValue(`${field}_locked`)
  },

  // TODO: Different progress for web media.
  getProgress () {
    let progress = 0
    const metadata = this.getValue('metadata')
    let metadataFields

    if (this.isMusicCategory()) {
      metadataFields = [
        'song_name',
        'composers',
        'copyright_name',
        'copyright_year',
        'video_isrc',
        'audio_isrc',
        'genre',
        'label',
        'album',
        'has_asserted_rights',
      ]
    } else {
      metadataFields = [
        'song_name',
      ]
    }
    if (this.showFacebookPolicy()) {
      metadataFields.push('facebook_policy_type')
    }

    if (this.showYoutubePolicy()) {
      metadataFields.push('youtube_policy_type')
    }
    if (metadata) {
      const hasAssertedRights = metadata.hasAssertedRights()
      metadata.setValue('has_asserted_rights', hasAssertedRights)
      progress += metadataFields.filter((field) => {
        if (field === 'composers') {
          return some(metadata.getValue(field), (composer) => composer.getValue('name') && /\w/.test(composer.getValue('name')))
        }
        return metadata.getValue(field) && /\w/.test(metadata.getValue(field).toString())
      }).length
    }
    return Math.min(100, 100.0 / metadataFields.length * progress)
  },

  isGoodForContentId () {
    const reasons = this.getContentIdReasons()
    return reasons.length === 0
  },

  getContentIdReasons () {
    const reasons = []
    if (this.getValue('has_content_id')) {
      reasons.push('has_content_id')
    }
    if (!this.getValue('artist')) {
      reasons.push('select_artist')
    }
    if (!this.getValue('file_upload') || !this.getValue('file_upload').hasMedia()) {
      reasons.push('attach_media')
    }
    return reasons
  },

  getGeneratedAttributes () {
    return this._generatedAttributes || (this._generatedAttributes = [])
  },

  appendGeneratedAttribute (attribute) {
    return this.getGeneratedAttributes().push(attribute)
  },

  clearGeneratedAttributes () {
    this._generatedAttributes = []
  },

})

// - -------------------------------------------------------------------- - //
