import { enableLogging } from 'd2/utils/environment'
import { gql } from '@apollo/client'
import { prettyFileSize } from 'd2/utils/StringHelpers'
import { toInteger } from 'lodash-es'
import $ from 'jquery'
import apollo from 'd2/utils/apollo'
import evaporate from 'evaporate'

const oneKb = 1000
const oneMb = oneKb * oneKb
const oneGb = oneMb * oneKb
const defaultMinBytes = 0.5 * oneKb
const defaultMaxBytes = 30 * oneGb
const mediaExtensions = ['wav', 'flac', 'mov', 'mp4', 'mp3']

const FileUploader = function (file, options) {
  (options === undefined) && (options = {})
  this.minBytes = options.minBytes || defaultMinBytes
  this.maxBytes = options.maxBytes || defaultMaxBytes
  this.ev = $({}) // used to trigger events

  const ext = options.ext

  if (file instanceof File) {
    this.fileObject = file
  } else {
    this.fileInput = file
  }

  if (ext instanceof Array) {
    if (ext.length) {
      this.extension = new RegExp(ext.map((str) => `\\.${str}$`).join('|'), 'i')
    } else {
      // eslint-disable-next-line prefer-regex-literals
      this.extension = new RegExp('.')
    }
  } else {
    // eslint-disable-next-line prefer-regex-literals
    this.extension = new RegExp('.')
  }

  this._headers = {}
  this.serverless = options.serverless
}

FileUploader.sanitizeFilename = function (filename) {
  return filename.replace(/[^\w.]/g, '-')
    .replace(/[-_]+/g, '-')
    .replace(/-\.|\.-/g, '.')
    .replace(/^[-_.]/, '')
}

FileUploader.prototype.on = function (name, fnc) {
  this.ev.on(name, fnc)
}

FileUploader.prototype.processError = function (responseText, file) {
  let data

  try {
    data = $.parseJSON(responseText)
  } catch (SyntaxError) {
    data = {}
  }
  const errors = (data && data.errors) || data

  this.triggerError(errors, file)
}

FileUploader.prototype.triggerError = function (errors, file) {
  this.ev.trigger('error', [errors, file])
}

FileUploader.prototype.startUpload = function () {
  let file
  if (this.fileObject) {
    file = this.fileObject
  } else {
    file = this.fileInput.files[0]
  }

  const prettyMaxSize = prettyFileSize(this.maxBytes, true)

  if (!file) {
    this.ev.trigger('error', [{
      'file': ['is_invalid'],
    }, file])
    return false
  } else if (file.size < this.minBytes) {
    this.ev.trigger('error', [{
      'file': ['is_too_small'],
    }, file])
    return false
  } else if (file.size > this.maxBytes) {
    this.ev.trigger('error', [{
      'file': [`size cannot be larger than ${prettyMaxSize}`],
    }, file])
    return false
  } else if (!this.extension.test(file.name)) {
    this.ev.trigger('error', [{
      'file': ['extension'],
    }, file])
    return false
  }

  this._upload(file)
}

FileUploader.prototype._upload_s3 = async function (file) {
  const { key: uploadKey, type: uploadType } = this.serverless
  const self = this
  self.ev.trigger('start', [file])

  const {
    data: {
      generateS3MultipartUpload: {
        key: s3Key,
        bucket,
        aws_key: awsKey,
      },
    },
  } = await apollo().mutate({
    mutation: gql`mutation GenerateS3MultipartUpload($input: GenerateS3MultipartUploadInput!) {
      generateS3MultipartUpload(input: $input) {
        bucket
        key
        aws_key
      }
    }`,
    variables: {
      input: {
        upload_key: uploadKey,
        file_name: FileUploader.sanitizeFilename(file.name),
        type: uploadType,
      },
    },
  })

  const evaporateInstance = await evaporate.create({
    awsSignatureVersion: '2',
    bucket,
    aws_key: awsKey,
    onlyRetryForSameFileName: true,
    partSize: 100 * 1024 * 1024, // 100 mb
    progressIntervalMS: 500,
    logging: enableLogging(),
    customAuthMethod: async (signParams, signHeaders, stringToSign) => {
      const { data: { generateAWSV4Signature: { signed } } } = await apollo().mutate({
        mutation: gql`mutation GenerateAWSV4Signature($input: GenerateAWSV4SignatureInput!) {
          generateAWSV4Signature(input: $input) {
            signed
          }
        }`,
        variables: {
          input: {
            to_sign: decodeURIComponent(stringToSign), // evaporate library encodeURIComponent's this for the typical xhr use case. so it must be reversed
          },
        },
      })
      return signed
    },
  })

  await evaporateInstance.add({
    name: s3Key,
    file,
    contentType: file.type,
    started: () => {
      self.ev.trigger('start', [file])
    },
    progress: (p, { totalUploaded, fileSize }) => {
      // normalize to xhr progress event
      self.ev.trigger($.Event({
        loaded: totalUploaded,
        type: 'progress',
        total: fileSize,
      }))
    },
  })

  // notify the server the upload finished so the lambda can be triggered
  const {
    data: {
      notifyUploadComplete: {
        file_upload_id: fileUploadId,
        errors,
      },
    },
  } = await apollo().mutate({
    mutation: gql`mutation NotifyUploadComplete($input: NotifyUploadCompleteInput!) {
      notifyUploadComplete(input: $input) {
        errors {
          key
          messages
        }
        file_upload_id
      }
    }`,
    variables: {
      input: {
        upload_key: uploadKey,
        file_size: file.size,
        file_name: file.name,
        content_type: file.type,
        s3_key: s3Key,
        type: uploadType,
        duration: await this._getDuration(file)
      },
    },
  })

  if (errors) {
    this.ev.trigger('error', [errors, file])
  }

  if (self.fileInput) {
    self.fileInput.value = ''
  }

  const response = uploadKey === 'ingest_statement' ? s3Key : fileUploadId

  self.ev.trigger('finish', [file, response])
}

FileUploader.prototype._upload = async function (file) {
  const self = this
  try {
    await this._upload_s3(file)
  } catch (error) {
    if (self.fileInput) {
      alert('An error occurred while uploading the file, please try again later.')
    }
    self.ev.trigger('error', [{}, file])
  }
}

FileUploader.prototype.setRequestHeader = function (name, value) {
  this._headers[name] = value
}

FileUploader.prototype._getDuration = async function (file) {
  const fileExtension = file.name.split('.').pop()

  if (!this._isMediaExtension(fileExtension)) {
    return null
  }

  const { videoElement, objectUrl } = await this._loadVideoElementMetadata(file) // This is a video HTML element, not necessarily a video file
  const seconds = videoElement.duration // duration is a decimal number

  window.URL.revokeObjectURL(objectUrl) // We are done with the objectURL, so it should be cleaned up

  return toInteger(seconds * 1000) // convert to milliseconds
}

FileUploader.prototype._isMediaExtension = function (fileExtension) {
  return mediaExtensions.some((ext) => ext.toLowerCase() === fileExtension.toLowerCase())
}

FileUploader.prototype._loadVideoElementMetadata = function (file) {
  return new Promise((resolve, reject) => {
    try {
      const objectUrl = window.URL.createObjectURL(file)
      const videoElement = document.createElement('video')
      videoElement.preload = 'metadata'

      videoElement.onloadedmetadata = function () {
        resolve({ videoElement: this, objectUrl })
      }

      videoElement.src = objectUrl
    } catch (error) {
      reject(error)
    }
  })
}
export default FileUploader
