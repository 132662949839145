const cache = {}

// Convert the template into pure JavaScript
const parseTemplate = function (templateStr) {
  return templateStr
    .replace(/[\r\t\n]/g, ' ')
    .replace(/'/g, '\\\'')
    .split('<%')
    .join('\t')
    .replace(/((^|%>)[^\t\\]*)'/g, '$1\r')
    .replace(/\t=(.*?)%>/g, '\',$1,\'')
    .split('\t')
    .join('\');')
    .split('%>')
    .join('p.push(\'')
    .replace(/\s+/g, ' ')
    .split('\r')
    .join('\\\'')
}

const render = function (templateID, data) {
  let templateFn = cache[templateID]
  let templateStr

  if (!templateFn) {
    templateStr = document.getElementById(templateID).innerHTML
    /* eslint-disable no-new-func */
    templateFn = new Function('obj',
      `${'var p=[],print=function(){p.push.apply(p,arguments);};'

      // Introduce the data as local variables using with(){}
      + 'with(obj){p.push(\''}${
        parseTemplate(templateStr)
      }');}return p.join('');`)
    cache[templateID] = templateFn
    /* eslint-enable no-new-func */
  }

  return templateFn(data)
}

export default render
