import 'select2'
import { isArray } from 'lodash-es'
import $ from 'jquery'
import ready from '../utils/ready'

ready(() => {
  $('.form-field.multi-select').each(function () {
    const $this = $(this)
    const $field = $this.find('select')

    const options = {
      'width': '100%',
    }

    $field.select2(options)
  })

  $('.form-field.fancy-select').each(function () {
    const $this = $(this)
    const $field = $this.find('select')

    const options = {
      'width': '100%',
    }

    $field.select2(options)
  })

  $('.free-tags').each(function () {
    const $this = $(this)
    const $pseudoField = $this.find('input[type="text"]')
    const $selectField = $this.find('select')
    const delimiter = $pseudoField.data('delimiter')
    const tagsOptions = $pseudoField.data('tags')
    const tags = isArray(tagsOptions) ? tagsOptions : []

    const options = {
      'width': '100%',
      tags,
      'tokenSeparators': [',', ' '], // TODO: is there a reason we don't use `delimiter` here?
      'formatNoMatches' (term) {
        return term
      },
    }

    const update = function () {
      const tagsArray = ($pseudoField.val() || '').split(delimiter)
      let optionsMarkup = ''

      $.each(tagsArray, (_, tag) => {
        optionsMarkup += `<option selected="selected" value="${tag}">${tag}</option>`
      })
      $selectField.html(optionsMarkup)
    }

    $pseudoField.select2(options)
    $pseudoField.on('change', () => {
      update()
    })
  })

  $('.per-page').each(function () {
    const $this = $(this)

    $this.on('change', function () {
      const value = $(this).val()
      const url = new URL(window.location.href)
      const newSearchParams = new URLSearchParams({
        per_page: value,
        page: 1,
      })
      url.search = newSearchParams
      window.location.href = url.href
    })
  })
})
