const registered: Record<string, any> = {}

export function register (lookupName: string, componentClass: any) {
  if (registered[lookupName]) throw new Error(`Duplicate lookupName for React component: ${lookupName}`)
  registered[lookupName] = componentClass

  return componentClass
}

// ts-prune-ignore-next - Used in D1 JS
export function lookup (lookupName: string) {
  if (!registered[lookupName]) throw new Error(`No React component registered for lookupName: ${lookupName}`)

  return registered[lookupName]
}
