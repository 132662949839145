import $ from 'jquery'

$.extend({
  form (url, data, method) {
    if (method == null) {
      method = 'POST'
    }
    if (data == null) {
      data = {}
    }

    const form = $('<form>').attr({
      method,
      action: url,
    })
      .css({
        display: 'none',
      })

    const addData = function (name, data) {
      if ($.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          const value = data[i]
          addData(`${name}[]`, value)
        }
      } else if (typeof data === 'object') {
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            addData(`${name}[${key}]`, data[key])
          }
        }
      } else if (data != null) {
        form.append($('<input>').attr({
          type: 'hidden',
          name: String(name),
          value: String(data),
        }))
      }
    }

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        addData(key, data[key])
      }
    }

    return form.appendTo('body')
  },
})
