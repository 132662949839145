// - -------------------------------------------------------------------- - //

'use strict'

import Model from './Model.js'

// - -------------------------------------------------------------------- - //

export default Model.createModel({

  properties: [
    'id',
    'name',
    'user_id',
    'image_path',
    'bio',
    'bullet_points',
    'site',
    'soundcloud',
    'itunes',
    'beatport',
    'facebook',
    'tumblr',
    'twitter',
    'instagram',
    'vevo_user',
    'paid_until',
    'has_vevo_channel',
    'powered_by_vydia',
    'vevo_channel_created_at',
    'vevo_channel_type',
  ],

  isFresh () {
    return !this.hasValue('id')
  },

})

// - -------------------------------------------------------------------- - //
