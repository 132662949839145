// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import { toArray } from 'lodash-es'
import $ from 'jquery'
import FaIcon from '../FaIcon.jsx'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'DropFileArea'

  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    placeholderSubtext: PropTypes.string,
    onDropFile: PropTypes.func.isRequired,
    name: PropTypes.string,
  }

  // Removes all drag-n-drop listeners.
  componentWillUnmount () {
    const elm = $(ReactDOM.findDOMNode(this))
    const input = elm.find('input[type=file]')
    $([elm[0], input[0], document]).off('.dropFileArea')
  }

  // Listens to drag-n-drop events.
  componentDidMount () {
    const elm = $(ReactDOM.findDOMNode(this))
    const input = elm.find('input[type=file]')
    const onDropFile = this.props.onDropFile

    // Handles selected files on input field.
    input.on('change.dropFileArea', function () {
      if (this.files) {
        toArray(this.files).forEach((file) => {
          onDropFile(file)
        })
        input.wrap('<form>').parent()[0].reset()
        input.unwrap()
      }
    })

    // Prevents default behavior.
    $([elm[0], document])
      .on('dragenter.dropFileArea', (event) => {
        event.preventDefault()
        event.stopPropagation()
      })
      .on('dragover.dropFileArea', (event) => {
        event.preventDefault()
        event.stopPropagation()
      })
      .on('drop.dropFileArea', (event) => {
        event.preventDefault()
        event.stopPropagation()
      })

    // Handles files and folders.
    elm.on('drop.dropFileArea', (event) => {
      // Read entries recursively.
      const handleEntry = function (entry) {
        if (entry.isDirectory) {
          entry.createReader().readEntries((entries) => {
            entries.forEach(handleEntry)
          })
        } else if (entry.isFile) {
          entry.file((file) => {
            onDropFile(file)
          })
        }
      }

      const dataTransfer = event.originalEvent.dataTransfer

      if (dataTransfer.items) {
        toArray(dataTransfer.items).forEach((item) => {
          if (item.webkitGetAsEntry) {
            const entry = item.webkitGetAsEntry()
            if (entry) {
              handleEntry(entry)
            }
          } else {
            alert('webkit required')
          }
        })
      } else if (dataTransfer.files) {
        toArray(dataTransfer.files).forEach((file) => {
          onDropFile(file)
        })
      }
    })
  }

  renderSubtext = () => (
    <p>
      { this.props.placeholderSubtext }
    </p>
  )

  render = () => {
    const input = this.props.multiple
      ? (
        <input
          multiple
          name={this.props.name}
          type='file'
        />
      )
      : (
        <input
          name={this.props.name}
          type='file'
        />
      )
    return (
      <div className='drop-file-area'>
        <div className='drop-area'>
          <FaIcon icon='cloud-upload' />
          <span>
            { this.props.placeholder }
          </span>
          { !!this.props.placeholderSubtext && this.renderSubtext() }
          { input }
        </div>
      </div>
    )
  }
}

// - -------------------------------------------------------------------- - //
