// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import ArtworkUpload from './ArtworkUpload.jsx'
import AudioFileModel from '../flux/models/AudioFileModel.js'
import AudioToVideoAction from '../flux/actions/AudioToVideoAction.js'
import ButtonGroup from './ButtonGroup.jsx'
import ColorInput from './ColorInput.jsx'
import GlobalSettingsModel from '../flux/models/GlobalSettingsModel.js'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import TextField from '../TextField.jsx'
import Texts from '../texts/texts.js'
import VideoPreview from './VideoPreview.jsx'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'VideoSettings'

  static propTypes = {
    fonts: PropTypes.array,
    selectedFile: PropTypes.instanceOf(AudioFileModel),
    globalSettings: PropTypes.instanceOf(GlobalSettingsModel),
    isEyeDropperEnabled: PropTypes.bool.isRequired,
  }

  handleChange = (field) => {
    AudioToVideoAction.updateVideoSettings(field)
  }

  renderTextField = (model, name, attr, readOnly) => (
    <TextField
      disabled={!!readOnly}
      label_attribute={Texts.a2v.video[attr]}
      maxLength={100}
      model={model}
      model_attribute={attr}
      model_name={name}
      onChange={() => this.handleChange(attr)}
      readOnly={!!readOnly}
    />
  )

  renderUploadArtwork = () => (
    <ArtworkUpload
      globalSettings={this.props.globalSettings}
      selectedFile={this.props.selectedFile}
    />
  )

  handleSelectFont = () => {
    const file = this.props.selectedFile
    const value = ReactDOM.findDOMNode(this.refs.font).value
    file.setValue('font', value)
    AudioToVideoAction.updateVideoSettings('font')
  }

  handleSelectVariantFont = () => {
    const file = this.props.selectedFile
    const value = ReactDOM.findDOMNode(this.refs.fontVariant).value
    file.setValue('fontVariant', value)
    AudioToVideoAction.updateVideoSettings('fontVariant')
  }

  renderOptionFont = (font, index) => (
    <option
      key={index}
      value={font}
    >
      { font }
    </option>
  )

  renderSelectFont = () => {
    const file = this.props.selectedFile
    const fonts = this.props.fonts
    if (file.getValue('show_text')) {
      let value = file.getValue('font')
      if (!value) {
        value = fonts[0]
      }
      return (
        <div className='form-field'>
          <label
            className='form-label'
            htmlFor='font-picker'
          >
            { Texts.a2v.video.font }
          </label>
          <select
            className='form-input'
            id='font-picker'
            onChange={this.handleSelectFont}
            ref='font'
            value={value}
          >
            { fonts.map(this.renderOptionFont) }
          </select>
        </div>
      )
    }
  }

  renderShowText = () => (
    <div className='form-field'>
      <label
        className='form-label'
        htmlFor='show-text-checkbox'
      >
        { Texts.a2v.video.show_text }
      </label>
      <input
        checked={!!this.props.selectedFile.getValue('show_text')}
        id='show-text-checkbox'
        name='audio_file[show_text]'
        onChange={this.handleShowText}
        ref='showText'
        type='checkbox'
      />
    </div>
  )

  handleShowText = () => {
    const file = this.props.selectedFile
    const value = ReactDOM.findDOMNode(this.refs.showText).checked
    file.setValue('show_text', value)
    AudioToVideoAction.updateVideoSettings('show_text')
  }

  renderFontColor = () => {
    const file = this.props.selectedFile
    if (file.getValue('show_text')) {
      return (
        <div className='form-field'>
          <label className='form-label'>
            { Texts.a2v.video.font_color }
          </label>
          <ColorInput
            color={file.getValue('font_color')}
            colorPicker
            eyeDropper
            name='color'
            onChange={this.handleFontColor}
          />
          <p className='form-note'>
            { Texts.a2v.video.color_picker.note }
          </p>
          <div className='clear' />
        </div>
      )
    }
  }

  handleFontColor = (color) => {
    if (color) {
      const file = this.props.selectedFile
      file.setValue('font_color', color)
      AudioToVideoAction.updateVideoSettings('font_color')
    }
  }

  renderFontShadow = () => {
    const file = this.props.selectedFile
    if (file.getValue('show_text')) {
      return (
        <div className='form-field'>
          <label className='form-label'>
            { Texts.a2v.video.font_shadow }
          </label>
          <ButtonGroup
            onChange={this.handleFontShadow}
            options={['white', 'black', 'none']}
            selected={file.getValue('font_shadow')}
          />
        </div>
      )
    }
  }

  handleFontShadow = (shadow) => {
    const file = this.props.selectedFile
    file.setValue('font_shadow', shadow)
    AudioToVideoAction.updateVideoSettings('font_shadow')
  }

  render () {
    let content

    const selectedFile = this.props.selectedFile
    if (selectedFile) {
      content = (
        <div className='row'>
          <div className='col-md-6'>
            { this.renderUploadArtwork() }
            { this.renderTextField(selectedFile, 'audio_file', 'song_name') }
            { this.renderTextField(selectedFile.getValue('artist'), 'artist', 'name', true) }
            { this.renderShowText() }
            { this.renderSelectFont() }
            { this.renderFontColor() }
            { this.renderFontShadow() }
          </div>
          <div className='col-md-6 video-preview-outer'>
            <VideoPreview
              globalSettings={this.props.globalSettings}
              isEyeDropperEnabled={this.props.isEyeDropperEnabled}
              selectedFile={this.props.selectedFile}
            />
          </div>
        </div>
      )
    } else {
      content = (
        <div className='row'>
          <div className='col-md-12 empty'>
            { Texts.a2v.video.select }
          </div>
        </div>
      )
    }

    const fontNames = this.props.fonts.map((value) => value.replace(' ', '+'))

    return (
      <div className='video-settings'>
        <style>
          { `@import url(https://fonts.googleapis.com/css?family=${fontNames.join('|')});` }
        </style>
        { content }
      </div>
    )
  }
}

// - -------------------------------------------------------------------- - //
