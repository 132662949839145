// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import AudioToVideoAction from '../flux/actions/AudioToVideoAction.js'
import Container from '../Container.jsx'
import FaIcon from '../FaIcon.jsx'
import GlobalSettingsModel from '../flux/models/GlobalSettingsModel.js'
import ImagePreview from './ImagePreview.jsx'
import Overlay from '../Overlay.jsx'
import PropTypes from 'prop-types'
import Texts from '../texts/texts.js'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'BackgroundWarning'

  static propTypes = {
    globalSettings: PropTypes.instanceOf(GlobalSettingsModel).isRequired,
  }

  handleConfirm = () => {
    AudioToVideoAction.confirmBackgroundWarning()
  }

  handleCancel = () => {
    AudioToVideoAction.cancelBackgroundWarning()
  }

  renderImage = () => {
    let image
    const backgroundImage = this.props.globalSettings.getValue('backgroundImage')
    if (backgroundImage) {
      const file = backgroundImage.getValue('file')
      if (file) {
        image = <ImagePreview file={file} />
      }
    }
    return image
  }

  render () {
    return (
      <Overlay
        className='background-warning'
        key='background-warning'
      >
        <Container
          className='container-xs'
          heading={Texts.a2v.backgroundWarning.title}
        >

          <FaIcon
            icon='times'
            onClick={this.handleCancel}
          />

          <h2>
            { Texts.a2v.backgroundWarning.message }
          </h2>

          <ul>
            { Texts.a2v.backgroundWarning.checklist.map((item, index) => ( // eslint-disable-next-line react/no-array-index-key
              <li key={index}>
                { item }
              </li>
            )) }
          </ul>

          { this.renderImage() }

          <p>
            { Texts.a2v.backgroundWarning.question }
          </p>

          <nav className='secondary-nav'>
            <ul>
              <li>
                <button
                  className='btn btn-primary'
                  onClick={this.handleConfirm}
                  type='button'
                >
                  { Texts.a2v.backgroundWarning.confirm }
                  { ' ' }
                  <FaIcon icon='arrow-right' />
                </button>
              </li>
              <li>
                <button
                  className='btn btn-link'
                  onClick={this.handleCancel}
                  type='button'
                >
                  { Texts.a2v.backgroundWarning.cancel }
                </button>
              </li>
            </ul>
            <div className='clear' />
          </nav>
        </Container>
      </Overlay>
    )
  }
}

// - -------------------------------------------------------------------- - //
