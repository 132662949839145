import { lazy, memo } from 'react'
import type { OwnProps } from 'd2/partials/TemporarilyLockedModal/types'

export type Props = OwnProps & {
  standalone?: boolean
}

const Default = lazy<React$ComponentType<Props>>(
  () => import('d2/partials/TemporarilyLockedModal'),
)

const Standalone = lazy<React$ComponentType<Props>>(
  () => import('d2/partials/TemporarilyLockedModal/standalone'),
)

const TemporarilyLockedModal = memo<Props>((props) => (
  props.standalone ? <Standalone {...props} /> : <Default {...props} />
))

export default TemporarilyLockedModal
