// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import $ from 'jquery'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'TextLine'

  static propTypes = {
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
    className: PropTypes.string,
  }

  componentDidUpdate () {
    const elm = ReactDOM.findDOMNode(this)
    const width = elm.offsetWidth
    let fontSize = parseInt($(elm).css('fontSize', '')
      .css('fontSize'))
    while (elm.scrollWidth > width) {
      elm.style.fontSize = `${--fontSize}px`
    }
  }

  render () {
    return (
      <div
        className={`${this.props.className} text-line`}
        style={this.props.style}
      >
        { this.props.text }
      </div>
    )
  }
}

// - -------------------------------------------------------------------- - //
