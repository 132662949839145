import $ from 'jquery'
import ready from '../utils/ready'

ready(() => {
  const $everything = $('*:visible')
  const $body = $('body')
  $('form.delete-form').on('submit', (event) => {
    if (!window.confirm('Are you sure?')) {
      event.preventDefault()
    }
  })

  $('form.single-submit').on('submit', (event) => {
    const $form = $(event.target)

    if ($form.hasClass('submitting')) {
      event.preventDefault()
    } else {
      $form.addClass('submitting')
      $form.find('.btn-primary').addClass('disabled')
    }
  })

  $everything.on('dragstart dragenter dragover drag', () => {
    $body.addClass('drag-over')
  })

  $everything.on('dragleave drop dragend', () => {
    $body.removeClass('drag-over')
  })

  // Disable pasting into confirmation fields
  $('.form-field.confirmation input[type=text]').bind('paste', (e) => {
    e.preventDefault()
  })
})
