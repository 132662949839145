import 'select2'
import $ from 'jquery'
import TextAreaHelpers from '../utils/TextAreaHelpers'
import ready from '../utils/ready'

ready(() => {
  // need to do setTimeout to separate the mergeTag function with the main thread to wait for the finish of the main thread
  setTimeout(mergeTag, 1)
})

const mergeTag = function () {
  $('.merge-tags-selector').each(function () {
    const $select = $(this)

    const resetToBlankState = function () {
      const $defaultOption = $select.find('option.blank')
      $defaultOption.prop('selected', true)
      $select.select2('val', $defaultOption.val())
      $select.trigger('change.select2')
    }

    const insertMergeTagIntoTarget = function (value) {
      const targetSelector = $select.data('target')
      const $target = $(targetSelector)
      // TODO: Intelligently decide if spaces should go before and/or after.
      TextAreaHelpers.insertAtCurrentPositionAndFocus($target, ` *|${value}|* `)
    }

    $select.select2({
      width: '100%',
    })

    $select.on('change', () => {
      const selectedValue = $select.val()
      if (selectedValue) {
        insertMergeTagIntoTarget(selectedValue)
      }
      resetToBlankState()
    })
  })
}
