// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import { PhotoshopPicker } from 'react-color'
import EyeDropper from './EyeDropper.jsx'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'ColorInput'

  static propTypes = {
    name: PropTypes.string,
    color: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    colorPicker: PropTypes.bool,
    eyeDropper: PropTypes.bool,
  }

  state = {
    value: this.props.color || '',
  }

  componentWillReceiveProps (newProps) {
    if (newProps.color !== this.state.value) {
      this.setState({ value: newProps.color })
    }
  }

  handleEyeDropperChange = (color) => {
    this.setState({ value: color })
    if (this.props.onChange) {
      this.props.onChange(color)
    }
  }

  handleInputChange = () => {
    const color = ReactDOM.findDOMNode(this.refs.input).value
    this.setState({ value: color })
    if (this.props.onChange) {
      this.props.onChange(this.validColor(color))
    }
  }

  handlePickerChange = (color) => {
    color = color.hex
    this.setState({ value: color })
    if (this.props.onChange) {
      this.props.onChange(color)
    }
  }

  handleFocus = () => {
    this.setState({ focused: true })
  }

  handleBlur = () => {
    if (!this._mouseOverPicker) {
      this.closePicker()
    }
  }

  closePicker = () => {
    this._mouseOverPicker = false
    this.setState({ focused: false })
  }

  renderEyeDropper = () => {
    if (this.props.eyeDropper) {
      return (
        <EyeDropper onChange={this.handleEyeDropperChange} />
      )
    }
  }

  handleMouseEnter = () => {
    this._mouseOverPicker = true
  }

  handleMouseLeave = () => {
    this._mouseOverPicker = false
    ReactDOM.findDOMNode(this.refs.input).focus()
  }

  validColor = (color) => {
    if (/^#[0-9ABCDEFabcdef]{3}$|^#[0-9ABCDEFabcdef]{6}$/.test(color)) {
      return color
    }
  }

  renderColorPicker = () => {
    if (this.props.colorPicker && this.state.focused) {
      return (
        <div
          className='colorpicker-wrapper'
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          <div className='photoshop-picker-wrapper'>
            <PhotoshopPicker
              color={this.validColor(this.state.value)}
              header='Choose Background Color'
              onAccept={this.closePicker}
              onCancel={this.closePicker}
              onChange={this.handlePickerChange}
            />
          </div>
        </div>
      )
    }
  }

  render () {
    return (
      <div className='color-input'>
        <span style={{ backgroundColor: this.validColor(this.state.value) }} />
        <input
          className='form-input'
          maxLength='7'
          name={this.props.name}
          onBlur={this.handleBlur}
          onChange={this.handleInputChange}
          onFocus={this.handleFocus}
          ref='input'
          type='text'
          value={this.state.value || ''}
        />
        { this.renderColorPicker() }
        { this.renderEyeDropper() }
      </div>
    )
  }
}

// - -------------------------------------------------------------------- - //
