// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import { throttle } from 'lodash-es'
import $ from 'jquery'
import BackgroundImageModel from '../flux/models/BackgroundImageModel.js'
import BackgroundPreview from './BackgroundPreview.jsx'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'GlobalPreview'

  static propTypes = {
    backgroundColor: PropTypes.string,
    backgroundImage: PropTypes.instanceOf(BackgroundImageModel).isRequired,
    backgroundTemplate: PropTypes.string,
  }

  componentDidMount () {
    const elm = $(ReactDOM.findDOMNode(this))
    const outer = elm.parent()
    const width = elm.width()
    const height = elm.height()
    const resizePreview = throttle(() => {
      const availableWidth = outer.width()
      const availableHeight = outer.height()
      const top = (height - availableHeight) / 2
      const left = (width - availableWidth) / 2
      const ratio = Math.min(availableWidth, 600) / width
      elm.css({
        top: -top,
        left: -left,
        transform: `scale(${ratio})`,
      })
    }, 400)
    $(window).on('resize.globalPreview', resizePreview)
    resizePreview()
  }

  componentWillUnmount () {
    $(window).off('.globalPreview')
  }

  render () {
    return (
      <div className='global-preview'>
        <BackgroundPreview
          backgroundColor={this.props.backgroundColor}
          backgroundImage={this.props.backgroundImage}
          backgroundTemplate={this.props.backgroundTemplate}
        />
      </div>
    )
  }
}

// - -------------------------------------------------------------------- - //
