import { sentenceCase, stringifyErrors } from 'd2/utils/StringHelpers'
import $ from 'jquery'
import Errors from '../utils/Errors.js'
import helpers from '../ui/helpers.js'

const Form = function (form, options) {
  options = options || {}

  this.$form = $(form)
  this.$general_error = this.$form.find('.form-general-error')

  this.$form_top = this.$form.closest(this.$form.data('form-top'))
  this.$form_top = this.$form_top.length > 0 ? this.$form_top : this.$form

  this.$submit_btn = this.$form.find('.form-submit-button')
  this.$submit_btn.html(
    `<span class="form-button-content">${
      this.$submit_btn.html()
    }</span>`
    + '<i class="fa fa-spinner fa-spin ajax-loading"></i>'
  )

  this.analytics = options.analytics || null

  this._bindEvents()
  this.namespace = options.namespace
}

Form.supportedBrowser = function () {
  return typeof FormData !== 'undefined'
}

Form.displayErrorOnField = function ($field, messages) {
  const $formField = $field.closest('.form-field')
  let markup

  if (messages) {
    markup = '<div class="input-errors" style="display: none;">'
    $.each(messages, (_, message) => {
      markup += `<div class="input-error">${message}</div>`
    })
    markup += '</div>'

    $field.after(markup)
  }

  $formField.addClass('with-errors')
  $formField.find('.input-errors').slideDown()
}

Form.removeErrorFromField = function ($field) {
  const $formField = $field.closest('.form-field')
  const $inputErrors = $formField.find('.input-errors')
  $formField.removeClass('with-errors')
  $inputErrors.remove()
}

// convert the traditional errors Object into a HR Array
Form.stringifyErrors = function (errors) {
  return stringifyErrors(errors)
}

Form.sentenceCase = function (string) {
  return sentenceCase(string)
}

Form.prototype.removeErrors = function () {
  this.$form.find('.form-field')
    .removeClass('with-errors')
    .find('.input-errors')
    .remove()
  this.$form.removeClass('with-errors')
  this.$general_error.html('')
}

Form.prototype.displayErrors = function (errors) {
  const self = this

  self.removeErrors()

  errors = new Errors(errors)
  const fieldsErrorMessages = errors.getReadableMessagesObject()

  self.$form.addClass('with-errors')
  self.$general_error.text('Please review the form and resubmit to continue.')

  helpers.scroll_to(self.$form_top, null, {
    padding: 0,
  })

  $.each(fieldsErrorMessages, (field, fieldMessages) => {
    const fieldName = `${self.namespace}[${field}]`
    const $field = self.$form.find(`[name="${fieldName}"]`)

    Form.displayErrorOnField($field, fieldMessages)
  })
}

Form.prototype.onSubmit = function (callback) {
  this._submitCallbacks = this._submitCallbacks || []
  this._submitCallbacks.push(callback)
}

Form.prototype.onSuccess = function (callback) {
  this._successCallbacks = this._successCallbacks || []
  this._successCallbacks.push(callback)
}

Form.prototype.onFailure = function (callback) {
  this._failureCallbacks = this._failureCallbacks || []
  this._failureCallbacks.push(callback)
}

Form.prototype._bindEvents = function () {
  const self = this

  self.$form.on('submit', (event) => {
    event.preventDefault()
    self.submit()
    return false
  })
}

Form.prototype.clearFields = function () {
  this.$form.find('.form-field').find('input, select, textarea')
    .val('')
}

Form.prototype.populate = function (data) {
  const self = this

  $.each(data, (field, fieldValue) => {
    const fieldName = `${self.namespace}[${field}]`
    const $field = self.$form.find(`[name="${fieldName}"]`)

    $field.val(fieldValue)
  })
}

Form.prototype.submit = function () {
  const self = this

  if (self.submitting) {
    return
  }

  const $form = self.$form
  const submitCallbacks = self._submitCallbacks || []

  self._formObject = self._getSerializedObject(true)
  self._formData = self._getFormData()
  self._formAction = $form.attr('action') || window.location.pathname
  self._formMethod = $form.attr('method')

  const $submission = $.ajax({
    method: self._formMethod,
    url: self._formAction,
    data: self._formData,
    processData: !Form.supportedBrowser(),
    contentType: false,
    dataType: 'json',
  })

  self.start_submitting()

  $submission.done((data) => {
    self._success(data)
  })

  $submission.fail((request) => {
    self._failure(request)
  })

  for (let i = 0, len = submitCallbacks.length; i < len; i += 1) {
    submitCallbacks[i].call(self, self._formData)
  }
}

Form.prototype.start_submitting = function () {
  this.$form.addClass('submitting')
  this.$form.find(':input[type!=hidden]').each(function () {
    const $this = $(this)
    $this.data('was_disabled', $this.prop('disabled'))
    $this.prop('disabled', true)
  })
  this.submitting = true
}
Form.prototype.stop_submitting = function () {
  this.$form.removeClass('submitting')
  this.$form.find(':input').each(function () {
    const $this = $(this)
    if (!$this.data('was_disabled')) {
      $this.prop('disabled', false)
    }
    $this.removeData('was_disabled')
  })
  this.submitting = false
}

Form.prototype._success = function (data) {
  const callbacks = this._successCallbacks || []
  let i
  let len

  this.removeErrors()

  for (i = 0, len = callbacks.length; i < len; i += 1) {
    callbacks[i].call(this, data)
  }
  this.stop_submitting()
}

Form.prototype._failure = function (request) {
  const callbacks = this._failureCallbacks || []
  let i
  let len
  let errors

  if (request.status === 400) {
    errors = JSON.parse(request.responseText).errors
    this.displayErrors(errors)
  } else if (request.status === 401) {
    window.location.href = '/'
  } else {
    alert('Oops! Something went wrong. Please try again in a little while.')
  }

  for (i = 0, len = callbacks.length; i < len; i += 1) {
    callbacks[i].call(this, errors || null)
  }
  this.stop_submitting()
}

Form.prototype._getFormData = function () {
  const $form = this.$form
  const $fileInputs = $form.find('input[type="file"]')

  if (!Form.supportedBrowser()) {
    if ($fileInputs.length > 0) {
      alert('Uh oh! Your browser is outdated.\n'
            + 'File uploads will not work here...\n'
            + 'please use a modern browser like Google Chrome.')
    }
    return this._getSerializedObject()
  }

  const formData = new FormData()

  $.each($form.serializeArray(), (index, field) => {
    formData.append(field.name, field.value)
  })
  $fileInputs.each((index, fileInput) => {
    if (fileInput.files.length > 0) {
      formData.append(fileInput.name, fileInput.files[0])
    }
  })

  return formData
}

Form.prototype._getSerializedObject = function (reload) {
  if (!reload && this._serializedObject) {
    return this._serializedObject
  }

  const $form = this.$form

  return (this._serializedObject = $form.serializeObject())
}

export default Form
