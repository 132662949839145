// - -------------------------------------------------------------------- - //

'use strict'

import Model from './Model.js'

// - -------------------------------------------------------------------- - //

export default Model.createModel({

  properties: [
    'id',
    'original_path',
    'mp4_path',
    'thumbnail_path',
    'type',
    'duration_in_ms',
  ],

  hasMedia () {
    return !!this.getValue('original_path')
  },

})

// - -------------------------------------------------------------------- - //
