// - -------------------------------------------------------------------- - //

'use strict'

import { each, isNil } from 'lodash-es'
import Model from './Model.js'

// - -------------------------------------------------------------------- - //

export default Model.createModel({

  properties: [
    'id',
    'filename',
    'artist',
    'uploaded',
    'length',
    'song_name',
    'font',
    'fontVariant',
    'font_color',
    'font_shadow',
    'artwork',
    'file_upload',
    'file_upload_id',
    'show_text',
    'composer',
    'label',
    'genre',
    'publisher',
    'video_isrc',
    'audio_isrc',
  ],

  isFresh () {
    return !this.hasValue('id')
  },

  isUploading () {
    const total = this.getValue('length')
    const uploaded = this.getValue('uploaded')
    return uploaded > 0 && uploaded < total
  },

  isComplete () {
    const total = this.getValue('length')
    const uploaded = this.getValue('uploaded')
    return total > 0 && uploaded === total
  },

  getProgress () {
    const total = this.getValue('length')
    const uploaded = this.getValue('uploaded')
    return (uploaded / total) * 100
  },

  copyUndefinedSettingsFrom (file) {
    const propsToCopy = [
      'font',
      'fontVariant',
      'font_color',
      'font_shadow',
      'show_text',
      'composer',
      'label',
      'genre',
      'publisher',
    ]

    propsToCopy.forEach((prop) => {
      const currentValue = this.getValue(prop)
      if (isNil(currentValue)) {
        this.setValue(prop, file.getValue(prop))
      }
    })
  },

  fillDefaultSettings () {
    const defaultProps = {
      show_text: true,
      font: 'Bevan',
      font_color: '#FFFFFF',
      font_shadow: 'black',
    }

    each(defaultProps, (value, prop) => {
      const currentValue = this.getValue(prop)
      if (isNil(currentValue)) {
        this.setValue(prop, value)
      }
    })
  },

})

// - -------------------------------------------------------------------- - //
