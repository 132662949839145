import Delegator from '../utils/Delegator'
import popup from '../utils/popup'

// @param url
// @param options
//   @option windowName
//   @option width
//   @option height
const PopUp = function (url, options) {
  options = options || {}

  options.windowName = options.windowName || '_blank'
  options.width = options.width || 800
  options.height = options.height || 600
  options.pollFrequency = options.pollFrequency || 800

  this.delegator = new Delegator()

  this.url = url
  this.options = options
}

PopUp.prototype.open = function () {
  if (this.isOpen()) {
    return
  }
  this.popWindow = popup(this.url, this.options.windowName, this.options.width, this.options.height)

  this._onOpen()

  return this.popWindow
}

PopUp.prototype.close = function () {
  if (this.isOpen()) {
    this.popWindow.close()
    // Check if the popWindow was actually closed.
    this.pollPopWindow()
  }
}

PopUp.prototype.focus = function () {
  if (window.focus && this.isOpen()) {
    this.popWindow.focus()
  }
}

PopUp.prototype.focusOrOpen = function () {
  if (this.isOpen()) {
    this.focus()
  } else {
    this.open()
  }
}

PopUp.prototype.pollPopWindow = function () {
  const stillOpen = this.isOpen()
  if (!stillOpen) {
    this._onClose()
  }
  return stillOpen
}

PopUp.prototype.isOpen = function () {
  return this.popWindow && !this.popWindow.closed
}

PopUp.prototype.startPolling = function () {
  const self = this
  self.pollInterval = setInterval(() => {
    self.pollPopWindow()
  }, self.options.pollFrequency)
}

PopUp.prototype.stopPolling = function () {
  clearInterval(this.pollInterval)
}

PopUp.prototype._onOpen = function () {
  this.focus()
  this.startPolling()
  this.trigger('open')
}

PopUp.prototype._onClose = function () {
  this.popWindow = undefined
  this.stopPolling()
  this.trigger('close')
}

Delegator.extendEventDelegation(PopUp.prototype, 'delegator')

export default PopUp
