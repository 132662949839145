/* eslint-disable no-unused-expressions, no-sequences */
if (window.googleAnalyticsLoaded === undefined) {
  (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
      (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date(); a = s.createElement(o),
    m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
  })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga')
  window.ga('create',
    window.VYDIA_SETTINGS.GOOGLE_ANALYTICS_ACCOUNT,
    'auto', { 'allowLinker': true })
  window.ga('require', 'displayfeatures')
  window.ga('require', 'linker')
  window.ga('linker:autoLink', window.VYDIA_SETTINGS.GOOGLE_ANALYTICS_LINKED_DOMAINS || [])

  window.googleAnalyticsLoaded = true
}

window.ga('send', 'pageview', window.current_readable_path)
