import $ from 'jquery'
import Filterable from '../components/Filterable'
import ready from '../utils/ready'

ready(() => {
  const $allReleases = $('#all-releases')
  if ($allReleases.length > 0) {
    const $tabsContainer = $allReleases.find('.widget-tabs')

    /* eslint-disable no-new */
    new Filterable({
      '$filterables': $allReleases.find('tbody tr'),
      $tabsContainer,
      moveToTop: false,
      showMethod: 'show',
      filterProp: 'filter-type',
    })
    /* eslint-enable no-new */
  }

  const toggleDetail = function (row, event) {
    event.preventDefault()
    event.stopPropagation()
    row.nextUntil(':not(.details-row)').toggle()
  }

  $('#submitted-releases').on('click', '.toggle-details', function (event) {
    const chevron = $(event.target)
    chevron.toggleClass('fa-chevron-down')
    chevron.toggleClass('fa-chevron-up')
    const row = $(this).parentsUntil('tr')
      .parent()
    toggleDetail(row, event)
  })

  $('#action-required-releases').on('click', '.toggle-details', function (event) {
    const chevron = $(event.target)
    chevron.toggleClass('fa-chevron-down')
    chevron.toggleClass('fa-chevron-up')
    const row = $(this).parentsUntil('tr')
      .parent()
    toggleDetail(row, event)
  })
})
