const TextAreaHelpers = {}

// Insert text into the provided element at the current position of the cursor.
// Then focus the element for the user, at the position of the end of the inserted text.
//
// Return the position of the last added character.
TextAreaHelpers.insertAtCurrentPositionAndFocus = function ($el, text) {
  const focusPosition = TextAreaHelpers.insertAtCurrentPosition($el, text)
  return TextAreaHelpers.setSelectionRange($el, focusPosition)
}

// Insert text into the provided element at the current position of the cursor.
//
// Return the position of the last added character.
TextAreaHelpers.insertAtCurrentPosition = function ($el, text) {
  const cursorPosition = TextAreaHelpers.getCursorPosition($el)
  return TextAreaHelpers.insertAtPosition($el, cursorPosition, text)
}

// Get the current position of the cursor ("caret") in the text area.
// Works even if it is not currently focused by the user.
TextAreaHelpers.getCursorPosition = function ($el) {
  const el = $el.get(0)
  let pos = 0
  if ('selectionStart' in el) {
    pos = el.selectionStart
  } else if ('selection' in document) {
    el.focus()
    const Sel = document.selection.createRange()
    const SelLength = document.selection.createRange().text.length
    Sel.moveStart('character', -el.value.length)
    pos = Sel.text.length - SelLength
  }
  return pos
}

// Insert text into the provided element at the specified position.
//
// Return the position of the last added character.
TextAreaHelpers.insertAtPosition = function ($el, position, text) {
  const oldValue = $el.val()
  const newValue = oldValue.substring(0, position) + text + oldValue.substring(position)
  $el.val(newValue)
  return position + text.length
}

// Focus the element and set the selection so that the cursor is at the specified position.
TextAreaHelpers.setSelectionRange = function ($el, selectionStart, selectionEnd /* (optional) */) {
  selectionEnd = selectionEnd || selectionStart

  const el = $el.get(0)
  if (el.setSelectionRange) {
    el.focus()
    el.setSelectionRange(selectionStart, selectionEnd)
  } else if (el.createTextRange) {
    const range = el.createTextRange()
    range.collapse(true)
    range.moveEnd('character', selectionEnd)
    range.moveStart('character', selectionStart)
    range.select()
  }
}

export default TextAreaHelpers
