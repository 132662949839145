// - -------------------------------------------------------------------- - //

'use strict'

import { throttle } from 'lodash-es'
import $ from 'jquery'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import createReactClass from 'create-react-class'
import cx from 'classnames'

// - -------------------------------------------------------------------- - //

const throttleMS = 100

// - -------------------------------------------------------------------- - //

export default createReactClass({
  displayName: 'ScrollView',

  propTypes: {
    // One of either of these is required:
    maxInnerHeight: PropTypes.number, // px height
    maxInnerHeightChildren: PropTypes.number, // Number of children to show at once.

    alwaysShowTop: PropTypes.bool,
    alwaysShowBottom: PropTypes.bool,
  },

  getInitialState () {
    return {
      atTop: true,
      atBottom: true,
      maxInnerHeightFromChildren: undefined,
    }
  },

  componentDidMount () {
    this._isMounted = true
    this._$inner = $(ReactDOM.findDOMNode(this.refs.inner))
    this._$inner.on('scroll', this.handleScroll)
    this.handleScroll()
    if (this.props.maxInnerHeightChildren) this.updateMaxInnerHeight()
  },

  componentDidUpdate () {
    this.handleScroll()
  },

  componentWillUnmount () {
    this._isMounted = false
    this._$inner.off('scroll', this.handleScroll)
  },

  updateMaxInnerHeight () {
    const $firstChild = this._$inner.children(':first-child')
    const newHeight = $firstChild.outerHeight() * this.props.maxInnerHeightChildren

    if (newHeight !== this.state.maxInnerHeightFromChildren) {
      this.setState({
        maxInnerHeightFromChildren: newHeight,
      })
    }
  },

  handleScroll: throttle(function () {
    if (!this._isMounted) return

    const inner = this._$inner[0]
    const offset = inner.scrollTop
    const scrollHeight = inner.scrollHeight

    this.setState({
      atTop: offset === 0,
      atBottom: scrollHeight - offset === this._$inner.innerHeight(),
    })
  }, throttleMS),

  getMaxHeight () {
    return this.props.maxInnerHeight || this.state.maxInnerHeightFromChildren
  },

  render () {
    const classes = cx('scroll-view', this.props.className, {
      'show-shadow-top': this.props.alwaysShowTop || !this.state.atTop,
      'show-shadow-bottom': this.props.alwaysShowBottom || !this.state.atBottom,
    })
    const maxHeight = this.getMaxHeight()

    const innerStyle = {
      maxHeight: maxHeight ? `${maxHeight}px` : undefined,
    }

    return (
      <div className={classes}>
        <div className='shadow-top' />
        <div
          className='scroll-view-inner'
          ref='inner'
          style={innerStyle}
        >
          { this.props.children }
        </div>
        <div className='shadow-bottom' />
      </div>
    )
  },

})
