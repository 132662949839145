// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import AudioFileModel from '../flux/models/AudioFileModel.js'
import AudioToVideoAction from '../flux/actions/AudioToVideoAction.js'
import DropFileArea from './DropFileArea.jsx'
import FaIcon from '../FaIcon.jsx'
import GlobalSettingsModel from '../flux/models/GlobalSettingsModel.js'
import ProgressBar from '../ProgressBar.jsx'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import Texts from '../texts/texts.js'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'ArtworkUpload'

  static propTypes = {
    selectedFile: PropTypes.instanceOf(AudioFileModel),
    globalSettings: PropTypes.instanceOf(GlobalSettingsModel),
  }

  handleRemove = () => {
    if (this.isGlobalArtwork()) {
      AudioToVideoAction.setArtwork(null)
    }
    AudioToVideoAction.removeArtwork()
  }

  handleApplyAll = () => {
    const file = this.props.selectedFile
    const artwork = file.getValue('artwork')
    const checked = ReactDOM.findDOMNode(this.refs.applyAll).checked
    if (artwork && checked) {
      AudioToVideoAction.setArtwork(artwork)
    } else {
      AudioToVideoAction.setArtwork(null)
    }
  }

  renderError = () => {
    const file = this.props.selectedFile
    const artwork = file.getValue('artwork')
    let errorContent
    if (artwork) {
      const trashIcon = (<FaIcon
        icon='trash-alt'
        onClick={this.handleRemove}
      />)
      const errors = artwork.getErrors()

      if (errors && errors[0] === 'dimensions') {
        errorContent = (
          <span className='error'>
            { Texts.a2v.video.artworkDimensions }
            { ' ' }
            { trashIcon }
          </span>
        )
      } else if (errors && errors[0] === 'extension') {
        errorContent = (
          <span className='error'>
            { Texts.a2v.video.artworkExtension }
            { ' ' }
            { trashIcon }
          </span>
        )
      } else if (errors && errors[0]) {
        errorContent = (
          <span className='error'>
            { errors[0] }
            { ' ' }
            { trashIcon }
          </span>
        )
      } else if (errors && !errors[0]) {
        errorContent = (
          <span className='error'>
            { Texts.a2v.video.artworkError }
            { ' ' }
            { trashIcon }
          </span>
        )
      }
    }

    return errorContent
  }

  getArtwork = () => {
    const file = this.props.selectedFile
    const globalArtwork = this.props.globalSettings.getValue('artwork')
    return globalArtwork || file.getValue('artwork')
  }

  isGlobalArtwork = () => {
    const file = this.props.selectedFile
    const globalArtwork = this.props.globalSettings.getValue('artwork')
    const artwork = file.getValue('artwork')
    return artwork === globalArtwork
  }

  hasGlobalArtwork = () => {
    const globalArtwork = this.props.globalSettings.getValue('artwork')
    return !!globalArtwork
  }

  renderProgress = () => {
    let progress
    const artwork = this.getArtwork()
    if (artwork && !artwork.getErrors()) {
      progress = (
        <ProgressBar
          active={!artwork.isComplete()}
          isFloat={false}
          progress={artwork.getProgress()}
          striped={!artwork.isComplete()}
        />
      )
    }
    return progress
  }

  renderFile = () => {
    let content
    const artwork = this.getArtwork()
    if (artwork) {
      content = (
        <div className='artwork-file'>
          <div className='artwork-filename'>
            { artwork.getValue('filename') }
            { this.isGlobalArtwork() || !this.hasGlobalArtwork()
              ? <FaIcon
                icon='trash-alt'
                onClick={this.handleRemove}
              />
              : null }
          </div>
          <div className='apply-all'>
            <input
              checked={this.hasGlobalArtwork()}
              id='apply-to-all'
              onChange={this.handleApplyAll}
              ref='applyAll'
              type='checkbox'
            />
            <label
              htmlFor='apply-to-all'
              title={Texts.a2v.video.applyAll}
            >
              { Texts.a2v.video.applyAll }
            </label>
          </div>
          <div className='artwork-progress'>
            { this.renderProgress() }
          </div>
          <span className='clear' />
        </div>
      )
    }
    return content
  }

  render () {
    const fileContent = this.renderFile()
    const errorContent = this.renderError()

    let content
    if (errorContent || !fileContent) {
      content = (
        <DropFileArea
          multiple={false}
          onDropFile={AudioToVideoAction.uploadArtwork}
          placeholder={Texts.a2v.video.artworkPlaceholder}
        />
      )
    } else {
      content = fileContent
    }

    return (
      <div className='form-field'>
        <label className='form-label'>
          { Texts.a2v.video.artwork }
        </label>
        { content }
        { errorContent }
      </div>
    )
  }
}

// - -------------------------------------------------------------------- - //
