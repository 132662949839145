import $ from 'jquery'
import ready from '../utils/ready'

ready(() => {
  const showComposerOwnershipTable = function (margin) {
    $('.rights-table').slideDown()
    $('.composers-ownership-table').css('margin-left', margin)
  }

  const hideComposerOwnershipTable = function () {
    $('.rights-table').slideUp()
  }

  const showRightsControlTable = function () {
    $('.rights-control').slideDown()
  }

  if (!$('#control_rights_music_video').is(':checked')) {
    $('[id$=\'same_as_above_container\']').slideUp()
    $('[id$=\'geo-modifiers\']').slideDown()
  }

  $('.rights-control').on('change', '[name^=\'control_rights\']', (event) => {
    const checkbox = $(event.target)

    if (checkbox.is(':checked')) {
      $(event.delegateTarget).find('.form-field-rights')
        .slideDown()
    } else {
      $(event.delegateTarget).find('.form-field-rights')
        .slideUp()
    }
  })

  $('#control_rights_music_video').on('change', (event) => {
    const checkbox = $(event.target)

    if (checkbox.is(':checked')) {
      $('[id$=\'same_as_above_container\']').slideDown()
      if ($('#composition_rights_same_as_above').is(':checked')) {
        $('#composition_rights-geo-modifiers').slideUp(500)
      }
      if ($('#sound_recording_rights_same_as_above').is(':checked')) {
        $('#sound_recording_rights-geo-modifiers').slideUp(500)
      }
    } else {
      $('[id$=\'same_as_above_container\']').slideUp()
      $('[id$=\'geo-modifiers\']').slideDown()
    }
  })

  $('#full_rights').on('click', () => {
    $('.rights-control').slideUp()
    $('.web-rights-control').slideDown()
    $('.web-protection-information').slideDown()
    if ($('.rights-table').find('input[name$=\'name]\']').length > 1) {
      showComposerOwnershipTable('20px')
    } else {
      hideComposerOwnershipTable()
    }
  })

  $('#partial_rights').on('click', () => {
    $('.web-rights-control').slideUp()
    $('.web-protection-information').slideUp()
    showRightsControlTable()
    if ($('#control_rights_composition').prop('checked')) {
      showComposerOwnershipTable('55px')
    } else {
      hideComposerOwnershipTable()
    }
  })

  $('#control_rights_composition').on('click', (event) => {
    const checkbox = $(event.target)
    if (checkbox.is(':checked')) {
      showComposerOwnershipTable('55px')
    } else {
      hideComposerOwnershipTable()
    }
  })

  if ($('#partial_rights').prop('checked')) {
    showRightsControlTable()
    hideComposerOwnershipTable()
  }

  if ($('#control_rights_composition').prop('checked')) {
    showComposerOwnershipTable('55px')
  }

  if ($('#full_rights').prop('checked')) {
    $('.web-rights-control').slideDown()
    $('.web-protection-information').slideDown()
    if (window.composersData.length > 1) {
      showComposerOwnershipTable('20px')
    } else {
      hideComposerOwnershipTable()
    }
  }
})
