// - -------------------------------------------------------------------- - //

'use strict'

import { extend } from 'lodash-es'
import $ from 'jquery'
import ArtistPicker from './ArtistPicker.jsx'
import ArtistPickerOverlay from '../artist/ArtistPickerOverlay.jsx'
import AudioToVideoAction from '../flux/actions/AudioToVideoAction.js'
import AudioToVideoStep from './AudioToVideoStep.jsx'
import AudioToVideoStore from '../flux/stores/AudioToVideoStore.js'
import AudioWarning from './AudioWarning.jsx'
import BackgroundWarning from './BackgroundWarning.jsx'
import BatchUpload from './BatchUpload.jsx'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import Container from '../Container.jsx'
import FaIcon from '../FaIcon.jsx'
import GlobalSettings from './GlobalSettings.jsx'
import PageTitle from '../router/PageTitle'
import Texts from '../texts/texts.js'
import Tooltip from '../shared/Tooltip.jsx'
import TransitionDurations from 'd2/utils/transition_durations'
import VideoSettings from './VideoSettings.jsx'
import createReactClass from 'create-react-class'
import cx from 'classnames'

const overlayTransitionDurations = TransitionDurations('overlay')

// - -------------------------------------------------------------------- - //

const getState = function () {
  const isAudioComplete = AudioToVideoStore.isAudioComplete()
  const isArtworkComplete = AudioToVideoStore.isArtworkComplete()
  const isBackgroundComplete = AudioToVideoStore.isBackgroundComplete()

  return {

    step: AudioToVideoStore.getStep(),
    buildingErrors: AudioToVideoStore.getBuildingErrors(),
    isSaving: AudioToVideoStore.isSaving(),
    isComplete: isAudioComplete && isArtworkComplete && isBackgroundComplete,

    files: AudioToVideoStore.getAudioFiles(),
    selectedFile: AudioToVideoStore.getSelectedAudioFile(),
    isAudioUploading: AudioToVideoStore.isAudioUploading(),
    isAudioComplete,
    hasValidAudioFiles: AudioToVideoStore.hasValidAudioFiles(),

    artists: AudioToVideoStore.getArtists(),
    isLoadingArtists: AudioToVideoStore.isLoadingArtists(),
    isSavingArtist: AudioToVideoStore.isSavingArtist(),
    newArtistError: AudioToVideoStore.getNewArtistError(),
    selectedArtist: AudioToVideoStore.getSelectedArtist(),

    fonts: AudioToVideoStore.getFonts(),
    globalSettings: AudioToVideoStore.getGlobalSettings(),
    isArtworkUploading: AudioToVideoStore.isArtworkUploading(),
    isArtworkComplete,
    isEyeDropperEnabled: AudioToVideoStore.isEyeDropperEnabled(),
    isBackgroundUploading: AudioToVideoStore.isBackgroundUploading(),
    isBackgroundComplete,
    hasAudioWarning: AudioToVideoStore.hasAudioWarning(),
    hasBackgroundWarning: AudioToVideoStore.hasBackgroundWarning(),

  }
}

// - -------------------------------------------------------------------- - //

export default createReactClass({
  displayName: 'AudioToVideo',

  mixins: [
    AudioToVideoStore.mixin,
    PageTitle('Media Creation Tool'),
  ],

  componentWillMount () {

  },

  getInitialState () {
    return extend({ noOverlay: true }, getState())
  },

  handleBeforeUnload (event) {
    return Texts.a2v.leaveWarn.build
  },

  componentDidMount () {
    $(window).on('beforeunload', this.handleBeforeUnload)

    this.setState({
      noOverlay: false,
    })
  },

  componentWillUnmount () {
    $(window).off('beforeunload', this.handleBeforeUnload)
  },

  storeDidChange () {
    if (AudioToVideoStore.hasBuildingFinished()) {
      $(window).off('beforeunload', this.handleBeforeUnload)

      window.location = '/d2/files/videos/artwork'
    } else {
      this.setState(getState())
    }
  },

  saveAndFinish () {
    if (this.state.isComplete) {
      AudioToVideoAction.saveAndFinish(this.state.selectedArtist, this.state.files, this.state.globalSettings)
    }
  },

  handleContinue () {
    if (this.state.hasValidAudioFiles) {
      if (!this.state.selectedFile) {
        AudioToVideoAction.selectFirstFile()
      }
      if (this.state.step === 1) {
        AudioToVideoAction.showAudioWarning()
      } else if (this.state.step === 2 && this.state.globalSettings.getValue('backgroundType') === 'upload') {
        AudioToVideoAction.showBackgroundWarning()
      } else {
        AudioToVideoAction.saveAndContinue(this.state.step)
      }
    }
  },

  submitBackground () {
    const type = this.state.globalSettings.getValue('backgroundType')
    const backgroundColor = this.state.globalSettings.getValue('backgroundColor')
    const backgroundImage = this.state.globalSettings.getValue('backgroundImage')

    if (type === 'color' && !validColor(backgroundColor)) {
      alert(Texts.a2v.background.font_error)
      return
    } else if (type === 'upload' && (!backgroundImage || !isCompleteBackgroundImage(backgroundImage))) {
      alert(Texts.a2v.background.upload_error)
      return
    }

    this.handleContinue()
  },

  submitFontColor (lastSong) {
    const color = this.state.selectedFile.getValue('font_color')
    const showText = this.state.selectedFile.getValue('show_text')

    if (showText && !validColor(color)) {
      alert(Texts.a2v.video.font_error)
      return
    }

    if (lastSong) {
      this.saveAndFinish()
    } else {
      AudioToVideoAction.nextSong()
    }
  },

  handleSubmit (event) {
    event.preventDefault()
  },

  renderError () {
    let error
    if (this.state.buildingErrors) {
      error = (
        <li>
          <p className='error'>
            { Texts.a2v.build.error }
          </p>
        </li>
      )
    }
    return error
  },

  render () {
    let content

    // Step one - select artist and upload files
    if (this.state.step === 1) {
      const buttonDisabled = this.state.files.length === 0

      const buttonClasses = {
        'btn': true,
        'btn-primary': true,
        'disabled': buttonDisabled,
      }

      let button = (
        <button
          className={cx(buttonClasses)}
          onClick={this.handleContinue}
          type='submit'
        >
          { Texts.a2v.upload.submit }
          { ' ' }
          <FaIcon icon='arrow-right' />
        </button>
      )

      if (buttonDisabled) {
        button = (
          <Tooltip title={Texts.a2v.tooltip.upload}>
            { button }
          </Tooltip>
        )
      }

      content = (
        <div className='step-one'>
          <AudioToVideoStep currentStep={this.state.step} />
          <Container
            heading={Texts.a2v.artist.title}
            icon='file-audio-o'
          >
            <ArtistPicker
              artists={this.state.artists}
              key='artistPicker'
              selectedArtist={this.state.selectedArtist}
            />
            <p className='sortingInfo'>
              { Texts.a2v.sortingInfo }
            </p>
            <BatchUpload
              files={this.state.files}
              isComplete={this.state.isAudioComplete}
              isUploading={this.state.isAudioUploading}
              key='batchUpload'
              selectable={false}
              selectedFile={this.state.selectedFile}
              sortable
            />
          </Container>
          <nav className='secondary-nav buttons-bar'>
            <ul>
              <li>
                { button }
              </li>
            </ul>
            <div className='clear' />
          </nav>
        </div>
      )

    // Step Two - upload files and select background
    } else if (this.state.step === 2) {
      content = (
        <div className='step-two'>
          <AudioToVideoStep currentStep={this.state.step} />
          <Container
            heading={Texts.a2v.upload.title}
            icon='file-audio-o'
          >
            <ArtistPicker
              artists={this.state.artists}
              key='artistPicker'
              selectedArtist={this.state.selectedArtist}
            />
            <BatchUpload
              files={this.state.files}
              isComplete={this.state.isAudioComplete}
              isUploading={this.state.isAudioUploading}
              key='batchUpload'
              selectable={false}
              selectedFile={this.state.selectedFile}
              sortable={false}
            />
          </Container>
          <Container
            className='background-container'
            heading={Texts.a2v.global.title}
            icon='sliders'
          >
            <GlobalSettings
              globalSettings={this.state.globalSettings}
              onAccept={this.submitBackground}
            />
          </Container>
          <nav className='secondary-nav buttons-bar'>
            <ul>
              <li>
                <button
                  className='btn btn-primary'
                  onClick={this.submitBackground}
                  type='submit'
                >
                  { Texts.a2v.background.submit }
                  { ' ' }
                  <FaIcon icon='arrow-right' />
                </button>
              </li>
            </ul>
            <div className='clear' />
          </nav>
        </div>
      )

    // Step Three - configure each file
    } else if (this.state.step === 3) {
      const buttons = []

      // First file
      if (this.state.selectedFile && this.state.selectedFile === this.state.files[0]) {
        buttons.push(
          <li key='1'>
            <button
              className='btn btn-link'
              onClick={AudioToVideoAction.globalSettings}
              type='button'
            >
              { Texts.a2v.global.goBackTo }
            </button>
          </li>
        )

      // Not first file
      } else {
        buttons.push(
          <li key='2'>
            <button
              className='btn btn-link'
              onClick={AudioToVideoAction.previousSong}
              type='button'
            >
              <FaIcon icon='arrow-left' />
              { ' ' }
              { Texts.a2v.save.previous }
            </button>
          </li>
        )
      }

      // Last file
      if (this.state.selectedFile && this.state.selectedFile === this.state.files[this.state.files.length - 1]) {
        const buttonFinishDisabled = this.state.isSaving || !this.state.isComplete

        const buttonFinishClasses = cx({
          'btn': true,
          'btn-danger': true,
          'disabled': buttonFinishDisabled,
        })

        let buttonTip
        if (this.state.isSaving) {
          buttonTip = Texts.a2v.tooltip.saving
        } else if (!this.state.isComplete) {
          buttonTip = Texts.a2v.tooltip.incomplete
        }

        let button = (
          <button
            className={buttonFinishClasses}
            onClick={() => this.submitFontColor(true)}
            type='submit'
          >
            { Texts.a2v.save.finish }
            { ' ' }
            <FaIcon icon='arrow-right' />
          </button>
        )

        if (buttonTip) {
          button = (
            <Tooltip title={Texts.a2v.tooltip.upload}>
              { button }
            </Tooltip>
          )
        }

        buttons.unshift(
          <li key='3'>
            { button }
          </li>
        )

      // Not last file
      } else {
        buttons.unshift(
          <li key='4'>
            <button
              className='btn btn-primary'
              onClick={() => this.submitFontColor(false)}
              type='submit'
            >
              { Texts.a2v.save.continue }
              { ' ' }
              <FaIcon icon='arrow-right' />
            </button>
          </li>
        )
      }

      let heading = Texts.a2v.video.title
      if (this.state.selectedFile) {
        heading += ` for "${this.state.selectedFile.getValue('filename')}"`
      }

      content = (
        <div className='step-three'>
          <AudioToVideoStep currentStep={this.state.step} />
          <Container
            heading={Texts.a2v.artist.title}
            icon='file-audio-o'
          >
            <ArtistPicker
              artists={this.state.artists}
              key='artistPicker'
              selectedArtist={this.state.selectedArtist}
            />
            <BatchUpload
              files={this.state.files}
              isComplete={this.state.isAudioComplete}
              isUploading={this.state.isAudioUploading}
              key='batchUpload'
              selectable
              selectedFile={this.state.selectedFile}
              sortable={false}
            />
          </Container>
          <Container
            className='file-settings-container'
            heading={heading}
            icon='sliders'
          >
            <VideoSettings
              fonts={this.state.fonts}
              globalSettings={this.state.globalSettings}
              isEyeDropperEnabled={this.state.isEyeDropperEnabled}
              selectedFile={this.state.selectedFile}
            />
          </Container>
          <nav className='secondary-nav buttons-bar'>
            <ul>
              { buttons }
              { this.renderError() }
            </ul>
            <div className='clear' />
          </nav>
        </div>
      )
    }

    let overlay = null

    if (this.state.noOverlay) {
      // Skip overlay

    } else if (this.state.artists && !this.state.selectedArtist) {
      overlay = (
        <ArtistPickerOverlay
          artists={this.state.artists}
          errors={this.state.newArtistError}
          isLoading={this.state.isLoadingArtists}
          isSaving={this.state.isSavingArtist}
          onAddArtist={AudioToVideoAction.addArtist}
          onSelectArtist={AudioToVideoAction.selectArtist}
        />
      )
    } else if (this.state.hasAudioWarning) {
      overlay = <AudioWarning />
    } else if (this.state.hasBackgroundWarning) {
      overlay = <BackgroundWarning globalSettings={this.state.globalSettings} />
    }

    return (
      <div className='audio-to-video'>
        <CSSTransitionGroup
          transitionName='overlay'
          {...overlayTransitionDurations}
        >
          { overlay }
        </CSSTransitionGroup>

        <form onSubmit={this.handleSubmit}>
          { content }
        </form>
      </div>
    )
  },

})

const validColor = function (color) {
  return !!(color && color.match(/^#([A-F0-9]{3}|[A-F0-9]{6})$/i))
}

const isCompleteBackgroundImage = function (backgroundImage) {
  const file = backgroundImage.getValue('file')
  return !!(file && !file.hasErrors())
}

// - -------------------------------------------------------------------- - //
