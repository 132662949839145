import { Component } from 'react'
import { register } from 'd2/intermediate/registry'
import PropTypes from 'prop-types'

class TimeAgo extends Component {
  static propTypes = {
    updateEvery: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
    ]),
  }

  static defaultProps = {
    updateEvery: 1000, // MS. `0` or `false` to disable updating.
  }

  componentDidMount () {
    if (this.props.updateEvery) {
      this._interval = setInterval(this.updateTimeAgo, this.props.updateEvery)
    }
  }

  componentWillUnmount () {
    clearInterval(this._interval)
  }

  shouldComponentUpdate (nextProps, nextState) {
    return nextState.timeAgo !== this.state.timeAgo
  }

  updateTimeAgo = () => {
    this.setState({
      timeAgo: this.getTimeAgo(),
    })
  }

  getPassedSeconds = () => {
    const now = new Date()
    return parseInt((now.getTime() - this.props.datetime) / 1000)
  }

  getTimeAgo = () => {
    const passedSeconds = this.getPassedSeconds()
    const minute = 60
    const hour = 60 * 60
    const day = 24 * 60 * 60

    switch (true) {
    case passedSeconds < 2 * minute:
      return 'Just now'
    case passedSeconds < hour:
      return `${parseInt(passedSeconds / minute)} minutes ago`
    case passedSeconds < 2 * hour:
      return 'An hour ago'
    case passedSeconds < day:
      return `${parseInt(passedSeconds / hour)} hours ago`
    case passedSeconds < 2 * day:
      return 'Yesterday'
    case passedSeconds < 8 * day:
      return `${parseInt(passedSeconds / day)} days ago`
    default:
      return this.props.datetime.toLocaleDateString()
    }
  }

  state = {
    timeAgo: this.getTimeAgo(),
  }

  render () {
    const title = this.props.datetime.toLocaleString()

    return (
      <span title={title}>
        { this.state.timeAgo }
      </span>
    )
  }
}

export default register('TimeAgo', TimeAgo)
