import $ from 'jquery'
import ready from '../utils/ready'

ready(() => {
  const $searchListItem = $('.secondary-nav-search')
  if ($searchListItem.length > 0) {
    $searchListItem.each(function () {
      const $li = $(this)

      $li.find('.form-input').on('focus', () => {
        $li.addClass('active')
      })

      $li.find('.form-input').on('blur', () => {
        $li.removeClass('active')
      })
    })
  }
})
