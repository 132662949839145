// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import { register } from 'd2/intermediate/registry'
import $ from 'jquery'
import PropTypes from 'prop-types'
import Texts from '../texts/texts.js'

// - -------------------------------------------------------------------- - //

class MetadataIsrc extends Component {
  static displayName = 'MetadataIsrc'

  static propTypes = {
    value: PropTypes.string, // Not required, because we can generate one if empty.
    attribute: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {}

  state = {
    isrcValue: this.props.value,
  }

  handleClickGenerate = (event) => {
    event.preventDefault()
    const self = this
    const $request = $.ajax({
      url: '/api/metadatas/generate_isrc',
      type: 'GET',
      data: {},
    })
    $request.done((data) => {
      self.setState({ isrcValue: data.isrc })
    })
  }

  handleIsrcChange = (event) => {
    this.setState({ isrcValue: event.target.value })
  }

  renderGenerate = () => (
    <p className='generate'>
      { `${Texts.setup.metadata.generate[this.props.attribute]} ` }
      Click
      { ' ' }
      <a
        className='isrc-generate'
        onClick={this.handleClickGenerate}
      >
        here
      </a>
      { ' ' }
      to generate one.
    </p>
  )

  render () {
    const label = this.props.attribute === 'video_isrc' ? 'Video ISRC Code' : 'Audio ISRC Code'
    const attr = this.props.attribute

    return (
      <div className='form-field'>
        <label
          className='form-label'
          htmlFor={`metadata_${attr}`}
        >
          { label }
        </label>
        <input
          className='form-input'
          disabled={this.props.disabled}
          id={`metadata_${attr}`}
          maxLength={12}
          name={`metadata[${attr}]`}
          onChange={this.handleIsrcChange}
          ref='input'
          type='text'
          value={this.state.isrcValue || ''}
        />

        { this.props.disabled
          ? <p className='empty'>
            { ' ' }
          </p>
          : null }

        { this.state.isrcValue ? null : this.renderGenerate() }
      </div>
    )
  }
}

export default register('MetadataIsrc', MetadataIsrc)

// - -------------------------------------------------------------------- - //
