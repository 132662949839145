import 'bootstrap/js/tooltip'
import $ from 'jquery'

const recordsTable = function recordsTable ($table) {
  $table.on('click', 'a.delete', (event) => {
    const $row = $(event.target).closest('tr, .row')
    const title = $row.data('title')

    event.preventDefault()

    if (!window.confirm(`Do you want to delete ${title}?`)) {
      return false
    }

    const deletePromise = $.ajax({
      type: 'POST',
      url: event.target.href,
      data: {
        authenticity_token: $('meta[name=csrf-token]').attr('content'),
        _method: 'DELETE',
      },
    })

    deletePromise.done(() => {
      const $tooltipElements = $row.find('[data-original-title]')
      $tooltipElements.tooltip('hide')
      $tooltipElements.tooltip('destroy')
      $row.remove()
    })

    deletePromise.fail(() => {
      alert(`There was an error deleting ${title}, try again later.`)
    })
  })
}

export default recordsTable
