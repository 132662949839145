import $ from 'jquery'
import Clipboard from 'clipboard'

const Copiable = function ($el, options) {
  const self = this

  self.$el = $el
  self.$fallback = $(self.$el.data('fallback-target'))
  self.analytics = options.analytics
  self.options = options
  self.getCopyText = function (trigger) {
    const copyText = trigger.getAttribute('data-clipboard-text')
    const content = trigger.innerHTML.trim()

    return copyText || content
  }

  self.client = new Clipboard(self.$el[0], {
    text (trigger) {
      const copyText = self.getCopyText(trigger)

      self.analytics && self.analytics.track('Copy', {
        'Copy Text': copyText,
      })

      return copyText
    },
  })

  self.bindListeners()
}

Copiable.prototype.bindListeners = function () {
  const self = this

  self.client.on('error', () => {
    self.fallback()
  })

  self.client.on('success', () => {
    let message = self.$el.data('success-alert')
    message = message || 'Copied to clipboard!'
    alert(message)
  })
}

Copiable.prototype.fallback = function () {
  const self = this

  self.$el.hide()
  self.$fallback.show()
}

Copiable.prototype.unbindListeners = function () {
  this.client.off()
  this.client.destroy()
}

export default Copiable
