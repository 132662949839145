import { lazy, memo } from 'react'
import type { OwnProps } from 'd2/components/StorageSpaceBanner/types'

export type Props = OwnProps & {
  standalone?: boolean
}

const Default = lazy<React$ComponentType<OwnProps>>(() => import('d2/components/StorageSpaceBanner'))

const Standalone = lazy<React$ComponentType<OwnProps>>(() => import('d2/components/StorageSpaceBanner/standalone'))

const StorageSpaceBanner = memo<Props>(({ d1, standalone }) => (
  standalone ? <Standalone d1={d1} /> : <Default d1={d1} />
))

export default StorageSpaceBanner
