// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import ArtistCollection from '../flux/collections/ArtistCollection.js'
import ArtistPickerContainer from './ArtistPickerContainer.jsx'
import Overlay from '../../components/Overlay.jsx'
import PropTypes from 'prop-types'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'ArtistPickerOverlay'

  static propTypes = {
    artists: PropTypes.instanceOf(ArtistCollection).isRequired,
    errors: PropTypes.any,
    isLoading: PropTypes.bool.isRequired,
    onCancel: PropTypes.func,
    onAddArtist: PropTypes.func.isRequired,
    onSelectArtist: PropTypes.func.isRequired,
  }

  render () {
    return (
      <Overlay
        key='artist-overlay'
        onClickAway={this.props.onCancel}
      >
        <ArtistPickerContainer
          {...this.props}
          onClickAway={this.props.onCancel}
        />
      </Overlay>
    )
  }
}

// - -------------------------------------------------------------------- - //
