import $ from 'jquery'
import ready from '../utils/ready'
import throttler from '../utils/throttler.js'
const $window = $(window)
const $document = $(document)

let $body
let $footer

ready(() => {
  $body = $('body')
  $footer = $('#main-footer')
  _toggleScrolledClasses()
})
let toggleScrolledClasses

const _toggleScrolledClasses = function () {
  if (!$body || !$footer) {
    return
  }
  const scrollTop = $window.scrollTop()
  const documentHeight = $document.height()
  const footerHeight = $footer.height()
  const windowHeight = Math.max(window.innerHeight || 0, $window.height())
  const scrolledToFooter = scrollTop + windowHeight >= documentHeight - footerHeight
  $body.toggleClass('scrolled-to-footer', scrolledToFooter)
}

const bindListeners = function () {
  $window.scroll(() => {
    toggleScrolledClasses()
  })

  $window.resize(() => {
    toggleScrolledClasses()
  })
}

const setup = function () {
  toggleScrolledClasses = throttler(_toggleScrolledClasses, 5)

  bindListeners()
  _toggleScrolledClasses()
}

setup()
