// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import AudioFileCollection from '../flux/collections/AudioFileCollection.js'
import AudioFileModel from '../flux/models/AudioFileModel.js'
import AudioToVideoAction from '../flux/actions/AudioToVideoAction.js'
import BatchUploadItem from './BatchUploadItem.jsx'
import PropTypes from 'prop-types'
import Sortable from 'react-sortablejs'
import Texts from '../texts/texts.js'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'BatchUploadList'

  static propTypes = {
    files: PropTypes.instanceOf(AudioFileCollection).isRequired,
    selectedFile: PropTypes.instanceOf(AudioFileModel),
    selectable: PropTypes.bool.isRequired,
    sortable: PropTypes.bool.isRequired,
  }

  onSort = (order, sortable, evt) => {
    AudioToVideoAction.reorder(order.map((e) => +e))
  }

  renderFile = (file, index) => {
    let isSelected = false
    if (this.props.selectedFile) {
      isSelected = file.getValue('filename') === this.props.selectedFile.getValue('filename')
    }
    return (
      <BatchUploadItem
        file={file}
        isSelected={isSelected}
        key={index}
        orderId={index}
        selectable={this.props.selectable}
        sortable={this.props.sortable}
      />
    )
  }

  renderFilesList = () => {
    if (this.props.sortable) {
      return (
        <Sortable
          className='list-body'
          id='sortable-list'
          onChange={this.onSort}
          tag='ul'
        >
          { this.props.files.map(this.renderFile) }
        </Sortable>
      )
    }

    return (
      <ul>
        { this.props.files.map(this.renderFile) }
      </ul>
    )
  }

  render = () => (
    <section className='batch-upload-list list-primary'>
      <header>
        <div className='batch-upload-filename'>
          { Texts.a2v.upload.filename }
        </div>
        <div className='batch-upload-progress'>
          { Texts.a2v.upload.progress }
        </div>
      </header>
      { this.renderFilesList() }
    </section>
  )
}

// - -------------------------------------------------------------------- - //
