// - -------------------------------------------------------------------- - //

'use strict'

import NoticesStore from '../flux/stores/NoticesStore.js'
import createReactClass from 'create-react-class'

// - -------------------------------------------------------------------- - //

export default createReactClass({
  displayName: 'Notices',

  mixins: [
    NoticesStore.mixin,
  ],

  getInitialState () {
    return {
      notices: NoticesStore.getNotices(),
    }
  },

  storeDidChange () {
    this.setState({
      notices: NoticesStore.getNotices(),
    })
  },

  renderNotice (notice) {
    return (
      <ul
        className={`notices ${notice.type}-notices`}
        key={notice.id}
      >
        <li>
          { notice.text }
        </li>
      </ul>
    )
  },

  render () {
    return (
      <div className='notices-wrapper'>
        { this.state.notices.map(this.renderNotice) }
      </div>
    )
  },

})

// - -------------------------------------------------------------------- - //
