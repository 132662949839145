import { lazy, memo } from 'react'
import type { Type } from 'd2/partials/InstagramBusinessButton/types'

export type Props = {
  standalone?: boolean
}

const Default: Type = lazy<React$ComponentType>(
  () => import('d2/partials/InstagramBusinessButton'),
)

const Standalone = lazy<React$ComponentType>(
  () => import('d2/partials/InstagramBusinessButton/standalone'),
)

export default memo<Props>(({ standalone }) => (
  standalone ? <Standalone /> : <Default />
))
