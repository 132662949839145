// - -------------------------------------------------------------------- - //

'use strict'

import Model from './Model.js'

// - -------------------------------------------------------------------- - //

export default Model.createModel({

  properties: [
    'artwork',
    'backgroundType',
    'backgroundColor',
    'backgroundImage',
    'backgroundTemplate',
  ],

})

// - -------------------------------------------------------------------- - //
