// - -------------------------------------------------------------------- - //

'use strict'

import Model from './Model.js'

// - -------------------------------------------------------------------- - //

export default Model.createModel({

  properties: [
    'id',
    'filename',
    'uploaded',
    'length',
    'url',
    'file_upload',
    'file_upload_id',
  ],

  isFresh () {
    return !this.hasValue('id')
  },

  isUploading () {
    const total = this.getValue('length')
    const uploaded = this.getValue('uploaded')
    return uploaded > 0 && uploaded < total
  },

  isComplete () {
    const total = this.getValue('length')
    const uploaded = this.getValue('uploaded')
    return total > 0 && uploaded === total
  },

  getProgress () {
    const total = this.getValue('length')
    const uploaded = this.getValue('uploaded')
    return (uploaded / total) * 100
  },

})

// - -------------------------------------------------------------------- - //
