// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import $ from 'jquery'
import AudioToVideoAction from '../flux/actions/AudioToVideoAction.js'
import FaIcon from '../FaIcon.jsx'
import PropTypes from 'prop-types'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'EyeDropper'

  static propTypes = {
    onChange: PropTypes.func.isRequired,
  }

  state = {
    color: undefined,
    enabled: false,
  }

  componentWillMount () {
    this.$doc = $(document)
  }

  componentWillUnmount () {
    this.$doc.off('mousedown', this.handleMouseDown)
    this.$doc.off('mousemove', this.handleMouseMove)
    this.$doc.off('mouseup', this.handleMouseUp)
    this.removeHover()
    this.destroyCanvas()
  }

  destroyCanvas = () => {
    if (this._canvas) {
      $(this._canvas).remove()
      this._canvas = undefined
    }
  }

  drawCanvas = () => {
    const img = this._hoveringImage
    const ratio = parseFloat($(img).data('scale') || 1)
    const width = img.width * ratio
    const height = img.height * ratio
    const canvas = document.createElement('CANVAS')
    document.body.appendChild(canvas)
    canvas.width = width
    canvas.height = height
    canvas.style.position = 'absolute'
    canvas.style.top = `-${height}px`
    canvas.style.left = `-${width}px`
    canvas.getContext('2d').drawImage(img, 0, 0, width, height)
    this._canvas = canvas
  }

  readPixel = (event) => {
    const $img = $(this._hoveringImage)
    const offset = $img.offset()

    const offsetX = event.pageX - offset.left
    const offsetY = event.pageY - offset.top

    const pixel = !!this._canvas && this._canvas.getContext('2d').getImageData(offsetX, offsetY, 1, 1)

    if (pixel) {
      let r = pixel.data[0].toString(16)
      let g = pixel.data[1].toString(16)
      let b = pixel.data[2].toString(16)
      if (r.length === 1) r = `0${r}`
      if (g.length === 1) g = `0${g}`
      if (b.length === 1) b = `0${b}`
      const color = `#${r}${g}${b}`.toUpperCase()
      this.setState({ color })
      if (this.props.onChange) {
        this.props.onChange(color)
      }
    }
  }

  hoverNewElement = (target) => {
    if (target === this._hoveringImage) {
      return
    }

    if (!this.canColorPickElement(target)) {
      return
    }

    this.removeHover()

    this._hoveringImage = target

    $(this._hoveringImage).css('cursor', 'crosshair')

    this.destroyCanvas()
    this.drawCanvas()
  }

  handleMouseMove = (event) => {
    if (!this.state.enabled) return

    this.hoverNewElement(event.target)

    if (this._mouseIsDown) {
      this.readPixel(event)
    }
  }

  handleMouseDown = (event) => {
    if (!this.canColorPickElement(event.target)) {
      return
    }

    this._mouseIsDown = true
    this.hoverNewElement(event.target)
    this.readPixel(event)
    this.$doc.one('mouseup', this.handleMouseUp)

    AudioToVideoAction.toggleEyeDropper(true)
  }

  handleMouseUp = () => {
    this._mouseIsDown = false
    this._hoveringImage = undefined
    AudioToVideoAction.toggleEyeDropper(false)
  }

  handleClick = (event) => {
    this.mute(event)

    if (this.state.enabled) {
      this.$doc.off('mousedown', this.handleMouseDown)
      this.$doc.off('mousemove', this.handleMouseMove)
      this.removeHover()
    } else {
      this.$doc.on('mousedown', this.handleMouseDown)
      this.$doc.on('mousemove', this.handleMouseMove)
    }

    this.setState({ enabled: !this.state.enabled })
  }

  canColorPickElement = (element) => element.nodeName === 'IMG'

  removeHover = () => {
    if (this._hoveringImage) {
      $(this._hoveringImage).css('cursor', '')
    }
    this._hoveringImage = undefined
  }

  mute = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  render () {
    return (
      <div className={`eye-dropper${this.state.enabled ? ' enabled' : ''}`}>
        <FaIcon
          icon='eyedropper'
          onClick={this.handleClick}
          onMouseDown={this.mute}
        />
      </div>
    )
  }
}

// - -------------------------------------------------------------------- - //
