import { lazy, memo } from 'react'

export type Props = {
  standalone?: boolean
}

const Default = lazy<React$ComponentType>(() => import('d2/components/StoragePlanPicker'))

const Standalone = lazy<React$ComponentType>(
  () => import('d2/components/StoragePlanPicker/standalone'),
)

const StoragePlanPicker = memo<Props>(({ standalone }) => (
  standalone ? <Standalone /> : <Default />
))

export default StoragePlanPicker
