// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import AudioToVideoAction from '../flux/actions/AudioToVideoAction.js'
import PropTypes from 'prop-types'
import Texts from '../texts/texts.js'
import classSet from 'classnames'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'AudioToVideoStep'

  static propTypes = {
    steps: PropTypes.arrayOf(PropTypes.object),
    currentStep: PropTypes.number,
  }

  static defaultProps = {
    steps: [
      { click: false, label: Texts.a2v.steps.one },
      { click: true, label: Texts.a2v.steps.two },
      { click: false, label: Texts.a2v.steps.three },
      { click: false, label: Texts.a2v.steps.four },
    ],
  }

  handleClick = (counter, index) => {
    if (this.props.steps[index].click && this.props.currentStep > counter) {
      AudioToVideoAction.changeStep(counter)
    }
  }

  renderStep = (step, index) => {
    const counter = index + 1
    const currentStep = this.props.currentStep > this.props.steps.length
      ? this.props.steps.length
      : this.props.currentStep
    const classes = classSet({
      done: counter < this.props.currentStep,
      active: counter === currentStep,
    })
    return (
      <li
        className={classes}
        key={index}
        onClick={() => this.handleClick(counter, index)}
      >
        <span className='counter-wrapper'>
          <span className='counter'>
            { counter }
          </span>
          .
          <span className='step-label'>
            { step.label }
          </span>
        </span>
      </li>
    )
  }

  render () {
    return (
      <div className='audio-to-video-step'>
        <ol className='progress-tracker'>
          { this.props.steps.map(this.renderStep) }
        </ol>
      </div>
    )
  }
}

// - -------------------------------------------------------------------- - //
