import { lazy, memo } from 'react'

export type Props = {
  standalone?: boolean
}

const Default = lazy<React$ComponentType>(() => import('d2/components/StorageTierSidebar'))

const Standalone = lazy<React$ComponentType>(() => import('d2/components/StorageTierSidebar/standalone'))

export default memo<Props>(({ standalone }) => (
  standalone ? <Standalone /> : <Default />
))
