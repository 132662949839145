import $ from 'jquery'
const helpers = {}

helpers.scroll_to = function ($el, duration, options) {
  let lastOffsetTop

  const $body = $('html, body')

  if (duration === undefined) {
    duration = 400
  }
  if (options === undefined) {
    options = {}
  }
  options.padding || (options.padding = 0)
  options.onComplete || (options.onComplete = function () {})

  const halfDuration = duration / 2

  const reAnimate = function (duration) {
    return $body.stop(true, false).animate({
      scrollTop: lastOffsetTop,
    }, {
      progress: onProgress,
      complete: options.onComplete,
      duration: duration < halfDuration ? halfDuration : duration,
    })
  }

  const onProgress = function (animation, progress, remainingMS) {
    const offsetDiff = lastOffsetTop - calculateOffsetTop()
    if ((offsetDiff > 5 || offsetDiff < -5) && $($el).length > 0) {
      reAnimate(remainingMS)
    }
  }

  const calculateOffsetTop = function () {
    const offset = $($el).offset().top - options.padding
    lastOffsetTop = offset > 0 ? offset : 0
    return lastOffsetTop
  }

  calculateOffsetTop()
  return reAnimate(duration)
}

helpers.wait = function (ms, fn) {
  return setTimeout(fn, ms)
}

export default helpers
