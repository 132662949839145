import Modal from '../components/Modal'
import Model from '../utils/demplate/Model'
import PopUp from '../components/PopUp'
import generateString from '../utils/generateString.js'

// @param $form [jQuery] - the form which contains the upload button
// @param options [Object] - the options
// @option modalID [String] - DOM element ID of the modal
// @option modalTemplateID [String] - DOM element ID of the template for the modal
const PopupUploader = function ($form, options) {
  if (!options || !options.modalID || !options.modalTemplateID) {
    throw new Error('invalid options')
  }

  this.$form = $form

  this.model = new Model({
    uploadStarted: false,
    uploadCompleted: false,
    uploadClosed: false,
    uploadErrored: false,
  })

  this.modal = new Modal(`#${options.modalID}`, {
    closeable: false,
    templateSelector: options.modalTemplateID,
    demplateModel: this.model,
  })

  this.demplate = this.modal.demplate

  this.bindModalListeners()

  this.bindWindowListeners()
}

PopupUploader.prototype.open = function (uploaderURL) {
  const self = this

  self.popup = new PopUp(uploaderURL, {
    windowName: generateString(8),
    width: 400,
    height: 520,
  })

  self.popup.on('open', () => {
    self.model.set({
      uploadStarted: false,
      uploadCompleted: false,
      uploadClosed: false,
      uploadErrored: false,
    })
  })

  self.popup.on('close', () => {
    self.model.set({
      uploadClosed: true,
      uploadErrored: false,
    })
  })

  self.popup.open()
  self.modal.open()
}

PopupUploader.prototype.bindWindowListeners = function () {
  const self = this

  window.delegator.on('uploadStart', () => {
    self.model.set({
      uploadStarted: true,
      uploadCompleted: false,
      uploadErrored: false,
    })
  })

  window.delegator.on('uploadFinish', () => {
    self.model.set('uploadCompleted', true)
  })

  window.delegator.on('uploadError', () => {
    self.model.set('uploadErrored', true)
  })
}

PopupUploader.prototype.bindModalListeners = function () {
  const self = this

  self.demplate.bind('.focus-uploader', 'click', (event) => {
    event.preventDefault()
    self.popup.focusOrOpen()
  })

  self.demplate.bind('.cancel', 'click', (event) => {
    event.preventDefault()
    self.popup.close()
  })

  self.demplate.bind('.close', 'click', (event) => {
    event.preventDefault()
    self.close()
  })

  self.demplate.bind('.open-uploader', 'click', (event) => {
    event.preventDefault()
    self.popup.open()
  })

  self.demplate.bind('.continue', 'click', (event) => {
    event.preventDefault()
    if (self.isSubmittable()) {
      self.$form.get(0).submit()
    } else {
      alert('Oops! Please begin the upload before continuing.')
      self.popup.focusOrOpen()
    }
  })
}

PopupUploader.prototype.close = function () {
  this.popup.close()
  this.modal.close(true)

  this.popup.off('open').off('close')
}

PopupUploader.prototype.isSubmittable = function () {
  return this.model.get('uploadCompleted') || (this.model.get('uploadStarted') && !this.model.get('uploadClosed'))
}

export default PopupUploader
