// - -------------------------------------------------------------------- - //

'use strict'

import Model from './Model.js'

// - -------------------------------------------------------------------- - //

export default Model.createModel({

  properties: [
    'id',
    'file',
    'source',
    'width',
    'height',
    'file_upload',
    'file_upload_id',
  ],

  isFresh () {
    return !this.hasValue('id')
  },

})

// - -------------------------------------------------------------------- - //
