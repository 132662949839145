// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import { PhotoshopPicker } from 'react-color'
import AudioToVideoAction from '../flux/actions/AudioToVideoAction.js'
import PropTypes from 'prop-types'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'BackgroundColor'

  static propTypes = {
    onAccept: PropTypes.func,
    onCancel: PropTypes.func,
    backgroundColor: PropTypes.string,
  }

  handleChange = (color) => {
    if (color) {
      if (color.hex) {
        color = color.hex
      }
      AudioToVideoAction.setBackgroundColor(color)
    }
  }

  render () {
    return (
      <div className='background-settings-color'>
        <div className='photoshop-picker-wrapper'>
          <PhotoshopPicker
            color={this.props.backgroundColor || '#FFFFFF'}
            header='Choose Background Color'
            onAccept={this.props.onAccept}
            onCancel={this.props.onCancel}
            onChange={this.handleChange}
          />
        </div>
      </div>
    )
  }
}

// - -------------------------------------------------------------------- - //
