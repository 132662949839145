import { Component, createElement } from 'react'
import { reduce } from 'lodash-es'
import $ from 'jquery'
import AutoFitText from '../components/shared/AutoFitText.jsx'
import PropTypes from 'prop-types'
import ReactMounter from '../utils/ReactMounter'
import changeCaseKeys from 'change-case-keys'
import ready from '../utils/ready'

ready(() => {
  const getReactPropsFromElement = function (el) {
    const props = reduce(el.attributes, (attributes, item) => {
      item.specified && (attributes[item.name] = item.value)
      return attributes
    }, {})

    if (props['class']) {
      props.className = props['class']
      delete props['class']
    }

    if (props['for']) {
      props.htmlFor = props['for']
      delete props['for']
    }

    // TODO: React does not support style string. Not worth solving.
    delete props['style']

    return changeCaseKeys(props, 'camelize', 1)
  }

  // https://www.npmjs.com/package/react-fittext
  // https://github.com/davatron5000/FitText.js

  class FitText extends Component {
    static propTypes = {
      children: PropTypes.string.isRequired,
      fontFamily: PropTypes.string,
      verticalCenter: PropTypes.bool,
    }

    render () {
      const $child = $(this.props.children)
      if ($child.prop('tagName')) {
        const topLevelChildTagName = $child.prop('tagName').toLowerCase()
        const childContents = $child.html()
        const props = Object.assign(
          {},
          getReactPropsFromElement($child[0]),
          {
            dangerouslySetInnerHTML: { __html: childContents },
          },
        )

        return (
          <AutoFitText
            fontFamily={this.props.fontFamily}
            verticalCenter={this.props.verticalCenter}
          >
            { createElement(topLevelChildTagName, props) }
          </AutoFitText>
        )
      }
    }
  }

  // .fit-text element must have width set.
  // TODO: use react_component view helper.
  $('.fit-text').each(function () {
    const $this = $(this)
    const fontFamily = $this.data('font-family') || undefined
    const verticalCenter = $this.data('vertical-center') || false

    const content = (
      <FitText
        fontFamily={fontFamily}
        verticalCenter={verticalCenter}
      >
        { this.innerHTML }
      </FitText>
    )

    ReactMounter.mount(content, this)
  })
})
