export default function (length) {
  const from = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let text = ''
  let i

  for (i = 0; i < length; i += 1) {
    text += from.charAt(Math.floor(Math.random() * from.length))
  }

  return text
}
