// - -------------------------------------------------------------------- - //

'use strict'

// - -------------------------------------------------------------------- - //

const appBrandName = window.app_brand_name

// - -------------------------------------------------------------------- - //

const texts = {

  importYoutube: {
    title: 'Select videos to import from YouTube',
    loadingReasons: [
      'Authorizing YouTube account...',
      'Scanning YouTube channel for videos...',
      'Fetching YouTube video information...',
      'Parsing YouTube video information...',
      'Still loading... You must have a ton of videos!',
      'Still loading... This could take a few minutes...',
    ],

    error: 'An error occurred while loading your YouTube videos. Please try again in a little while.',
    channel: (name) => `${name}'s Channel`,
    submit: (n, isImporting) => {
      const ing = isImporting ? 'ing' : ''
      return n === 0 ? `Import${ing} Videos` : `Import${ing} ${n} Video${n === 1 ? '' : 's'}`
    },
    cancel: 'Go back',
    noVideos: 'No videos found. Choose another YouTube channel.',
    noVideosRemaining: 'Already imported all the videos from this YouTube channel.',
    alreadyImportedSomeVideos: `Note: Some videos are hidden because you imported them to ${appBrandName} already.`,
    willRedirectToYoutube: 'You are being redirected to YouTube. If you\'re not redirected in 5 seconds, click the link below.',
    manuallyRedirectToYoutube: 'Click here to connect your YouTube account.',

    previewYouTube: {
      heading: 'YouTube Preview',
    },

    success: {
      title: 'Success!',
      message: `Your videos are now importing into your ${appBrandName} Files.`,
      contentID: 'Do you earn money when other people upload your stuff to YouTube? Set up Content ID in minutes. Start by clicking the button below.',
      continue: 'Content ID My Files',
      skip: 'I don\'t want more money. Take me to my videos.',
    },
  },

  fileDetails: {
    metadata: {
      publisher: 'Publisher',
      composerPlaceholder: 'Enter Name of Writer',
      copyright_name: 'Copyright Name',
      enter_publisher: 'Enter the Publisher name:',
      add_composer: 'Add Another Composer',
      remove_composer: 'Remove Composer',
      composer_performance_rights_organization: 'Performance Rights Organization',
    },
  },

  chart: {
    admin: 'Earnings Analytics',
    monetize: 'Monetization Analytics',
    modes: [ 'Monetization', 'Distribution' ],
    results: 'Results',
    filter: 'Filter',
    view: 'View',
    slice: 'slice by',
    noSlice: 'no slice',
    start: 'Start',
    finish: 'Finish',
    addFilter: 'Click to add a filter',
    dateFormat: 'MM/dd/yyyy',
    monthFormat: 'MMMM/yyyy',
    dateSubmit: 'OK',
    legend: {
      complete_data: 'Complete Data - We have received all final statements from our partners. These numbers are locked in and will not change.',
      incomplete_data_first: 'Incomplete Data - We are waiting on the final statements from our partners. Click ',
      incomplete_data_second: ' to see the current statement processing. Final numbers will not be reflected until all statements are processed.',
    },
    events: {
      'client_revenue_cents': 'Estimated Revenue',
      'site_revenue_cents': 'Site Revenue',
      'total_revenue_cents': 'Estimated Revenue',
      'total_streams': 'Total Streams',
      'video_impressions': 'Impressions',
      'video_plays': 'Plays',
      'video_progress': 'Progress',
    },
    null: 'Not specified',
    properties: {
      'geocountries': 'Country',
      'assets': 'Asset',
      'artists': 'Artist',
      'groups': 'Group',
      'session.user_agent.mobile': 'Mobile',
      'session.user_agent.browser': 'Browser',
      'session.user_agent.platform': 'Platform',
      'session.geo_information.country': 'Country',
      'video.metadata.song_name': 'Song name',
      'video.destination.source': 'Source',
    },
    values: {
      'session.user_agent.mobile': {
        true: 'Mobile',
        false: 'Not mobile',
      },
      'video.destination.source': {
        '-': 'Not specified',
      },
    },
  },

  destination: {
    title: 'Destination',
    add: 'Add Destination',
    remove: 'Remove',
    save: 'Save',
    url: 'URL',
  },

  a2v: {

    tooltip: {
      saving: 'Please wait while your files are being processed.',
      incomplete: 'You cannot continue until all files have successfully uploaded.',
      upload: 'You have to start uploading audio files first.',

      submit: {
        incomplete: 'All files must be "Complete". Click on any "Incomplete" files above.',
        ready: 'Click here when you\'re ready!',
      },
    },
    sortingInfo: 'If you are uploading an album, please check that they are listed in the order you want them to appear. If necessary, click and drag the icon next to the song name to reorder.',
    leaveWarn: {
      build: 'If you leave now, you won\'t be able to resume here later.',
      monetize: 'If you leave now, you\'ll have to submit each video individually. You won\'t be able to resume this batch wizard later.',
    },

    steps: {
      one: 'Upload Audio Files',
      two: 'Set Background',
      three: 'Add Flavor',
      four: 'Publish',
    },

    artist: {
      title: 'Upload the Audio Files',
      add: 'Add Artist',
      name: 'Artist Name',
      create: 'Add artist',
      createFirst: 'Enter artist name',
      duplicate: 'You already have an artist with that name.',
      empty: 'Artist name can\'t be empty.',
      label: 'Choose an Artist',
      existing: 'Artist',
      or: 'or',
      submit: 'Submit',
      saving: 'Saving...',
      cancel: 'Cancel',
      choose_youtube_channel: 'Choose a YouTube channel',
    },

    upload: {
      title: 'Uploaded Audio Files',
      artist: 'Artist',
      filename: 'Filename',
      progress: 'Upload Progress',
      placeholder: '"Drag & Drop" the Audio Files or click here',
      placeholderSubtext: '(High Quality WAV required)',
      start: 'Start Upload',
      stop: 'Stop Upload',
      submit: 'Save and Continue',
      error: {
        file_extension: 'Invalid audio file format. High quality WAV required.',
        title_cant_be_empty: 'Song name can\'t be empty.',
        background_cant_be_empty: 'Background must be selected.',
        something_went_wrong: 'Please wait a moment and try again.',
      },
    },

    global: {
      title: 'Set the Background',
      goBackTo: 'Change Background',
      submit: 'Save and Continue',
      extension: 'Image must be PNG, JPEG or TIFF',
      dimensions: 'Image must be at least 1080p',
      error: 'An error occurred while uploading.',
    },

    background: {
      submit: 'Next Step',
      color: 'Background Color',
      upload: 'Upload A Background',
      choose: 'Choose Background',
      title: 'Upload Your Files',
      placeholder: '"Drag & Drop" the image or click here',
      font_error: 'Please use a valid hex color code.',
      upload_error: 'Please upload a valid image or choose another background option.',
    },

    video: {
      select: 'Select a video from above',
      title: 'Video Settings',
      artwork: 'Artwork',
      artworkExtension: 'Image must be PNG, JPEG or TIFF',
      artworkDimensions: 'Image must be at least 900x900',
      artworkPlaceholder: 'Upload artwork image here',
      artworkError: 'An error occurred while uploading.',
      applyAll: 'Apply to all',
      show_text: 'Show text',
      song_name: 'Song name',
      genre: 'Genre',
      publisher: 'Publisher',
      composer: 'Composer',
      label: 'Label',
      video_isrc: 'Video ISRC',
      audio_isrc: 'Audio ISRC',
      font_color: 'Color',
      font_error: 'Please use a valid hex color code.',
      font_shadow: 'Shadow',
      name: 'Artist',
      font: 'Font',
      by: 'by ',

      color_picker: {
        note: 'Click the eye-dropper to get a color from the picture.',
      },
    },

    save: {
      next: 'Next Song',
      previous: 'Previous Song',
      'continue': 'Next Song',
      finish: 'Save and Finish',
    },

    build: {
      heading: 'We\'ll take it from here',
      title: 'You\'ll be notified you as soon as your videos are ready.',
      body: 'In the mean time, click the button below to publish your new videos to VEVO and more!',
      error: 'Check files above for errors.',
    },

    monetize: {
      notice_header: 'More Videos = More Money',
      notice_body: `We've found that ${appBrandName} artists earn about 300% more when they upload and publish more than 5 videos.`,
      skip: 'No thanks, take me to my files.',
      contact: 'Contact Information',
      title: 'Publish and Monetize your new videos in 3 minutes.',
      subTitle: 'You\'ll be notified you as soon as your videos are encoded.',
      submit: 'Continue',
      contact_name: 'Your Name',
      contact_phone: 'Your Phone',
      contact_email: 'Your Email',
      publish: 'Publish Now',
      publishError: 'All fields are required.',
      goBack: 'Go Back',
      tooltip: {
        remove: 'Don\'t create a Release for this file now.',
      },
    },

    finished: 'Your Videos are being created. Please check your medias soon.',

    audioWarning: {
      title: 'Before you Continue...',
      message: 'We won\'t let you use this tool if any of your uploads:',
      checklist: [
        'contain third-party content that you have not exclusively licensed.',
        'contain public domain clips or speeches.',
        'are karaoke or sound-alike versions.',
        'contain audio library samples, loops, or sound-effects that are not exclusive to your track.',
      ],
      question: 'If your upload does not contain any of the above you may Continue.',
      cancel: 'Cancel',
      confirm: 'Continue',
    },

    backgroundWarning: {
      title: 'Before you Continue...',
      message: 'We won\'t be able to process your image if contains any:',
      checklist: [
        'website, social, email links.',
        'third-party logos including Facebook, Twitter, iTunes.',
      ],
      question: 'If the uploaded image does not contain any of the above you may Continue.',
      cancel: 'Cancel',
      confirm: 'Continue',
    },

  },

  bundle: {

    genrePicker: {
      heading: 'Customize your experience',
      label: 'What genre is your music video?',
      note: 'If no genre matches, pick the closest fit.',
    },

    disabledReason: {
      alreadyPurchased: 'Oops! You\'ve already paid for a bundle on this release. If you want to purchase another bundle, please create a new release.',
    },
  },

  network: {
    disabledReason: {
      isBundled: 'This network is included in the selected bundle.',
    },
  },

  releaseDetails: {
    noTrim: 'You\'re posting your entire video',
    noTrimProtect: 'You\'re protecting your entire video',
    postImmediately: 'Post Immediately',
    protectImmediately: `${appBrandName} Content Protection Pending Review`,
    returnButton: 'All Broadcasts',
    twitterTrim: 'You\'re posting the first 30 seconds',
    twitterHelp: 'The maximum video length for the Twitter network is 30 seconds',
  },

  releaseExtras: {
    error: {
      heading: 'Uh oh!',
      submitHide: 'Got It',
      customVevoBlocked: '"Rush Delivery" is currently unavailable for Custom VEVO YouTube Channels. If you require a Custom VEVO YouTube Channel, please deselect "Rush Delivery".', // TODO
      rushDeliveryBlocked: '"Rush Delivery" is currently unavailable for Custom VEVO YouTube Channels. If you require Rush Delivery, please deselect "Custom VEVO YouTube Channel".', // TODO
    },
  },

  setup: {
    metadata: {
      generate: {
        audio_isrc: 'Don\'t have an Audio ISRC?',
        video_isrc: 'Don\'t have a Video ISRC?',
      },
    },
  },

  bannerExamplesOverlay: {
    heading: 'Professional VEVO Banners',
    selectButton: 'I want one!',
    cancel: 'I don\'t need a professional banner',
    close: 'Close',
  },

  youtubePreviewOverlay: {
    heading: 'YouTube Preview',
    close: 'Close',
  },

  facebookPreviewOverlay: {
    heading: 'Facebook Preview',
    close: 'Close',
  },

  releases: {
    monetize: 'Publish',
    distribute: 'Distribute',
  },

}

// - -------------------------------------------------------------------- - //

export default texts
