// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import AudioFileCollection from '../flux/collections/AudioFileCollection.js'
import AudioFileModel from '../flux/models/AudioFileModel.js'
import BatchUploadArea from './BatchUploadArea.jsx'
import BatchUploadList from './BatchUploadList.jsx'
import PropTypes from 'prop-types'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'BatchUpload'

  static propTypes = {
    files: PropTypes.instanceOf(AudioFileCollection).isRequired,
    selectedFile: PropTypes.instanceOf(AudioFileModel),
    selectable: PropTypes.bool.isRequired,
    sortable: PropTypes.bool.isRequired,
  }

  render () {
    let list
    if (this.props.files.length) {
      list = (
        <BatchUploadList
          files={this.props.files}
          selectable={this.props.selectable}
          selectedFile={this.props.selectedFile}
          sortable={this.props.sortable}
        />
      )
    }
    return (
      <div className='batch-upload'>
        { list }
        <BatchUploadArea />
      </div>
    )
  }
}

// - -------------------------------------------------------------------- - //
