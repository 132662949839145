import $ from 'jquery'
import Modal from './Modal.js'
import template from '../utils/template.js'

function mediaPreview ($el, options) {
  const modal = new Modal(options.modalSelector, options['modalOptions'])

  modal.on('modalClose', (_, $element) => {
    $element.find('.container').empty()
  })

  $el.click(function (event) {
    event.preventDefault()
    event.stopPropagation()
    modal.$el.find('.container').html(createTag($(this), options))
    modal.open()
    modal.$el.find('video, audio').each(function () {
      this.play()
    })
  })
}

function mediaAttributes ($el, formats) {
  const attributes = {}
  $.each(formats, (_, kind) => {
    attributes[`${kind}_url`] = $el.data(`${kind}-url`)
  })
  return attributes
}

function createTag ($el, options) {
  return template(options.templateSelector, {
    file: mediaAttributes($el, options.formats),
  })
}

export default mediaPreview
