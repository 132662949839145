// - -------------------------------------------------------------------- - //

'use strict'

import {
  clone,
  each,
  extend,
  has,
  isArray,
  isEmpty,
  isNil,
  isObject,
  keys,
  pick,
} from 'lodash-es'
import { inherits } from 'util'

// - -------------------------------------------------------------------- - //

function Model (data) {
  this.data = {}
  this.errors = null
  this.loading = false
  this.modified = false

  // Defines model properties for further validation.
  this.properties.forEach((name) => {
    this.data[name] = null
  })

  // Sets given data using setValue to validate.
  if (isObject(data)) {
    keys(data).forEach((name) => {
      this.setValue(name, data[name])
    })
  }

  this.init()
}

Model.prototype = {

  init () {
  },

  isFresh () {
    return !this.hasValue('id')
  },

  // setValues, but pick out valid attrs first.
  setPickedValues (attrs) {
    attrs = this.constructor.pickProperties(attrs)

    return this.setValues(attrs)
  },

  setValues (attrs) {
    each(attrs, (value, key) => {
      this.setValue(key, value)
    })

    return this
  },

  hasValue (attr) {
    const val = this.getValue(attr)
    return !isNil(val)
  },

  getValue (attr) {
    if (!has(this.data, attr)) {
      throw new Error(`Invalid property key: "${attr}".`)
    }
    return this.data[attr]
  },

  setValue (attr, value) {
    if (!has(this.data, attr)) {
      throw new Error(`Invalid property key: "${attr}".`)
    }
    this.data[attr] = value

    return this
  },

  getErrors () {
    return this.errors
  },

  setErrors (errors) {
    this.errors = clone(errors)

    return this
  },

  clearError (attr) {
    if (this.hasErrors()) {
      delete this.errors[attr]
    }

    return this
  },

  clearErrors () {
    this.errors = null

    return this
  },

  hasErrors () {
    const errors = this.getErrors()

    return (isObject(errors) || isArray(errors))
        && !isEmpty(errors)
  },

  setLoading (loading) {
    this.loading = !!loading

    return this
  },

  isLoading () {
    return this.loading
  },

  setModified (modified) {
    this.modified = !!modified

    return this
  },

  isModified () {
    return this.modified
  },

  toJSON () {
    const json = {}
    for (const key in this.data) {
      const val = this.data[key]
      if (val instanceof Model) {
        json[key] = val.toJSON()
      } else if (val instanceof Object) {
        json[key] = clone(val)
      } else {
        json[key] = val
      }
    }
    return json
  },

  toString () {
    return JSON.stringify(this.toJSON())
  },

}

// - -------------------------------------------------------------------- - //

const ClassFunctions = {
  pickProperties (object) {
    return pick(object, this.prototype.properties || [])
  },
}

// - -------------------------------------------------------------------- - //

Model.createModel = function (definition) {
  definition = definition || {}
  definition.properties = definition.properties || []

  const NewModel = function () {
    Model.apply(this, arguments)
  }

  inherits(NewModel, Model)

  extend(NewModel, ClassFunctions)
  extend(NewModel.prototype, definition)

  return NewModel
}

Model.isModel = function (arg) {
  return arg instanceof Model
}

export default Model

// - -------------------------------------------------------------------- - //
