import $ from 'jquery'
import ClipboardJsMounter from '../utils/ClipboardJsMounter'
import Copiable from '../components/Copiable'
import ready from '../utils/ready'

ready(() => {
  $('[data-copiable]').each(function () {
    const $copiable = $(this)

    const copiable = new Copiable($copiable, {
      preventClick: $copiable.data('prevent-click') !== undefined,
    })

    ClipboardJsMounter.mount(copiable, this)
  })

  $('a.noclick').on('click', (event) => {
    event.preventDefault()
    return false
  })
})
