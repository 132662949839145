import { stringifyErrors } from 'd2/utils/StringHelpers'
import $ from 'jquery'
import ready from '../utils/ready'
import 'bootstrap/js/tooltip' // eslint-disable-line sort-imports-es6-autofix/sort-imports-es6

let processing

const doPrompt = function (message, answer, placeholder) {
  if (!message) {
    return true
  }
  placeholder || (placeholder = '')
  return `${prompt(message, placeholder)}` === `${answer}`
}

const doConfirm = function (message) {
  if (!message) {
    return true
  }
  return window.confirm(message)
}

const handleSuccessURI = function (uri) {
  if (uri && uri !== '#') {
    window.location.href = uri
  } else {
    window.location.reload(false)
  }
}

const handleSuccessSelector = function (selector) {
  const $removeElements = $(selector)
  const $tooltipElements = $removeElements.find('[data-original-title]')
  $tooltipElements.tooltip('hide')
  $tooltipElements.tooltip('destroy')
  $removeElements.remove()
}

const ajaxError = function (errors) {
  const errorString = stringifyErrors(errors)
  let errorMsg = errorString.join('\n')

  if (errorMsg) {
    errorMsg = `Oops! Failed because\n${errorMsg}`
  } else {
    errorMsg = 'Oops! Something went wrong. Please try again in a little while.'
  }

  alert(errorMsg)
}

const ajaxComplete = function () {
  processing = false
}

const tryCatchParseJSON = function (text) {
  let data
  let error
  try {
    data = $.parseJSON(text)
  } catch (e) {
    error = e
  }
  return {
    data,
    error,
  }
}

const onClickDataRemote = function (event) {
  event.preventDefault()

  const $this = $(this)
  const path = $this.attr('href')
  const method = ($this.data('method') || '').toUpperCase()
  const confirm = $this.data('confirm')

  const prompt = $this.data('prompt')
  const promptAnswer = $this.data('prompt-answer')
  const promptPlaceholder = $this.data('prompt-placeholder')

  const successURI = $this.data('success-uri')
  const successRemoveSelector = $this.data('success-remove-selector')
  const successAlertNoReload = $this.data('success-alert-no-reload')

  if (processing || $(this).hasClass('disabled')) {
    return false
  }

  const confirmationSuccess = doConfirm(confirm) && doPrompt(prompt, promptAnswer, promptPlaceholder)

  if (!confirmationSuccess) {
    return false
  }

  processing = true

  $.ajax(path, {
    data: {
      _method: method,
      authenticity_token: $('meta[name=csrf-token]').attr('content'),
    },
    type: method,
    error (jqXHR) {
      const parsed = tryCatchParseJSON(jqXHR.responseText)
      const data = parsed.data
      const errors = parsed.error || (data && data.errors) || data

      if (parseInt(jqXHR.status) === 401) {
        window.location.href = '/'
      } else {
        ajaxError(errors)
      }
    },
    complete: ajaxComplete,
    success () {
      if (successRemoveSelector) {
        handleSuccessSelector(successRemoveSelector)
      } else if (successAlertNoReload) {
        alert(successAlertNoReload)
      } else {
        handleSuccessURI(successURI)
      }
    },
  })

  return false
}

const onClickDataNewReleaseForm = function (event) {
  event.preventDefault()

  if (processing) {
    return false
  }

  const $this = $(this)
  const formAttributes = {}

  processing = true

  formAttributes.media_id = $this.data('media-id')
  formAttributes.networks_ids = $this.data('networks-ids')
  formAttributes.type = $this.data('type')
  formAttributes.service = $this.data('service')
  formAttributes.bundle_id = $this.data('bundle-id')
  formAttributes.authenticity_token = $('meta[name=csrf-token]').attr('content')

  $.form('/service', formAttributes).submit()

  return false
}

const onClickDataHrefMethod = function (event) {
  event.preventDefault()

  const $this = $(this)
  let method = ($this.data('href-method') || '').toUpperCase()
  const confirm = $this.data('confirm')
  const prompt = $this.data('prompt')
  const promptAnswer = $this.data('prompt-answer')
  const promptPlaceholder = $this.data('prompt-placeholder')

  if (processing || $this.hasClass('disabled')) {
    return false
  }

  if (!doPrompt(prompt, promptAnswer, promptPlaceholder)
      || !doConfirm(confirm)) {
    return false
  }

  processing = true

  const formAttributes = {
    '_method': method,
    authenticity_token: $('meta[name=csrf-token]').attr('content'),
  }

  method = method === 'GET' ? method : 'POST'

  $.form($this.attr('href'), formAttributes, method).submit()

  return false
}

const onClickCheckBoxInput = function (event) {
  const $this = $(this)
  const confirm = $this.data('confirm')
  const prompt = $this.data('prompt')
  const promptAnswer = $this.data('prompt-answer')
  const promptPlaceholder = $this.data('prompt-placeholder')

  if (processing || $this.hasClass('disabled')) {
    return false
  }

  if ($(this).prop('checked') === false) {
    return true
  }

  const confirmationSuccess = doPrompt(prompt, promptAnswer, promptPlaceholder)

  if (confirmationSuccess) {
    return true
  }

  event.preventDefault()
}

// new release forms
$(document).on('click', '[data-new-release-form]', onClickDataNewReleaseForm)

$(document).on('click', 'a[data-remote]', onClickDataRemote)

$(document).on('click', 'a[data-href-method]', onClickDataHrefMethod)

$(document).on('click', '[data-checkbox-input]', onClickCheckBoxInput)

ready(() => {
// bind global form components
  $('a[href="#submit"]').on('click', function (event) {
    event.preventDefault()
    $(this).closest('form')
      .get(0)
      .submit()
  })

  $('form[data-confirm]').on('submit', function (event) {
    const $this = $(this)
    let confirmed = false
    let message = $this.data('confirm')

    if (!message) {
      message = 'Are you sure?'
    }

    confirmed = window.confirm(message)

    if (!confirmed) {
      event.preventDefault()
      return false
    }
  })
})
