const suffix = ` - ${window.app_brand_name}`

export default function (componentDefaultTitle) {
  return {

    componentDidMount () {
      this._setPageTitle()
    },

    componentDidUpdate () {
      // TODO: Check if title is actually different.
      // if (this.state.pageTitle !== prevState.pageTitle) {
      // }
      this._setPageTitle()
    },

    _setPageTitle () {
      document.title = this._getPageTitle() + suffix
    },

    _getPageTitle () {
      return (this.state && this.state.pageTitle) || componentDefaultTitle
    },

  }
}
