// - -------------------------------------------------------------------- - //

'use strict'

import { each, isEmpty } from 'lodash-es'
import { songName } from 'd2/utils/StringHelpers'
import ArtistStoreMixin from './shared/ArtistStoreMixin.js'
import AudioFileCollection from '../collections/AudioFileCollection.js'
import AudioFileModel from '../models/AudioFileModel.js'
import BackgroundImageModel from '../models/BackgroundImageModel.js'
import Flux from '../flux.js'
import GlobalSettingsModel from '../models/GlobalSettingsModel.js'
import ImageFileModel from '../models/ImageFileModel.js'

let AudioToVideoStore

// - -------------------------------------------------------------------- - //

let _CurrentStep

let _isSaving
let _CreatedMedias
let _BuildingErrors
let _BuildingFinished

let _AudioWarning
let _BackgroundWarning
let _Fonts
let _GlobalSettingsModel
let _eyeDropperState
let _AudioFileCollection
let _SelectedFile

const resetStoreState = function () {
  _CurrentStep = 1

  _isSaving = false
  _BuildingFinished = false
  _BuildingErrors = undefined
  _CreatedMedias = []

  _Fonts = window.fonts
  _AudioWarning = false
  _BackgroundWarning = false
  _SelectedFile = undefined
  _eyeDropperState = false
  _AudioFileCollection = new AudioFileCollection()
  _GlobalSettingsModel = new GlobalSettingsModel({
    backgroundType: 'choose',
    backgroundColor: null,
    backgroundImage: new BackgroundImageModel(),
    backgroundTemplate: null,
  })
}

resetStoreState()

// - -------------------------------------------------------------------- - //

const storeStateMethods = {

  getStep () {
    return _CurrentStep
  },

  isSaving () {
    return _isSaving
  },

  getCreatedMedias () {
    return _CreatedMedias
  },

  getBuildingErrors () {
    return _BuildingErrors
  },

  hasBuildingFinished () {
    return _BuildingFinished
  },

  hasAudioWarning () {
    return _AudioWarning
  },

  hasBackgroundWarning () {
    return _BackgroundWarning
  },

  getFonts () {
    return _Fonts
  },

  getGlobalSettings () {
    return _GlobalSettingsModel
  },

  isBackgroundComplete () {
    const image = _GlobalSettingsModel.getValue('backgroundImage')
    const file = image.getValue('file')
    if (file) {
      return file.isComplete()
    }
    return true
  },

  isBackgroundUploading () {
    const image = _GlobalSettingsModel.getValue('backgroundImage')
    const file = image.getValue('file')
    if (file) {
      return file.isUploading()
    }
    return false
  },

  isArtworkComplete () {
    const audioFile = _SelectedFile
    if (audioFile) {
      const imageFile = audioFile.getValue('artwork')
      if (imageFile) {
        return imageFile.isComplete()
      }
      return true
    }
    return true
  },

  isArtworkUploading () {
    const audioFile = _SelectedFile
    if (audioFile) {
      const imageFile = audioFile.getValue('artwork')
      if (imageFile) {
        return imageFile.isUploading()
      }
      return false
    }
    return false
  },

  isEyeDropperEnabled () {
    return _eyeDropperState
  },

  isAudioUploading () {
    return _AudioFileCollection.filter((model) => model.isUploading()).length > 0
  },

  isAudioComplete () {
    const filtered = _AudioFileCollection.filter((model) => model.isComplete())
    return filtered.length > 0 && filtered.length === _AudioFileCollection.length
  },

  hasValidAudioFiles () {
    const filtered = _AudioFileCollection.filter((model) => !model.getErrors())
    return filtered.length > 0
  },

  getAudioFiles () {
    return _AudioFileCollection
  },

  getSelectedAudioFile () {
    return _SelectedFile
  },

}

const actionHandlers = {

  BUILD_ERROR (payload) {
    _isSaving = false
    _BuildingFinished = false
    _BuildingErrors = payload.errors
    each(payload.errors, (fileErrors, fileId) => {
      const file = _AudioFileCollection.getFileByUploadId(fileId)
      if (file) {
        file.setErrors(fileErrors)
      }
    })
  },

  BUILD_SUCCESS (payload) {
    _isSaving = false
    _BuildingFinished = true
    _BuildingErrors = undefined
    _CreatedMedias = payload.medias
  },

  CHANGE_STEP (payload) {
    _CurrentStep = payload.step
  },

  SAVE_AND_FINISH () {
    _isSaving = true
    _BuildingErrors = undefined
  },

  SAVE_AND_CONTINUE () {
    _CurrentStep = Math.min(_CurrentStep + 1, 3)

    if (_CurrentStep === 3 && _SelectedFile) {
      _SelectedFile.fillDefaultSettings()
    }
  },

  SHOW_AUDIO_WARNING () {
    _AudioWarning = true
  },

  CANCEL_AUDIO_WARNING () {
    _AudioWarning = false
  },

  CONFIRM_AUDIO_WARNING () {
    _AudioWarning = false
    _CurrentStep = Math.min(_CurrentStep + 1, 3)
  },

  SHOW_BACKGROUND_WARNING () {
    _BackgroundWarning = true
  },

  CANCEL_BACKGROUND_WARNING () {
    _BackgroundWarning = false
  },

  CONFIRM_BACKGROUND_WARNING () {
    _BackgroundWarning = false
    _CurrentStep = Math.min(_CurrentStep + 1, 3)
  },

  CHANGE_FILE_ORDER (payload) {
    _AudioFileCollection.reorder(payload.order)
  },

  LOAD_FONTS (payload) {
    _Fonts = payload.fonts
  },

  GLOBAL_SETTINGS () {
    _CurrentStep = 2
  },

  GLOBAL_SETTINGS_UPDATE () {
  },

  GLOBAL_SETTINGS_ARTWORK (payload) {
    _GlobalSettingsModel.setValue('artwork', payload.artwork)
  },

  GLOBAL_SETTINGS_BG_TYPE (payload) {
    _GlobalSettingsModel.setValue('backgroundType', payload.backgroundType)
    if (payload.backgroundType !== 'choose') {
      _GlobalSettingsModel.setValue('backgroundTemplate', null)
    }
    if (payload.backgroundType !== 'color') {
      _GlobalSettingsModel.setValue('backgroundColor', null)
    }
  },

  GLOBAL_SETTINGS_BG_COLOR (payload) {
    _GlobalSettingsModel.setValue('backgroundColor', payload.backgroundColor)
    _GlobalSettingsModel.setValue('backgroundImage', new BackgroundImageModel())
    _GlobalSettingsModel.setValue('backgroundTemplate', null)
    _AudioFileCollection.forEach((audioFile) => {
      audioFile.clearErrors()
    })
  },

  GLOBAL_SETTINGS_BG_TEMPLATE (payload) {
    _GlobalSettingsModel.setValue('backgroundColor', null)
    _GlobalSettingsModel.setValue('backgroundImage', new BackgroundImageModel())
    _GlobalSettingsModel.setValue('backgroundTemplate', payload.backgroundTemplate)
    _AudioFileCollection.forEach((audioFile) => {
      audioFile.clearErrors()
    })
  },

  GLOBAL_SETTINGS_BG_IMAGE (payload) {
    const image = _GlobalSettingsModel.getValue('backgroundImage')
    const file = new ImageFileModel({
      filename: payload.filename,
      length: payload.length,
      url: payload.url,
    })
    image.setValue('file', file)
  },

  GLOBAL_SETTINGS_BG_REMOVE () {
    const image = _GlobalSettingsModel.getValue('backgroundImage')
    image.setValue('file', null)
  },

  GLOBAL_SETTINGS_BG_UPLOAD_START () {
    _GlobalSettingsModel.setValue('backgroundColor', null)
    _GlobalSettingsModel.setValue('backgroundTemplate', null)
    const image = _GlobalSettingsModel.getValue('backgroundImage')
    const file = image.getValue('file')
    if (file) {
      file.setValue('length', 0)
      file.setValue('uploaded', 0)
    }
  },

  GLOBAL_SETTINGS_BG_UPLOAD_ERROR (payload) {
    const image = _GlobalSettingsModel.getValue('backgroundImage')
    const file = image.getValue('file')
    if (file) {
      file.setErrors(payload.errors)
    }
  },

  GLOBAL_SETTINGS_BG_UPLOAD_PROGRESS (payload) {
    const image = _GlobalSettingsModel.getValue('backgroundImage')
    const file = image.getValue('file')
    if (file) {
      file.setValue('length', payload.length)
      file.setValue('uploaded', payload.uploaded)
    }
  },

  GLOBAL_SETTINGS_BG_UPLOAD_COMPLETE (payload) {
    const image = _GlobalSettingsModel.getValue('backgroundImage')
    const file = image.getValue('file')
    if (file) {
      file.setValue('uploaded', file.getValue('length'))
      file.setValue('file_upload_id', payload.file_upload_id)
    }
    _AudioFileCollection.forEach((audioFile) => {
      audioFile.clearErrors()
    })
  },

  VIDEO_SETTINGS_ARTWORK_IMAGE (payload) {
    const audioFile = _SelectedFile
    if (audioFile) {
      const imageFile = new ImageFileModel({
        filename: payload.filename,
        length: payload.length,
        url: payload.url,
      })
      audioFile.setValue('artwork', imageFile)
    }
  },

  VIDEO_SETTINGS_ARTWORK_REMOVE () {
    const audioFile = _SelectedFile
    if (audioFile) {
      audioFile.setValue('artwork', null)
    }
  },

  VIDEO_SETTINGS_ARTWORK_UPLOAD_START () {
    const audioFile = _SelectedFile
    if (audioFile) {
      const imageFile = audioFile.getValue('artwork')
      if (imageFile) {
        imageFile.setValue('length', 0)
        imageFile.setValue('uploaded', 0)
      }
    }
  },

  VIDEO_SETTINGS_ARTWORK_UPLOAD_ERROR (payload) {
    const audioFile = _SelectedFile
    if (audioFile) {
      const imageFile = audioFile.getValue('artwork')
      if (imageFile) {
        imageFile.setErrors(payload.errors)
      }
    }
  },

  VIDEO_SETTINGS_ARTWORK_UPLOAD_PROGRESS (payload) {
    const audioFile = _SelectedFile
    if (audioFile) {
      const imageFile = audioFile.getValue('artwork')
      if (imageFile) {
        imageFile.setValue('length', payload.length)
        imageFile.setValue('uploaded', payload.uploaded)
      }
    }
  },

  VIDEO_SETTINGS_ARTWORK_UPLOAD_COMPLETE (payload) {
    const audioFile = _SelectedFile
    if (audioFile) {
      const imageFile = audioFile.getValue('artwork')
      if (imageFile) {
        imageFile.setValue('uploaded', imageFile.getValue('length'))
        imageFile.setValue('file_upload_id', payload.file_upload_id)
      }
    }
  },

  VIDEO_SETTINGS_UPDATE () {

  },

  TOGGLE_EYE_DROPPER (payload) {
    _eyeDropperState = payload.enabled
  },

  BATCH_UPLOAD_ADD_FILE (payload) {
    let model = _AudioFileCollection.getFileByName(payload.filename)
    if (model) {
      model.setValue('uploaded', 0)
    } else {
      model = new AudioFileModel({
        artist: AudioToVideoStore.getSelectedArtist(),
        filename: payload.filename,
        song_name: songName(payload.filename),
      })
      _AudioFileCollection.add(model)
    }
  },

  BATCH_UPLOAD_REMOVE_FILE (payload) {
    const model = _AudioFileCollection.getFileByName(payload.filename)
    if (model) {
      if (model === _SelectedFile) {
        const indexOf = _AudioFileCollection.indexOf(model)
        if (_AudioFileCollection[indexOf + 1]) {
          _SelectedFile = _AudioFileCollection[indexOf + 1]
        } else if (_AudioFileCollection[indexOf - 1]) {
          _SelectedFile = _AudioFileCollection[indexOf - 1]
        } else {
          _SelectedFile = null
        }
      }
      _AudioFileCollection.del(model)
    }
    if (!_SelectedFile) {
      _CurrentStep = 1
    }
  },

  BATCH_UPLOAD_START (payload) {
    const model = _AudioFileCollection.getFileByName(payload.filename)
    if (model) {
      model.setValue('length', 0)
      model.setValue('uploaded', 0)
    }
  },

  BATCH_UPLOAD_ERROR (payload) {
    const model = _AudioFileCollection.getFileByName(payload.filename)
    let errors = payload.errors
    if (model) {
      if (isEmpty(errors)) {
        errors = { 'something': ['went_wrong'] }
      }
      model.setErrors(errors)
    }
  },

  BATCH_UPLOAD_PROGRESS (payload) {
    const model = _AudioFileCollection.getFileByName(payload.filename)
    if (model) {
      model.setValue('length', payload.length)
      model.setValue('uploaded', payload.uploaded)
    }
  },

  BATCH_UPLOAD_COMPLETE (payload) {
    const model = _AudioFileCollection.getFileByName(payload.filename)
    if (model) {
      model.setValue('uploaded', model.getValue('length'))
      model.setValue('file_upload_id', payload.file_upload_id)
    }
  },

  BATCH_UPLOAD_SELECT_FILE (payload) {
    if (payload.filename === null) {
      _SelectedFile = undefined
    } else {
      const selectedFile = _AudioFileCollection.getFileByName(payload.filename)
      if (_CurrentStep === 3) {
        if (_SelectedFile) {
          selectedFile.copyUndefinedSettingsFrom(_SelectedFile)
        }
        selectedFile.fillDefaultSettings()
      }
      _SelectedFile = selectedFile
    }
  },

  BATCH_UPLOAD_SELECT_FIRST_FILE () {
    const filtered = _AudioFileCollection.filter((model) => !model.getErrors())
    if (filtered.length) {
      const firstFile = filtered[0]
      if (_CurrentStep === 3) {
        firstFile.fillDefaultSettings()
      }
      _SelectedFile = firstFile
    } else {
      _SelectedFile = undefined
    }
  },

  BATCH_UPLOAD_PREVIOUS_SONG () {
    const index = _AudioFileCollection.indexOf(_SelectedFile)
    if (index > 0) {
      const prevFile = _AudioFileCollection[index - 1]
      if (_CurrentStep === 3) {
        if (_SelectedFile) {
          prevFile.copyUndefinedSettingsFrom(_SelectedFile)
        }
        prevFile.fillDefaultSettings()
      }
      _SelectedFile = prevFile
    }
  },

  BATCH_UPLOAD_NEXT_SONG () {
    const index = _AudioFileCollection.indexOf(_SelectedFile)
    if (index > -1 && index < _AudioFileCollection.length - 1) {
      const nextFile = _AudioFileCollection[index + 1]
      if (_CurrentStep === 3) {
        if (_SelectedFile) {
          nextFile.copyUndefinedSettingsFrom(_SelectedFile)
        }
        nextFile.fillDefaultSettings()
      }
      _SelectedFile = nextFile
    }
  },

}

// - -------------------------------------------------------------------- - //

export default AudioToVideoStore = Flux.createCompositeStore(
  ArtistStoreMixin({ actionPrefix: 'AUDIO_TO_VIDEO' }),
  [storeStateMethods, actionHandlers, resetStoreState]
)

// - -------------------------------------------------------------------- - //
