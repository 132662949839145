// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import PropTypes from 'prop-types'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'ButtonGroup'

  static propTypes = {
    options: PropTypes.array.isRequired,
    selected: PropTypes.any,
    onChange: PropTypes.func.isRequired,
  }

  state = {
    selectedIndex: this.props.options.indexOf(this.props.selected),
  }

  componentWillReceiveProps (newProps) {
    this.setState({
      selectedIndex: newProps.options.indexOf(newProps.selected),
    })
  }

  handleChange = (index) => {
    this.setState({ selectedIndex: index })
    if (this.props.onChange) {
      this.props.onChange(this.props.options[index])
    }
  }

  renderButton = (option, index) => (
    <button
      className={this.state.selectedIndex === index ? 'selected' : null}
      key={index}
      onClick={() => this.handleChange(index)}
      type='button'
    >
      { option }
    </button>
  )

  render () {
    return (
      <div className='button-group'>
        { this.props.options.map(this.renderButton) }
      </div>
    )
  }
}

// - -------------------------------------------------------------------- - //
