// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import AudioToVideoAction from '../flux/actions/AudioToVideoAction.js'
import BackgroundImageModel from '../flux/models/BackgroundImageModel.js'
import BackgroundUploadFile from './BackgroundUploadFile.jsx'
import DropFileArea from './DropFileArea.jsx'
import PropTypes from 'prop-types'
import Texts from '../texts/texts.js'
import cx from 'classnames'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'BackgroundUpload'

  static propTypes = {
    backgroundImage: PropTypes.instanceOf(BackgroundImageModel).isRequired,
  }

  render () {
    const file = this.props.backgroundImage.getValue('file')
    let content
    const classes = {
      'background-settings-upload': true,
    }

    if (file) {
      classes['has-background-upload-file'] = true
      content = <BackgroundUploadFile backgroundImage={this.props.backgroundImage} />
    }

    return (
      <div className={cx(classes)}>
        { content }
        <DropFileArea
          onDropFile={AudioToVideoAction.uploadBackgroundImage}
          placeholder={Texts.a2v.background.placeholder}
        />
      </div>
    )
  }
}

// - -------------------------------------------------------------------- - //
