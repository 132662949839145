import $ from 'jquery'

const Delegator = function () {
  this.$el = $('<p/>')
}

// Add a function to the object that delegates to a property on the same object.
//
// @param obj [Object] - the object to add the function to.
// @param functionName [String] - the name of the function to delegate.
// @param delegateToPropName [String] - the name of the property on the object to delegate to.
//
// @return objFunction [Function] - the function that was created.
Delegator.delegate = function (obj, functionName, delegateToPropName) {
  const objFunction = function () {
    const delegateTo = this[delegateToPropName]
    return delegateTo[functionName].apply(delegateTo, arguments)
  }

  obj[functionName] = objFunction

  return objFunction
}

// Add basic event functions to an object.
// functions added: `on` `off` `trigger`
//
// @param obj [Object] - the object to add the functions to.
// @param delegateToPropName [String] - the name of the property on the object to delegate to.
//
// Example use:
//   Delegator.extendEventDelegation(MyClass.prototype, "delegator");
Delegator.extendEventDelegation = function (obj, delegateToPropName) {
  // Listen for an event.
  Delegator.delegate(obj, 'on', delegateToPropName)

  // Stop listening for an event.
  Delegator.delegate(obj, 'off', delegateToPropName)

  // Trigger an event.
  Delegator.delegate(obj, 'trigger', delegateToPropName)
}

// Delegate event functions to $el.
Delegator.extendEventDelegation(Delegator.prototype, '$el')

export default Delegator
