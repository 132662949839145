import $ from 'jquery'
import Delegator from '../Delegator'

// TODO: Store default so that it may be reset
const Model = function (data) {
  this.data = data || {}
  this.delegator = new Delegator()
}

// Set a single attribute or multiple attributes and trigger the "change" event.
Model.prototype.set = function () {
  let attributes
  let key
  let value

  if (arguments.length === 1) {
    attributes = arguments[0]
    this._setMultiple(attributes)
  } else {
    key = arguments[0]
    value = arguments[1]
    this._setSingle(key, value)
  }

  this.delegator.trigger('change', [key, value])
}

Model.prototype.get = function (key) {
  return this.data[key]
}

Model.prototype._setSingle = function (key, value) {
  this.data[key] = value
}

Model.prototype._setMultiple = function (attributes) {
  const self = this
  $.each(attributes, (key, value) => {
    self._setSingle(key, value)
  })
}

Delegator.extendEventDelegation(Model.prototype, 'delegator')

export default Model
