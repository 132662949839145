// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import BackgroundSettings from './BackgroundSettings.jsx'
import GlobalPreview from './GlobalPreview.jsx'
import GlobalSettingsModel from '../flux/models/GlobalSettingsModel.js'
import PropTypes from 'prop-types'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'GlobalSettings'

  static propTypes = {
    globalSettings: PropTypes.instanceOf(GlobalSettingsModel).isRequired,
    onAccept: PropTypes.func,
  }

  render () {
    const globalSettings = this.props.globalSettings
    const backgroundType = globalSettings.getValue('backgroundType')
    const backgroundColor = globalSettings.getValue('backgroundColor')
    const backgroundImage = globalSettings.getValue('backgroundImage')
    const backgroundTemplate = globalSettings.getValue('backgroundTemplate')

    return (
      <div className='global-settings'>
        <div className='row'>
          <div className='col-md-6'>
            <BackgroundSettings
              backgroundColor={backgroundColor}
              backgroundImage={backgroundImage}
              backgroundTemplate={backgroundTemplate}
              backgroundType={backgroundType}
              onAccept={this.props.onAccept}
            />
          </div>
          <div className='col-md-6 global-preview-outer'>
            <GlobalPreview
              backgroundColor={backgroundColor}
              backgroundImage={backgroundImage}
              backgroundTemplate={backgroundTemplate}
            />
          </div>
        </div>
      </div>
    )
  }
}

// - -------------------------------------------------------------------- - //
