export default function (fn, throttleMs) {
  let handler

  throttleMs = throttleMs || 500

  return function () {
    const args = arguments

    if (handler) {
      clearTimeout(handler)
    }

    handler = setTimeout(() => {
      fn.apply(null, args)
    }, throttleMs)
  }
}
