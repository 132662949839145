// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import { register } from 'd2/intermediate/registry'
import $ from 'jquery'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import FacebookSyncMessageOverlay from './FacebookSyncMessageOverlay'
import TransitionDurations from 'd2/utils/transition_durations'

const overlayTransitionDurations = TransitionDurations('overlay')

// - -------------------------------------------------------------------- - //

const subCategories = [
  {
    value: 'film',
    label: 'Film & Animation',
  },
  {
    value: 'autos',
    label: 'Autos & Vehicles',
  },
  {
    value: 'music',
    label: 'Music',
  },
  {
    value: 'pets',
    label: 'Pets & Animals',
  },
  {
    value: 'sports',
    label: 'Sports',
  },
  {
    value: 'travel',
    label: 'Travel & Events',
  },
  {
    value: 'gaming',
    label: 'Gaming',
  },
  {
    value: 'people',
    label: 'People & Blogs',
  },
  {
    value: 'comedy',
    label: 'Comedy',
  },
  {
    value: 'entertainment',
    label: 'Entertainment',
  },
  {
    value: 'news',
    label: 'News & Politics',
  },
  {
    value: 'howto',
    label: 'Howto & Style',
  },
  {
    value: 'education',
    label: 'Education',
  },
  {
    value: 'science',
    label: 'Science & Technology',
  },
  {
    value: 'nonprofits',
    label: 'Nonprofits & Activism',
  },
]

class ConnectFacebookPageAdapter extends Component {
  state = {
    open: false,
    facebookPagesSync: this.props.facebookPagesIds.reduce((map, id) => {
      map[id] = false
      return map
    }, {}),
    facebookPageDefaultSubCategories: this.props.facebookPagesIds.reduce((map, id) => {
      map[id] = undefined
      return map
    }, {}),
  }

  componentDidMount () {
    $(this.props.selectAll).on('change', this.handleToggleSelectAll)
    $(this.props.selectOne).on('click', this.toggleCheckbox)
    $(`${this.props.selectOne} input:checkbox`).on('change', this.handleToggleSelectOne)
  }

  componentWillUnmount () {
    $(this.props.selectAll).off('change', this.handleToggleSelectAll)
    $(this.props.selectOne).off('click', this.toggleCheckbox)
    $(`${this.props.selectOne} input:checkbox`).off('change', this.handleToggleSelectOne)
  }

  toggleCheckbox = (event) => {
    const $checkbox = $(event.target).find('input:checkbox')
    $checkbox.prop('checked', !$checkbox.prop('checked'))
    $checkbox.trigger('change')
  }

  handleToggleSelectAll = (e) => {
    const $target = $(e.target)
    $(`${this.props.selectOne} input:checkbox`).prop('checked', $target.prop('checked'))
    if ($target.prop('checked')) {
      if (this.featureIsEnabled()) {
        this.setState({
          open: true,
          activePageIds: this.props.facebookPagesIds,
        })
      }
    } else {
      const fbPagesSync = this.props.facebookPagesIds.reduce((map, id) => {
        map[id] = false
        return map
      }, {})
      const fbPagesSyncDefaultSubCategories = this.props.facebookPagesIds.reduce((map, id) => {
        map[id] = undefined
        return map
      }, {})
      this.setState({
        activePageIds: [],
        facebookPagesSync: fbPagesSync,
        facebookPageDefaultSubCategories: fbPagesSyncDefaultSubCategories,
      })
      this.toggleSyncLabels(fbPagesSync)
    }
  }

  handleToggleSelectOne = (e) => {
    const $target = $(e.target)
    const pageId = $target.data('facebook-page-id')
    if ($target.prop('checked')) {
      if (this.featureIsEnabled()) {
        this.setState({
          open: true,
          activePageIds: [pageId],
        })
      }
    } else {
      const fbPagesSync = Object.assign({}, this.state.facebookPagesSync)
      const fbPagesSyncDefaultSubCategories = Object.assign({}, this.state.facebookPageDefaultSubCategories)
      fbPagesSync[pageId] = false
      fbPagesSyncDefaultSubCategories[pageId] = undefined
      this.setState({
        activePageIds: [],
        facebookPagesSync: fbPagesSync,
        facebookPageDefaultSubCategories: fbPagesSyncDefaultSubCategories,
      })
      this.toggleSyncLabels(fbPagesSync)
    }
  }

  toggleSyncLabels = (fbPagesSync) => {
    $.each(fbPagesSync, (key, value) => {
      const $target = $(`.name[data-facebook-page-id="${key}"]`)
      const notLabelled = !$target.hasClass('sync-enabled')
      if (value && notLabelled) {
        $target.addClass('sync-enabled')
      } else if (!value) {
        $target.removeClass('sync-enabled')
      }
    })
  }

  accept = (selectedCategory) => {
    let fbPagesSync = this.state.facebookPagesSync
    fbPagesSync = Object.assign({}, fbPagesSync, this.state.activePageIds.reduce((map, id) => {
      map[id] = true
      return map
    }, {}))
    let fbPagesSyncDefaultSubCategories = this.state.facebookPageDefaultSubCategories
    fbPagesSyncDefaultSubCategories = Object.assign({}, fbPagesSyncDefaultSubCategories, this.state.activePageIds.reduce((map, id) => {
      map[id] = selectedCategory
      return map
    }, {}))
    this.setState({
      open: false,
      activePageIds: [],
      facebookPagesSync: fbPagesSync,
      facebookPageDefaultSubCategories: fbPagesSyncDefaultSubCategories,
    })
    this.toggleSyncLabels(fbPagesSync)
  }

  decline = () => {
    let fbPagesSync = this.state.facebookPagesSync
    fbPagesSync = Object.assign({}, fbPagesSync, this.state.activePageIds.reduce((map, id) => {
      map[id] = false
      return map
    }, {}))
    this.setState({
      open: false,
      activePageIds: [],
      facebookPagesSync: fbPagesSync,
    })
  }

  featureIsEnabled = () => !!this.props.facebookSyncEnabled

  renderFacebookSyncInputs = () => this.props.facebookPagesIds.map((id, i) => (<input
    key={id}
    name={`facebook_page_sync[${id}]`}
    type='hidden'
    value={this.state.facebookPagesSync[id] ? '1' : ''}
  />))

  renderFacebookSyncDefaultSubCategoryInputs = () => this.props.facebookPagesIds.map((id, i) => (<input
    key={id}
    name={`facebook_page_default_subcategory[${id}]`}
    type='hidden'
    value={this.state.facebookPageDefaultSubCategories[id] ? this.state.facebookPageDefaultSubCategories[id] : ''}
  />))

  render () {
    return (
      <div>
        <CSSTransitionGroup
          transitionName='overlay'
          {...overlayTransitionDurations}
        >
          {
            this.state.open
              ? <FacebookSyncMessageOverlay
                onAccept={this.accept}
                onDecline={this.decline}
                subCategories={subCategories}
              />
              : null
          }
        </CSSTransitionGroup>
        { this.renderFacebookSyncInputs() }
        { this.renderFacebookSyncDefaultSubCategoryInputs() }
      </div>
    )
  }
}

export default register('ConnectFacebookPageAdapter', ConnectFacebookPageAdapter)

// - -------------------------------------------------------------------- - //
