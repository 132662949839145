// - -------------------------------------------------------------------- - //

import { Component } from 'react'
import { register } from 'd2/intermediate/registry'
import $ from 'jquery'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import Container from '../Container.jsx'
import FacebookEmbed from './FacebookEmbed'
import Overlay from '../Overlay.jsx'
import PropTypes from 'prop-types'
import TransitionDurations from 'd2/utils/transition_durations'
import YouTubeEmbed from './YouTubeEmbed'

const overlayTransitionDurations = TransitionDurations('overlay')

// - -------------------------------------------------------------------- - //

const styles = {
  container: {
    'textAlign': 'center',
    'backgroundColor': 'black',
    'border': 'black',
  },
}

class EmbedModalAdapter extends Component {
  static propTypes = {
    selector: PropTypes.string,
  }

  state = {
    socialType: null,
    facebookPageId: null,
    facebookVideoId: null,
    youtubeEmbedToken: null,
    videoWidth: null,
    videoHeight: null,
    open: false,
  }

  componentDidMount () {
    $(this.props.selector).on('click', this.onClickPreviewLink)
    $(document).on('click', '.overlay-container', this.toggleModal)
  }

  componentWillUnmount () {
    $(this.props.selector).off('click', this.onClickPreviewLink)
    $(document).off('click', '.overlay-container', this.toggleModal)
  }

  onClickPreviewLink = (e) => {
    const el = $(e.target)
    this.setState({
      socialType: el.data('type') || null,
      facebookPageId: el.data('facebook-page-id') || null,
      facebookVideoId: el.data('facebook-video-id') || null,
      youtubeEmbedToken: el.data('youtube-video-id') || null,
      videoWidth: el.data('video-width') || null,
      videoHeight: el.data('video-height') || null,
    })
    this.toggleModal()
  }

  toggleModal = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  throwError = () => {
    throw new Error('The media generator provided was not compatible with this component.')
  }

  renderEmbed = () => {
    if (this.state.socialType === 'facebook') {
      return (
        <Overlay>
          <Container
            className='container-xs'
            style={styles.container}
          >
            <FacebookEmbed
              pageId={this.state.facebookPageId}
              videoHeight={this.state.videoHeight}
              videoId={this.state.facebookVideoId}
              videoWidth={this.state.videoWidth}
            />
          </Container>
        </Overlay>
      )
    } else if (this.state.socialType === 'youtube') {
      return (
        <Overlay>
          <Container
            className='container-xs'
            style={styles.container}
          >
            <YouTubeEmbed
              token={this.state.youtubeEmbedToken}
            />
          </Container>
        </Overlay>
      )
    }
    return (
      <div>
        <div>
          The media generator provided was not compatible with this component.
        </div>
        { this.throwError() }
      </div>
    )
  }

  render () {
    return (
      <CSSTransitionGroup
        transitionName='overlay'
        {...overlayTransitionDurations}
      >
        {
          this.state.open
            ? this.renderEmbed()
            : null
        }
      </CSSTransitionGroup>
    )
  }
}

export default register('EmbedModalAdapter', EmbedModalAdapter)
