import $ from 'jquery'

const Radioer = function (options) {
  options || (options = {})
  options.activeClass || (options.activeClass = null)
  options.inputName || (options.inputName = null)
  options.containerSelector || (options.containerSelector = null)

  this.$radios = $(`input[name="${options.inputName}"]:radio`)

  this.options = options

  this._setup()
  this._bindEvents()
}

// accepts a Radioer instance, or jQuery object
// return Boolean
Radioer.anyChecked = function (radios) {
  if (radios instanceof Radioer) {
    return Radioer.anyChecked(radios.$radios)
  }
  return radios.filter(':checked').length > 0
}

Radioer.prototype.on = function () {
  return this.$radios.on.apply(this.$radios, arguments)
}

Radioer.prototype.anyChecked = function () {
  return Radioer.anyChecked(this)
}

// get or set the value
Radioer.prototype.val = function (value) {
  if (value === undefined) {
    return this.$radios.filter(':checked').val()
  }

  return this.$radios.filter(`[value="${value}"]`).prop('checked', true)
}

Radioer.prototype._bindEvents = function () {
  const self = this

  self._containers().on('click', '*', function (event) {
    event.preventDefault()
    event.stopImmediatePropagation()
    event.stopPropagation()
    const $radioOption = $(this).closest(self.options.containerSelector)
      .find(self.$radios)
    self._handleRadioOption($radioOption)
  })
}

Radioer.prototype._handleRadioOption = function ($radioOption) {
  const $inactive = this.$radios.not($radioOption)
  const $active = $radioOption

  $inactive.prop('checked', false)
  $active.prop('checked', true)

  this._containerForRadioOption($inactive)
    .removeClass(this.options.activeClass)

  this._containerForRadioOption($active)
    .addClass(this.options.activeClass)

  $active.trigger('change')
}

Radioer.prototype._containerForRadioOption = function ($radioOption) {
  return $radioOption.closest(this.options.containerSelector)
}

Radioer.prototype._containers = function () {
  return this.$radios.closest(this.options.containerSelector)
}

Radioer.prototype._setup = function () {
  this._handleRadioOption(this.$radios.filter(':checked'))
}

export default Radioer
