// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import Errors from '../utils/Errors.js'
import Model from './flux/models/Model.js'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import cx from 'classnames'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'TextField'

  static propTypes = {
    formFieldWrapper: PropTypes.bool,
    model: PropTypes.instanceOf(Model).isRequired,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    textarea: PropTypes.bool,
    note: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
  }

  static defaultProps = {
    formFieldWrapper: true,
  }

  constructor (props) {
    super(props)
    const model = props.model
    const attr = props.model_attribute

    this.state = {
      value: model ? model.getValue(attr) : null,
    }
  }

  componentWillReceiveProps (newProps) {
    this.setState({
      value: newProps.model ? newProps.model.getValue(newProps.model_attribute) : null,
    })
  }

  handleChange = (event) => {
    if (!this.props.readOnly) {
      const model = this.props.model
      const attr = this.props.model_attribute
      const onChange = this.props.onChange
      const value = ReactDOM.findDOMNode(this.refs.input).value

      if (model) {
        model.setValue(attr, value)
      }

      this.setState({ value }, () => {
        if (onChange) {
          onChange()
        }
      })
    }
  }

  renderError = () => {
    const model = this.props.model
    const attr = this.props.model_attribute
    const errors = model ? model.getErrors() : null
    if (errors && errors[attr]) {
      return (
        <div className='input-error'>
          { Errors.getReadableFieldMessages(attr, errors[attr], { includeFieldName: false }) }
        </div>
      )
    }
  }

  render () {
    const model = this.props.model
    const name = this.props.model_name
    const attr = this.props.model_attribute
    const label = this.props.label_attribute
    const note = this.props.note
    const value = this.state.value
    const errors = model ? model.getErrors() : null

    let input
    if (this.props.textarea) {
      input = (
        <textarea
          className='form-input'
          disabled={this.props.disabled}
          id={`${name}_${attr}`}
          maxLength={this.props.maxLength}
          name={`${name}[${attr}]`}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          readOnly={this.props.readOnly}
          ref='input'
          value={value}
        />
      )
    } else {
      input = (
        <input
          className='form-input'
          disabled={this.props.disabled}
          id={`${name}_${attr}`}
          maxLength={this.props.maxLength}
          name={`${name}[${attr}]`}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          readOnly={this.props.readOnly}
          ref='input'
          type='text'
          value={value}
        />
      )
    }

    if (this.props.formFieldWrapper) {
      const classes = cx({
        'form-field': true,
        'with-errors': errors && errors[attr],
      })

      return (
        <div className={classes}>
          { !label
            ? null
            : <label
              className='form-label'
              htmlFor={`${name}_${attr}`}
            >
              { this.props.required
                ? <span className='required'>
                  *
                </span>
                : null }
              { label }
            </label>
          }
          { input }
          { !note
            ? null
            : <p className='form-note'>
              { note }
            </p>
          }
          { this.renderError() }
        </div>
      )
    }

    return input
  }
}

// - -------------------------------------------------------------------- - //
