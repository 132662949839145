// - -------------------------------------------------------------------- - //

'use strict'

import ArtistCollection from '../flux/collections/ArtistCollection.js'
import Container from '../Container.jsx'
import FaIcon from '../FaIcon.jsx'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import Texts from '../texts/texts.js'
import createReactClass from 'create-react-class'
import cx from 'classnames'

// - -------------------------------------------------------------------- - //

export default createReactClass({
  displayName: 'ArtistPickerContainer',

  propTypes: {
    artists: PropTypes.instanceOf(ArtistCollection).isRequired,
    errors: PropTypes.any,
    isSaving: PropTypes.bool.isRequired,
    onAddArtist: PropTypes.func.isRequired,
    onSelectArtist: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    onClickAway: PropTypes.func,
    className: PropTypes.string,
  },

  getDefaultProps () {
    return {
      className: '',
    }
  },

  getInitialState () {
    return {
      name: '',
      selected: 'CHOOSE',
    }
  },

  handleCreateChange () {
    this.setState({
      name: ReactDOM.findDOMNode(this.refs.name).value,
    })
  },

  handleSelectChange () {
    this.setState({
      selected: ReactDOM.findDOMNode(this.refs.select).value,
    })
  },

  handleSubmit (event) {
    event.preventDefault()
    if (!this.props.isSaving) {
      if (this.state.selected === 'CHOOSE') {
        this.props.onAddArtist({ name: this.state.name })
        this.setState(this.getInitialState())
      } else {
        this.props.onSelectArtist(this.state.selected)
      }
    }
  },

  renderOption (artist, index) {
    return (
      <option
        key={index}
        value={artist.getValue('id')}
      >
        { artist.getValue('name') }
      </option>
    )
  },

  renderError () {
    if (this.props.errors) {
      return (
        <li>
          <p className='error'>
            { Texts.a2v.artist.duplicate }
          </p>
        </li>
      )
    }
  },

  renderProgress () {
    if (this.props.isSaving) {
      return (
        <li>
          <p>
            <FaIcon
              icon='spinner'
              spin
            />
            { ` ${Texts.a2v.artist.saving}` }
          </p>
        </li>
      )
    }
  },

  renderCancel () {
    if (this.props.onCancel) {
      return (
        <li>
          <button
            className='btn btn-link'
            disabled={this.props.isSaving}
            id='artist-picker-cancel'
            onClick={this.props.onCancel}
            type='button'
          >
            { Texts.a2v.artist.cancel }
          </button>
        </li>
      )
    }
  },

  handleClickAway (event) {
    if (event.target === ReactDOM.findDOMNode(this)) {
      this.props.onClickAway?.()
    }
  },

  render () {
    const anyArtists = this.props.artists.length > 0
    let create
    if (anyArtists) {
      create = [
        <div
          className='form-field'
          key={1}
        >
          <label htmlFor='existing-artists-select'>
            { Texts.a2v.artist.existing }
          </label>
          <select
            id='existing-artists-select'
            onChange={this.handleSelectChange}
            ref='select'
            value={this.state.selected}
          >
            <option value='CHOOSE'>
              { Texts.a2v.artist.existing }
            </option>
            { this.props.artists ? this.props.artists.map(this.renderOption) : null }
          </select>
        </div>,
        <div
          className='inline-divider form-field clearfix'
          key={2}
        >
          <hr className='divider' />
          <span className='divider-text'>
            { Texts.a2v.artist.or }
          </span>
          <hr className='divider' />
        </div>,
      ]
    }

    const buttonClasses = cx({
      'btn': true,
      'btn-primary': true,
      'disabled': this.props.isSaving,
    })

    return (
      <form
        className='artist-overlay'
        name='artist'
        onClick={this.handleClickAway}
        onSubmit={this.handleSubmit}
      >
        <Container
          className={`container-xs ${this.props.className}`}
          heading={Texts.a2v.artist.label}
        >
          { create }
          <div className='form-field'>
            <label htmlFor='create-artist-input'>
              {
                anyArtists
                  ? Texts.a2v.artist.create
                  : Texts.a2v.artist.createFirst
              }
            </label>
            <input
              className='form-input'
              id='create-artist-input'
              maxLength={100}
              name='create-artist-input'
              onChange={this.handleCreateChange}
              placeholder={Texts.a2v.artist.name}
              ref='name'
              type='text'
              value={this.state.name}
            />
          </div>

          <nav className='secondary-nav'>
            <ul>
              <li>
                <button
                  className={buttonClasses}
                  disabled={this.props.isSaving}
                  id='artist-picker-submit'
                  type='submit'
                >
                  { Texts.a2v.artist.submit }
                </button>
              </li>
              { this.renderCancel() }
              { this.renderProgress() }
              { this.renderError() }
            </ul>
            <div className='clear' />
          </nav>
        </Container>
      </form>
    )
  },

})

// - -------------------------------------------------------------------- - //
