import { sentenceCase } from 'd2/utils/StringHelpers'
import $ from 'jquery'

const Errors = function (errorsObject, options) {
  options || (options = {})
  typeof options.includeFieldName === 'undefined'
     && (options.includeFieldName = false)

  this.options = options
  this.errorsObject = errorsObject
}

Errors.messages = {
  'is_invalid': 'is invalid',
  'cant_be_empty': 'can\'t be empty',
  'is_too_short': 'is too short',
  'is_duplicated': 'already exists',
  'less_than_min': 'is less than the minimum allowed',
  'greater_than_max': 'is greater than maximum allowed',
  'doesnt_match': 'doesn\'t match',
  'must_be_checked': 'must be checked',
  'must_agree': 'must agree',
  'invalid_email': 'must be a valid email',
  'insufficient_funds': 'insufficient funds',
  'is_too_small': 'size is too small',
  'is_too_large': 'size cannot be larger than 5gb',
}

Errors.getReadableMessage = function (field, message, options) {
  message = Errors.messages[message] || message
  options = options || {}

  if (options.includeFieldName) {
    message = `${field} ${message}`
  }

  return sentenceCase(message)
}

Errors.getReadableFieldMessages = function (field, errors, options) {
  const readableFieldErrors = []

  $.each(errors, (_, error) => {
    readableFieldErrors.push(Errors.getReadableMessage(field, error, options))
  })

  return readableFieldErrors
}

Errors.getReadableMessages = function (errors, options) {
  const readableErrors = []

  $.each(errors, (field, fieldErrors) => {
    readableErrors.push(Errors.getReadableFieldMessages(field, fieldErrors, options))
  })

  return readableErrors
}

Errors.getReadableMessagesObject = function (errors, options) {
  const readableErrors = {}

  $.each(errors, (field, fieldErrors) => {
    readableErrors[field] = Errors.getReadableFieldMessages(field, fieldErrors, options)
  })

  return readableErrors
}

Errors.prototype.getReadableMessages = function () {
  return Errors.getReadableMessages(this.errorsObject, this.options)
}

Errors.prototype.getReadableMessagesObject = function () {
  return Errors.getReadableMessagesObject(this.errorsObject, this.options)
}

export default Errors
