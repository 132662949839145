import { Component } from 'react'
import { register } from 'd2/intermediate/registry'
import PropTypes from 'prop-types'

class SocialAvatar extends Component {
  static propTypes = {
    showTitle: PropTypes.bool,
    title: PropTypes.string, // The title of the social channel, rendered into a `span.social-avatar-title`.
    thumbnailUrl: PropTypes.string, // The URL of the image to use as the thumbnail. Can be path or full URL.
  }

  render () {
    const style = {
      backgroundImage: `url('${this.props.thumbnailUrl}')`,
    }

    return (
      <div className='social-avatar'>
        <div
          className='social-avatar-image'
          style={style}
        />
        { this.props.showTitle
          ? <span className='social-avatar-title'>
            { this.props.title }
          </span>
          : null }
      </div>
    )
  }
}

export default register('SocialAvatar', SocialAvatar)
