import $ from 'jquery'
import Modal from '../components/Modal.js'
import ready from '../utils/ready'

ready(() => {
  const $modalTriggers = $('.open-modal')
  $modalTriggers.each(function () {
    const $this = $(this)
    let targetSelector

    targetSelector = $this.data('target')
    targetSelector || (targetSelector = $this.attr('href'))
    const modal = new Modal($(targetSelector))

    $this.on('click', (event) => {
      event.preventDefault()
      modal.open()
    })

    modal.on('modalClose', () => {
      modal.$el.find('video, audio').each(function () {
        this.pause()
      })
    })
  })
})
