import $ from 'jquery'
import ready from '../utils/ready'

ready(() => {
  const $artistAttachForm = $('#artist-attach-form')
  const $artistSelectBox = $artistAttachForm.find('#media_artist_id')
  const $artistCreateNew = $artistAttachForm.find('#artist-create-new')
  if ($artistAttachForm.length > 0) {
    $artistSelectBox.change(function () {
      const createNew = $(this).val() === ''

      $artistCreateNew.toggle(createNew)

      if (createNew) {
        $artistCreateNew.find('input').focus()
      }
    })
  }
})
