import { gql } from '@apollo/client'
import $ from 'jquery'
import apollo from 'd2/utils/apollo'
import ready from '../utils/ready'

ready(() => {
  const $importExportForm = $('#import-export')
  if (!$importExportForm.length) return

  const $actionField = $('#import-export-action')
  const $uploadField = $('#import-export-file')

  const init = () => {
    $actionField.on('change', (e) => {
      const action = $(e.target).val()
      // update current url query params in place with ?action=${action}
      const url = new URL(window.location.href)
      url.searchParams.set('action', action)
      window.history.replaceState({}, '', url)
    })

    $importExportForm.on('submit', async (e) => {
      e.preventDefault()

      // Get the file
      const file = $uploadField.prop('files')[0]

      // Get presigned url
      const { data: { presignedUrl: { errors, presigned_urls: presignedUrls } } } = await apollo().mutate({
        mutation: gql`mutation PresignedUrls($input: PresignedUrlInput!) {
          presignedUrl(input: $input) {
            presigned_urls {
              id
              presigned_url
              path
            }
            errors {
              key
              messages
            }
          }
        }`,
        variables: {
          input: {
            files: [{ name: file.name }],
            type: 'csv',
          },
        },
      })
      if (errors) {
        return alert(errors.map((error) => `${error.key}: ${error.message}`).join('\n'))
      }

      // Upload to s3
      const { path, presigned_url: presignedUrl } = presignedUrls[0]
      $.ajax({
        type: 'PUT',
        url: presignedUrl,
        contentType: 'binary/octet-stream',
        processData: false,
        data: file
      })
        .done(() => {
          // Submit the original form with the path instead of the file
          const token = $('meta[name=csrf-token]').attr('content')
          const $newForm = $('<form/>', { action: $importExportForm.attr('action'), method: $importExportForm.attr('method') }).append(
            $('<input>', { type: 'hidden', name: 'authenticity_token', value: token }),
            $('<input>', { type: 'hidden', name: 'import_export[action]', value: $actionField.val() }),
            $('<input>', { type: 'hidden', name: 'import_export[original_file_name]', value: file.name }),
            $('<input>', { type: 'hidden', name: 'import_export[s3_key]', value: path }),
          )
          $newForm.hide()
          $newForm.appendTo('body')
          $newForm.submit()
        })
        .fail((error) => {
          alert(`File upload failed! Error: ${error}`)
        })

      return false
    })
  }

  init()
})
