// - -------------------------------------------------------------------- - //

'use strict'

import { throttle } from 'lodash-es'
import $ from 'jquery'
import AudioFileModel from '../../components/flux/models/AudioFileModel.js'
import BackgroundPreview from './BackgroundPreview.jsx'
import GlobalSettingsModel from '../../components/flux/models/GlobalSettingsModel.js'
import ImagePreview from './ImagePreview.jsx'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import TextLine from './TextLine.jsx'
import Texts from '../texts/texts.js'
import classSet from 'classnames'
import createReactClass from 'create-react-class'

// - -------------------------------------------------------------------- - //

export default createReactClass({
  displayName: 'VideoPreview',

  propTypes: {
    selectedFile: PropTypes.instanceOf(AudioFileModel).isRequired,
    globalSettings: PropTypes.instanceOf(GlobalSettingsModel).isRequired,
  },

  handleResize () {
    if (!this._isMounted) return

    const elm = $(ReactDOM.findDOMNode(this))
    const outer = elm.parent()
    const width = elm.width()
    const height = elm.height()
    const availableWidth = outer.width()
    const availableHeight = outer.height()
    const top = (height - availableHeight) / 2
    const left = (width - availableWidth) / 2
    const ratio = Math.min(availableWidth, 600) / width
    elm.attr('data-scale', ratio)
      .css({
        top: -top,
        left: -left,
        transform: `scale(${ratio})`,
      })
      .find('.image-preview img')
      .attr('data-scale', ratio)
  },

  componentDidMount () {
    this._isMounted = true
    const throttledResize = throttle(this.handleResize, 400)
    $(window).on('resize.videoPreview', throttledResize)
    this.handleResize()
  },

  componentWillUnmount () {
    this._isMounted = false
    $(window).off('.videoPreview')
  },

  componentDidUpdate () {
    this.handleResize()
  },

  getFontStyle () {
    const selectedFile = this.props.selectedFile
    const fontStyle = {}
    if (selectedFile.hasValue('font')) {
      fontStyle.fontFamily = selectedFile.getValue('font')
    }
    if (selectedFile.hasValue('font_color')) {
      fontStyle.color = selectedFile.getValue('font_color')
    }
    if (selectedFile.getValue('font_shadow') !== 'none') {
      fontStyle.textShadow = `2px 2px ${selectedFile.getValue('font_shadow')}`
    }
    return fontStyle
  },

  render () {
    const selectedFile = this.props.selectedFile
    const globalSettings = this.props.globalSettings

    let artwork
    let artworkFile
    if (globalSettings.hasValue('artwork')) {
      artworkFile = globalSettings.getValue('artwork')
      if (!artworkFile.getErrors()) {
        artwork = <ImagePreview file={artworkFile} />
      }
    } else if (selectedFile.hasValue('artwork')) {
      artworkFile = selectedFile.getValue('artwork')
      if (!artworkFile.getErrors()) {
        artwork = <ImagePreview file={artworkFile} />
      }
    }

    let artist = ''
    if (selectedFile.hasValue('artist')) {
      artist = selectedFile.getValue('artist').getValue('name')
    }

    let songName = ''
    if (selectedFile.hasValue('song_name')) {
      songName = selectedFile.getValue('song_name')
    }

    const showText = selectedFile.getValue('show_text')
    const classes = classSet({
      'video-preview': true,
      'with-artwork': !!artwork,
      'with-text': showText && (/[\S]+/.test(songName) || /[\S]+/.test(artist)),
    })

    const backgroundTemplate = globalSettings.getValue('backgroundTemplate')
    const fontStyle = this.getFontStyle()
    const coverText = (
      <div
        className='text'
        key='text'
      >
        <TextLine
          className='song-name'
          style={fontStyle}
          text={songName}
        />
        <TextLine
          className='artist-name'
          style={fontStyle}
          text={`${Texts.a2v.video.by} ${artist}`}
        />
      </div>
    )

    return (
      <div className={classes}>
        <BackgroundPreview
          backgroundColor={globalSettings.getValue('backgroundColor')}
          backgroundImage={globalSettings.getValue('backgroundImage')}
          backgroundTemplate={backgroundTemplate}
        />
        <div className='artwork'>
          { artwork }
        </div>
        { showText ? coverText : null }
      </div>
    )
  },
})

// - -------------------------------------------------------------------- - //
