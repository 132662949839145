// - -------------------------------------------------------------------- - //

'use strict'

import { Component } from 'react'
import FaIcon from '../FaIcon.jsx'
import ImageFileModel from '../../components/flux/models/ImageFileModel.js'
import PropTypes from 'prop-types'

// - -------------------------------------------------------------------- - //

export default class extends Component {
  static displayName = 'ImagePreview'

  static propTypes = {
    file: PropTypes.instanceOf(ImageFileModel),
    url: PropTypes.string,
  }

  state = {
    loading: false,
  }

  componentWillReceiveProps (newProps) {
    if (newProps.url !== this.props.url) {
      this.setState({
        loaded: false,
        loading: true,
      })
    }
  }

  componentDidUpdate () {
    if (this.state.loading) {
      this.setState({
        loaded: false,
        loading: false,
      })
    }
  }

  /* eslint-enable react/no-did-update-set-state */

  handleLoaded = () => {
    this.setState({
      loaded: true,
    })
  }

  render () {
    const file = this.props.file
    const url = this.props.url
    let image

    if (file && file.hasValue('url')) {
      image = (
        <img
          alt='a2v-preview'
          crossOrigin='true'
          src={file.getValue('url')}
        />
      )
    } else if (url && !this.state.loading) {
      image = (
        <img
          alt='a2v-preview'
          crossOrigin='true'
          onLoad={this.handleLoaded}
          src={url}
        />
      )
    }

    return (
      <div className='image-preview'>
        { this.props.url && !this.state.loaded
          ? <FaIcon
            icon='spinner'
            spin
          />
          : null }
        { image }
      </div>
    )
  }
}

// - -------------------------------------------------------------------- - //
