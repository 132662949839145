import $ from 'jquery'
import helpers from '../ui/helpers'
import ready from '../utils/ready'
const markupPath = '/utils/gfm'

ready(() => {
  $('.message-content a, .message-attachments a').attr({
    rel: 'noopener noreferrer',
    target: '_blank',
  })
  const $formContainer = $('#release-message-form-container')

  if ($formContainer.length > 0) {
    const $messageFormContainer = $formContainer.find('.message-form-container')
    const $messageContent = $formContainer.find('#message_content')
    const $templateSelect = $formContainer.find('#message_message_templates')
    const $write = $messageFormContainer.find('a[href="#write"]')
    const $preview = $messageFormContainer.find('a[href="#preview"]')
    const $writeSection = $formContainer.find('#write')
    const $previewSection = $formContainer.find('#preview')
    let $currentSection = $writeSection
    const $previewContent = $previewSection.find('#preview-content')
    let requesting = false

    $write.on('click', (event) => {
      event.preventDefault()
      if ($writeSection === $currentSection) {
        return
      }
      $currentSection = $writeSection
      $previewSection.hide()
      $writeSection.show()
      $write.closest('li').addClass('active')
      $preview.closest('li').removeClass('active')
    })

    $preview.on('click', (event) => {
      event.preventDefault()

      if ($previewSection === $currentSection || requesting) {
        return
      }

      const val = $messageContent.val()
      if (!val || val.toString() === '') {
        alert('Oops! Please write something in the Message Content box before previewing.')
        return
      }

      requesting = true
      $.post(markupPath, {
        'authenticity_token': $('meta[name=csrf-token]').attr('content'),
        'content': $messageContent.val(),
      }, (data) => {
        requesting = false
        $previewContent.html(
          `<section class="messages-list"><ul><li class="user-message user-visible">${
            data
          }</li></ul></section>`)
        $currentSection = $previewSection
        $writeSection.hide()
        $previewSection.show()
      }, 'HTML')

      $preview.closest('li').addClass('active')
      $write.closest('li').removeClass('active')
    })

    $templateSelect.on('change', () => {
      const currentContent = $messageContent.val()
      const templateContent = $templateSelect.val()

      if (currentContent === '' || window.confirm('Are you sure you want to overwrite the current message?')) {
        $messageContent.val(templateContent)
      } else {
        $templateSelect.val('')
      }
    })

    $write.closest('li').addClass('active')

    const $messagesList = $('.messages-list')
    const $lastMessage = $messagesList.find('li:last')

    // Auto-scroll to latest message.
    if ($lastMessage.length > 0) {
      helpers.scroll_to($lastMessage)
    }
  }
})
