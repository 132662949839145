import { Component } from 'react'
import { isArray } from 'lodash-es'
import FaIcon from './FaIcon.jsx'
import PropTypes from 'prop-types'
import cx from 'classnames'

class Container extends Component {
  static propTypes = {
    id: PropTypes.string,
    heading: PropTypes.string,
    nav: PropTypes.any,
    icon: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }

  render () {
    const classes = {
      'container': true,
    }
    let heading = this.props.heading
    let nav = this.props.nav
    let icon = this.props.icon

    if (this.props.className) {
      classes[cx(this.props.className)] = true
    }

    if (nav) {
      nav = isArray(nav) ? nav : [nav]

      // eslint-disable-next-line react/no-array-index-key
      nav = nav.map((item, index) => (<li key={index}>
        { item }
      </li>))

      nav = (
        <nav>
          <ul>
            { nav }
          </ul>
        </nav>)
    }

    if (icon) {
      icon = (<FaIcon
        className='heading-icon'
        icon={icon}
      />)
    }

    if (heading || icon || nav) {
      heading = (
        <header>
          <h1>
            { icon }
            { ' ' }
            <span className='heading-text'>
              { heading }
            </span>
          </h1>
          { nav }
        </header>)
    }

    return (
      <div
        className={cx(classes)}
        id={this.props.id}
        style={this.props.style}
      >
        { heading }
        <div className='container-content'>
          { this.props.children }
        </div>
      </div>
    )
  }
}

export default Container
