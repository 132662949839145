// - -------------------------------------------------------------------- - //

'use strict'

import { extend, identity, isArray, isFunction, isObject, isString } from 'lodash-es'
import $ from 'jquery'
import McFly from 'mcfly'

// - -------------------------------------------------------------------- - //

const debug = function (/* arguments */) {
  if (window.VYDIA_SETTINGS.ENVIRONMENT !== 'test' && window.VYDIA_SETTINGS.ENVIRONMENT !== 'development') return

  console.warn.apply(console, arguments)
}

const flux = new McFly()

const createStore = flux.createStore.bind(flux)

flux.createStore = function (stateMethods, actionHandler, resetCallback) {
  /*
    - methods as passed as is to McFly.

    - actionHandler as a Function is passed as is to McFly.

    - actionHandler as an Object works as actionType being the key
      and a callback Function as value, if that callback returns false
      then change is NOT emitted, otherwise it is.

    - resetCallback is called on componentWillMount and componentWillUnmount
      it should be used to reset store data, otherwise they will persist when
      route changes.
  */

  if (isFunction(actionHandler)) {
    const actionHandlerRef = actionHandler
    actionHandler = function (payload) {
      actionHandlerRef.call(store, payload)
    }
  } else if (isObject(actionHandler)) {
    const actionTypeMap = actionHandler
    actionHandler = function (payload) {
      if (isFunction(actionTypeMap[payload.actionType])) {
        const ret = actionTypeMap[payload.actionType].call(store, payload)
        if (ret !== false) {
          store.emitChange()
        }
      }
    }
  }

  // Create the store.
  const store = createStore(stateMethods, actionHandler)

  // Wraps store mixin to call reset callback if specified.
  if (isFunction(resetCallback)) {
    const mixin = store.mixin
    store.mixin = {
      componentWillMount () {
        resetCallback.call(store, true)
        if (this.storeDidChange) {
          this.storeDidChange()
        }
      },
      componentDidMount: mixin.componentDidMount,
      componentWillUnmount () {
        resetCallback.call(store, false)
        mixin.componentWillUnmount()
      },
    }

    store.resetState = resetCallback
  }

  return store
}

flux.createPrefixer = function (options) {
  if (options && options.actionPrefix) {
    return function (actionName) {
      return `${options.actionPrefix}_${actionName}`
    }
  }
  return identity
}

flux.createCompositeStore = function () {
  const stateMethods = {}
  const resetCallbacks = []
  const actionPrefixes = []
  let actionHandlers = []

  /*
    Each argument is a micro-store to be mixed in the composite store.
    Array -> [stateMethods, actionHandler, resetCallback, actionPrefix]
    Object -> { stateMethods, actionHandler, resetCallback, actionPrefix }
  */
  for (let i = 0, len = arguments.length; i < len; i++) {
    const arg = arguments[i]
    if (isArray(arg)) {
      extend(stateMethods, arg[0])
      if (isFunction(arg[2])) {
        resetCallbacks.push(arg[2])
      }
      actionHandlers.push(arg[1])
      actionPrefixes.push(arg[3])
    } else if (isObject(arg)) {
      extend(stateMethods, arg.stateMethods)
      if (isFunction(arg.resetCallback)) {
        resetCallbacks.push(arg.resetCallback)
      }
      actionHandlers.push(arg.actionHandler)
      actionPrefixes.push(arg.actionPrefix)
    }
  }

  /*
    Create the composite action handler with optional name prefix.
    Handlers for the same actions are all called, not just the last given.
  */
  actionHandlers = actionHandlers.map((actionHandler, index) => {
    if (isFunction(actionHandler)) {
      return actionHandler
    } else if (isObject(actionHandler)) {
      // Adds prefix if defined.
      const prefixer = flux.createPrefixer({ actionPrefix: actionPrefixes[index] })
      const actionTypeMap = {}
      Object.keys(actionHandler).forEach((actionName) => {
        actionTypeMap[prefixer(actionName)] = actionHandler[actionName]
      })

      // Simple router for actions.
      return function (payload) {
        if (isFunction(actionTypeMap[payload.actionType])) {
          const ret = actionTypeMap[payload.actionType].call(this, payload)
          if (ret !== false) {
            this.emitChange()
          }
        }
      }
    }
    return actionHandler
  })

  const compositeActionHandler = function (payload) {
    actionHandlers.forEach((actionHandler) => {
      actionHandler.call(this, payload)
    })
  }

  const compositeResetCallback = function (mount) {
    resetCallbacks.forEach((resetCallback) => {
      resetCallback.call(this, mount)
    })
  }

  return flux.createStore(
    stateMethods,
    compositeActionHandler,
    compositeResetCallback
  )
}

flux.createCompositeActions = function () {
  const parts = [{}]
  for (let i = 0; i < arguments.length; i++) {
    parts.push(arguments[i])
  }
  const actions = extend.apply(undefined, parts)
  return flux.createActions(actions)
}

// - -------------------------------------------------------------------- - //

const jqxhrExtension = {

  // Shortcut to trigger actions when ajax request fails.
  failDispatch (handler) {
    let callback

    if (isFunction(handler)) {
      callback = function (data) {
        debug(data)
        const payload = handler(data)
        payload.errors = data && data.responseJSON && data.responseJSON.errors ? data.responseJSON.errors : {}
        flux.dispatcher.dispatch(payload)
      }
    } else if (isString(handler)) {
      callback = function (data) {
        debug(data)
        flux.dispatcher.dispatch({
          actionType: handler,
          errors: data && data.responseJSON && data.responseJSON.errors ? data.responseJSON.errors : {},
        })
        return data
      }
    } else if (isObject(handler)) {
      callback = function (data) {
        debug(data)
        handler.errors = data && data.responseJSON && data.responseJSON.errors ? data.responseJSON.errors : {}
        flux.dispatcher.dispatch(handler)
      }
    }

    const promise = this.fail(callback)
    promise.failDispatch = jqxhrExtension.failDispatch.bind(this)
    promise.thenDispatch = jqxhrExtension.thenDispatch.bind(this)
    return promise
  },

  // Shortcut to trigger actions when ajax request succeeds.
  thenDispatch (handler) {
    let callback

    if (isFunction(handler)) {
      callback = function (data) {
        const payload = handler(data)
        flux.dispatcher.dispatch(payload)
        return data
      }
    } else if (isString(handler)) {
      callback = function (data) {
        flux.dispatcher.dispatch({ actionType: handler })
        return data
      }
    } else if (isObject(handler)) {
      callback = function (data) {
        flux.dispatcher.dispatch(handler)
        return data
      }
    }
    const promise = this.then(callback)
    promise.failDispatch = jqxhrExtension.failDispatch.bind(this)
    promise.thenDispatch = jqxhrExtension.thenDispatch.bind(this)
    return promise
  },

}

// Extends every jqXHR object with dispatcher shortcuts.
$(document).ajaxSend((event, jqxhr) => {
  jqxhr.failDispatch = jqxhrExtension.failDispatch.bind(jqxhr)
  jqxhr.thenDispatch = jqxhrExtension.thenDispatch.bind(jqxhr)
})

// - -------------------------------------------------------------------- - //

export default flux

// - -------------------------------------------------------------------- - //
