import { Component } from 'react'
import { extend } from 'lodash-es'
import PropTypes from 'prop-types'
import cx from 'classnames'

class ProgressBar extends Component {
  static propTypes = {
    // If `true` props.progress should be (0.0..1.0), otherwise (0..100).
    isFloat: PropTypes.bool,

    // The progress amount.
    progress: PropTypes.number.isRequired,

    // Any additional classNames.
    className: PropTypes.string,

    // Add "progress-striped" className.
    striped: PropTypes.bool,

    // Add "progress-success" className.
    success: PropTypes.bool,

    // Add "progress-secondary" className.
    secondary: PropTypes.bool,

    // Add "active" className.
    active: PropTypes.bool,

    // Add "danger" className.
    danger: PropTypes.bool,

    // Number (0.0..1.0) to multiply against the given percent.
    //   Used when applying the width style.
    percentageModifier: PropTypes.number,

    // Any additional styles.
    style: PropTypes.object,
  }

  static defaultProps = {
    isFloat: false,
    striped: false,
    active: false,
    percentageModifier: 1.0,
  }

  styleFromPercent = (percent, additionalStyles) => {
    const widthStyle = {
      width: `${percent * this.props.percentageModifier}%`,
    }

    if (additionalStyles) {
      extend(widthStyle, additionalStyles)
    }

    return widthStyle
  }

  render () {
    const progressPercent = Math.min(this.props.progress * (this.props.isFloat ? 100 : 1), 100)
    const style = this.styleFromPercent(
      progressPercent,
      this.props.style
    )
    const classes = {
      'progress': true,
      'progress-striped': this.props.striped,
      'progress-success': this.props.success,
      'progress-secondary': this.props.secondary,
      'progress-danger': this.props.danger,
      'active': this.props.active,
    }

    if (this.props.className) {
      classes[this.props.className] = true
    }

    return (
      <div className={cx(classes)}>
        <div
          aria-valuemax='100'
          aria-valuemin='0'
          aria-valuenow={progressPercent}
          className='progress-bar'
          role='progressbar'
          style={style}
        >
          <span className='sr-only'>
            <span>
              { `${progressPercent}% Complete` }
            </span>
          </span>
        </div>
      </div>
    )
  }
}

export default ProgressBar
