import { compileURI } from 'd2/utils/StringHelpers'
import $ from 'jquery'
import Demplate from '../utils/demplate/Demplate'
import ready from '../utils/ready'
import serializeObject from '../utils/serializeObject'

ready(() => {
  const $container = $('#service-link-builder')
  if ($container.length > 0) {
    const $form = $container.find('form')
    let model
    const updateModel = function () {
      model || (model = new Demplate.Model())
      model.set(serializeObject($form))
    }
    const compileLink = function (model) {
      const base = `${window.location.protocol}//${window.location.host}/service`
      const params = {}

      // Only use truthy params.
      for (const k in model.data) {
        model.data[k] && (params[k] = model.data[k])
      }

      return compileURI(base, params)
    }
    const compileHTML = function (model) {
      const link = compileLink(model)
      return $('<input/>', {
        'type': 'text',
        'class': 'form-input select-all',
        'readonly': 'readonly',
        'id': 'compiled-link-field',
        'value': link,
      })
    }

    updateModel()

    const demplate = new Demplate($('#compiled-link'), null, {
      renderer: compileHTML,
      model,
    })

    demplate.bind('.select-all', 'focus mouseup mousedown', function () {
      $(this).select()
      return false
    })

    $form.find(':input').on('change keyup', updateModel)

    demplate.render()

    // Confirm submit form
    $form.on('submit', (event) => {
      const msg = 'Are you sure? This will send you to a new page.'

      if (!window.confirm(msg)) {
        event.preventDefault()
        return false
      }
    })
  }
})
